import React, { useState, useEffect } from 'react';
import HelpPage from './HelpPage';
import Skeleton from 'react-loading-skeleton';
import {
  LineChart, Line, BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
  AreaChart, Area, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar
} from 'recharts';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import '../style/Home.css';

const Home = () => {
  const loggedInUserEmail = JSON.parse(localStorage.getItem('user'))?.email;
  const [isHelpPageOpen, setIsHelpPageOpen] = useState(false);
  const [encryptedEmail, setEncryptedEmail] = useState('');
  const [pieChartData, setPieChartData] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [areaChartData, setAreaChartData] = useState([]);
  const [radarChartData, setRadarChartData] = useState([]);
  const [scorecardData, setScorecardData] = useState({});
  const [tabularData, setTabularData] = useState([]);
  const [dateFilter, setDateFilter] = useState('All');
  const [customDateRange, setCustomDateRange] = useState({ startDate: '', endDate: '' });
  const [loading, setLoading] = useState(true);
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  const toggleHelpPage = () => setIsHelpPageOpen(!isHelpPageOpen);
  const showHelpPage = () => setIsHelpPageOpen(true);
  const closeHelpPage = () => setIsHelpPageOpen(false);

  useEffect(() => {
    setEncryptedEmail(btoa(loggedInUserEmail) || '');
  }, [loggedInUserEmail]);

  // const sampleRadarData = [
  //   { subject: 'Math', A: 120, B: 110, fullMark: 150 },
  //   { subject: 'Chinese', A: 98, B: 130, fullMark: 150 },
  //   { subject: 'English', A: 86, B: 130, fullMark: 150 },
  //   { subject: 'Geography', A: 99, B: 100, fullMark: 150 },
  //   { subject: 'Physics', A: 85, B: 90, fullMark: 150 },
  //   { subject: 'History', A: 65, B: 85, fullMark: 150 },
  // ];


  useEffect(() => {
    if (encryptedEmail) {
      setLoading(true);
      fetch(`${process.env.REACT_APP_FASTAPI_URL}/v1/dashboard/get_dashboard_data?email=${loggedInUserEmail}&start_date=${customDateRange.startDate}&end_date=${customDateRange.endDate}`)
        .then(response => response.json())
        .then(data => {
          if (!data.error) {
            setPieChartData([
              { name: 'Active Projects', value: data.pieChartDataset[0].active_projects },
              { name: 'Inactive Projects', value: data.pieChartDataset[0].inactive_projects },
              // { name: 'Inactive Projects', value: 36 },
              // { name: 'Inactive Projects', value: 12 },
            ]);
            setLineChartData(data.lineChartDataset.map(item => ({
              date: new Date(item.Date).toLocaleDateString(),
              projectsCreated: item.ProjectsCreated,
            })));
            setBarChartData(data.barChartDataset.map(item => ({
              projectName: item.ProjectName,
              numberOfSelectedObjects: item.No_Of_SelectedObjects,
            })));
            setAreaChartData(data.lineChartDataset.map(item => ({
              date: new Date(item.Date).toLocaleDateString(),
              projectsCreated: item.ProjectsCreated,
              pv: item.ProjectsCreated,
            })));
            setScorecardData(data.scorecard_data);
            setTabularData(data.tabular_dataset);
            // setRadarChartData([
            //   { subject: 'Failed Objects', A: data.scorecard_data.failed_objects, fullMark: 1000 },
            //   { subject: 'Failed Projects', A: data.scorecard_data.failed_projects, fullMark: 100 },
            //   { subject: 'Success Objects', A: data.scorecard_data.success_objects, fullMark: 3000 },
            //   { subject: 'Success Projects', A: data.scorecard_data.success_projects, fullMark: 100 },
            //   { subject: 'Total Objects', A: data.scorecard_data.total_objects, fullMark: 4000 },
            //   { subject: 'Total Projects', A: data.scorecard_data.total_projects_created, fullMark: 100 },
            // ]);
          } else {
            console.error(data.error);
          }
        })
        .catch(error => console.error('Error fetching data:', error))
        .finally(() => setLoading(false)); 
    }
  }, [encryptedEmail, loggedInUserEmail, customDateRange]);

  const handleDateFilterChange = (e) => {
    const selectedFilter = e.target.value;
    setDateFilter(selectedFilter);
  
    const today = new Date();
    let startDate;
    let endDate = today.toISOString().split('T')[0]; // Today's date in YYYY-MM-DD format
  
    if (selectedFilter === 'Last One Day') {
      startDate = new Date(today);
      startDate.setDate(today.getDate() - 1);
    } else if (selectedFilter === 'Last One Week') {
      startDate = new Date(today);
      startDate.setDate(today.getDate() - 7);
    } else if (selectedFilter === 'Last One Month') {
      startDate = new Date(today);
      startDate.setMonth(today.getMonth() - 1);
    } else {
      startDate = '';
      endDate = '';
    }
  
    // Convert startDate to YYYY-MM-DD format
    startDate = startDate ? startDate.toISOString().split('T')[0] : '';
  
    setCustomDateRange({
      startDate: startDate,
      endDate: endDate
    });
  };

  const handleCustomDateChange = (e) => {
      setCustomDateRange({
        ...customDateRange,
        [e.target.name]: e.target.value,
      });
    };

  return (
    <div className="flex-grow">
      <div className='dashboard-layout'>
        <div className="control-section">
          <div className="spacer"></div> 
          <div className="filters">
            <div className="filter-item">
              <select value={dateFilter} onChange={handleDateFilterChange}>
                <option value="All">All</option>
                <option value="Last One Day">Last One Day</option>
                <option value="Last One Week">Last One Week</option>
                <option value="Last One Month">Last One Month</option>
                <option value="Custom">Custom</option>
              </select>
            </div>
            {dateFilter === 'Custom' && (
              <div className="custom-date-range">
                <input
                  type="date"
                  name="startDate"
                  value={customDateRange.startDate}
                  onChange={handleCustomDateChange}
                />
                <input
                  type="date"
                  name="endDate"
                  value={customDateRange.endDate}
                  onChange={handleCustomDateChange}
                />
              </div>
            )}
          </div>
        </div>

        <div className='flex-item flex-item-5'>
          <div className='scorecard-data'>
            <div className='scorecard-row-1'>
                {loading ? (
                  <Skeleton count={3} height={30} />
                ) : (
                  <>
                    <div className='scorecard-item'>
                      <label>Successful Projects:</label>
                      <span>{scorecardData.success_projects}</span>
                    </div>
                    <div className='scorecard-item'>
                      <label>Failed Projects:</label>
                      <span>{scorecardData.failed_projects}</span>
                    </div>
                    <div className='scorecard-item'>
                      <label>Total Projects:</label>
                      <span>{scorecardData.total_projects_created}</span>
                    </div>
                    
                  </>
                )}
            </div>
            <div className='scorecard-row-2'>
              {loading ? (
                <Skeleton count={3} height={30} />
              ) : (
                <>
                  <div className='scorecard-item'>
                      <label>Successful Objects:</label>
                      <span>{scorecardData.success_objects}</span>
                  </div>       
                  <div className='scorecard-item'>
                      <label>Failed Objects:</label>
                      <span>{scorecardData.failed_objects}</span>
                  </div>           
                  <div className='scorecard-item'>
                    <label>Total Objects:</label>
                    <span>{scorecardData.total_objects}</span>
                  </div>
                  
                </>
              )}
            </div>         
          </div>
        </div>
        <div className='flex-item flex-item-1'>
          {loading ? (
              <Skeleton height={300} />
            ) : (
                  <div className="dashboard-table-container">
                    <table className="styled-table-dashboard">
                      <thead>
                        <tr>
                          <th>Project Name</th>
                          <th>Schedule Job Name</th>
                          <th>Schedule Description</th>                      
                        </tr>
                      </thead>
                      <tbody className="scrollable-box scrollable-box-dashboard">
                        {tabularData.map((row, index) => (
                          <tr key={index}>
                            <td>{row.ProjectName}</td>
                            <td>{row.ScheduleJobName || 'N/A'}</td>
                            <td>{row.ScheduleDescription || 'N/A'}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
            )}          
        </div>
        <div className='flex-item flex-item-1'>
          {loading ? (
              <Skeleton height={300} />
            ) : (
              <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={barChartData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="projectName" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="numberOfSelectedObjects" fill="#8884d8" />
                  </BarChart>
              </ResponsiveContainer>
            )}          
        </div>
        <div className='flex-item flex-item-2'>
          {loading ? (
              <Skeleton height={500} />
            ) : (
              <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={lineChartData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="projectsCreated" stroke="#82ca9d" />
                  </LineChart>
              </ResponsiveContainer>
            )}
        </div>
        <div className='flex-item flex-item-3'>
          {loading ? (
              <Skeleton height={500} />
            ) : (
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={pieChartData}
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value" // Changed from "count" to "value"
                    label
                  >
                    {pieChartData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            )}
        </div>
        <div className='flex-item flex-item-4'>
          {loading ? (
              <Skeleton height={500} />
            ) : (
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart data={areaChartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Area type="monotone" dataKey="projectsCreated" stroke="#8884d8" fill="#8884d8" />
                </AreaChart>
            </ResponsiveContainer>
            )}
        </div>
        {/* <div className='flex-item flex-item-5'>
          <ResponsiveContainer width="100%" height={300}>
            <RadarChart data={radarChartData}>
              <PolarGrid />
              <PolarAngleAxis dataKey="subject" />
              <PolarRadiusAxis angle={30} domain={[0, 150]} />
              <Radar name="A" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
              <Radar name="B" dataKey="B" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.6} />
              <Tooltip />
              <Legend />
            </RadarChart>
          </ResponsiveContainer>
        </div> */}
      </div>
      <div className="help-icon">
        <div className={` ${isHelpPageOpen ? 'help-container' : 'help-container-closed'}`}>
          <p className={` ${isHelpPageOpen ? 'menu-text-help-bot' : 'question-mark-style '}`} onMouseEnter={showHelpPage} onClick={closeHelpPage}>
            {isHelpPageOpen ? 'May I help you?' : '?'}
          </p>
          {isHelpPageOpen && <HelpPage onClose={toggleHelpPage} />}
        </div>
      </div>
    </div>
  );
};

export default Home;
