import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router,Routes,Route,Navigate} from 'react-router-dom';
import Home from './Components/Home';
import Project from './Components/Project'; 
import Project2 from './Components/Project2'; 
import AddProjectDialog from './Components/AddProjectDialog';
import Connector from './Components/Connector';
import Schedule from './Components/Schedule';
import RunHistoryMain from './Components/RunHistoryMain';
import HamburgerMenu from './Components/HamburgerMenu';
import MappingDialog from './Components/MappingDialog';
import EditProjectDialog from './Components/EditProjectDialog';
import HelpPage from './Components/HelpPage';
import NewPage from './Components/NewPage';
import progressBar from './Components/progressBar';
import RunTable from './Components/RunTable';
import Scheduler from './Components/Scheduler';
import TimeInput from './Components/TimeInput';
import Settings from './Components/Settings';
import HourCounter from './Components/hour';
import DatePickerComponent from './Components/datepicker';
import Layout from './Components/Layout';
//import Transformations from './Components/Transformations';
//import Transformations2 from './Components/Transformations2';
import React, { useState, useEffect, useRef } from "react";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import AnalogClock from './Components/AnalogClock';
import Signup from './Components/SignupLoginComponents/Signup';
import Login2 from './Components/SignupLoginComponents/Login';
import SignUpLoginParent from './Components/SignupLoginComponents/SignupLoginParent';
import UserProfile from './Components/UserProfile';
import ProtectedRoute from './Components/ProtectedRoute';
import SQLExecProjects from './Components/SQLExecProjects';
import SQLExecAddProject from './Components/SQLExecAddProjects';
import ProjectStatus from './Components/SchedulerJobDetailsComponents/ProjectStatus';
import DMLayer3 from './Components/SchedulerJobDetailsComponents/DMLayer3';
import SELayer3 from './Components/SchedulerJobDetailsComponents/SELayer3';
import RunHistoryLayout from './Components/RunHistoryLayout';
import RunHistoryDMEntityLevel from './Components/RunHistoryDetailsComponents/RunHistoryDMEntityLevel';
import RunHistorySEEntityLevel from './Components/RunHistoryDetailsComponents/RunHistorySEEntityLevel';
import Layout2 from './Components/Layout2';
import JobDetails from './Components/SchedulerJobDetailsComponents/JobDetails';
import ProjectConfigDetails from './Components/ProjectConfigDetails';
import AddProject from './Components/AddProject';
import EditorLayout from './Components/Editor/EditorLayout';
import SQLEditor from './Components/Editor/SQLEditor';

function App() {

  const [darkMode, setDarkMode] = useState(false);
  const [purpleMode, setPurpleMode] = useState(false);
  const [redMode, setRedMode] = useState(false);
  const [blueMode, setBlueMode] = useState(false);
  const [yellowMode, setYellowMode] = useState(false);

  return (
    <Router>
      <Routes>
      <Route path="/signup" element={<Signup />} />
      <Route path="/login" element={<SignUpLoginParent />} />
      <Route path='/' element={<Layout darkMode={darkMode} purpleMode={purpleMode} redMode={redMode} blueMode={blueMode} yellowMode={yellowMode} />}>
        <Route index element={<ProtectedRoute><Home /></ProtectedRoute>} />
        {/* <Route path="/"
        element={<Home />}></Route> */}
        <Route path="/project"
          element={<ProtectedRoute><Project darkMode={darkMode} purpleMode={purpleMode} redMode={redMode} blueMode={blueMode} yellowMode={yellowMode} /></ProtectedRoute>}>
        </Route>
        {/* <Route path="/profile"
          element={<UserProfile />}>
        </Route> */}
        <Route path="/editor"
          element={<EditorLayout />}>
        </Route>
        <Route path="/editor2"
          element={<SQLEditor />}>
        </Route>
        <Route path="/all-projects"
          element={<Project2 />}>
        </Route>
        <Route path="/project-config-details/:projectname"
          element={<ProjectConfigDetails />}>
        </Route>
        <Route path="/add-project"
          element={<AddProject />}>
        </Route>
        <Route path="/sqlexecprojects"
          element={<SQLExecProjects />} />
        <Route path='add-sql-project'
          element = {<SQLExecAddProject />} />
        <Route path='scheduled-job-details'
          element = {<JobDetails />} />
        {/* <Route path="/Layout"
        element={<ProtectedRoute><Layout darkMode={darkMode} purpleMode={purpleMode} redMode={redMode} blueMode={blueMode} yellowMode={yellowMode} /></ProtectedRoute>}></Route> */}
        <Route path="/NewPage"
        element={<NewPage />}></Route>
        <Route path="/HelpPage"
        element={<HelpPage/>}></Route>
        <Route path="/AddProjectdialog"
        element={<AddProjectDialog/>}></Route>
        <Route path="/Connector/:projectname" 
        element={<Connector darkMode={darkMode} purpleMode={purpleMode} redMode={redMode} blueMode={blueMode} yellowMode={yellowMode} />} />
        <Route path="/schedule"
        element={<ProtectedRoute><Scheduler darkMode={darkMode} purpleMode={purpleMode} blueMode={blueMode} yellowMode={yellowMode} /></ProtectedRoute>}></Route>
        <Route path="/scheduler"
        element={<ProtectedRoute><Schedule darkMode={darkMode} purpleMode={purpleMode} redMode={redMode} blueMode={blueMode} yellowMode={yellowMode} /></ProtectedRoute>}></Route>
        <Route path="/project-status/"
        element={<ProtectedRoute><ProjectStatus /></ProtectedRoute>}></Route>
        <Route path="/dm-object-details/"
        element={<ProtectedRoute><DMLayer3 /></ProtectedRoute>}></Route>
        <Route path="/se-object-details/"
        element={<ProtectedRoute><SELayer3 /></ProtectedRoute>}></Route>
        <Route path="/run-history-project-status/"
        element={<ProtectedRoute><RunHistoryLayout /></ProtectedRoute>}></Route>
        <Route path="/run-history-dm-entity-status/"
        element={<ProtectedRoute><RunHistoryDMEntityLevel /></ProtectedRoute>}></Route>
        <Route path="/run-history-se-entity-status/"
        element={<ProtectedRoute><RunHistorySEEntityLevel /></ProtectedRoute>}></Route>
        <Route path="/RunHistoryMain"
        element={<ProtectedRoute><RunHistoryMain darkMode={darkMode} purpleMode={purpleMode} redMode={redMode} blueMode={blueMode} yellowMode={yellowMode} /></ProtectedRoute>}></Route>
        <Route path="/HamburgerMenu"
        element={<HamburgerMenu darkMode={darkMode} purpleMode={purpleMode} />}></Route>
        <Route path="/MappingDialog"
        element={<MappingDialog/>}></Route>
        <Route path="/RunTable/:projectname"
        element={<RunTable darkMode={darkMode} purpleMode={purpleMode} redMode={redMode} blueMode={blueMode} yellowMode={yellowMode} />}></Route>
        <Route path="/EditProjectDialog"
        element={<EditProjectDialog/>}></Route>
        <Route path="/progressBar"
        element={<progressBar/>}></Route>
        <Route path="/settings"
        element={<Settings 
                      darkMode={darkMode} 
                      setDarkMode={setDarkMode} 
                      purpleMode={purpleMode} 
                      setPurpleMode={setPurpleMode} 
                      redMode={redMode} 
                      setRedMode={setRedMode}
                      blueMode={blueMode}
                      setBlueMode={setBlueMode} 
                      yellowMode={yellowMode}
                      setYellowMode={setYellowMode}
                />}></Route>
        <Route path="/hour"
        element={<HourCounter/>}></Route>
        <Route path="/datepicker"
        element={<DatePickerComponent/>}></Route>
        <Route path="/analogclock"
        element={<AnalogClock />}></Route>
        {/*<Route exact path="/transformations"
        element={<Transformations />}></Route>
        <Route exact path="/transformations2"
        element={<Transformations2 />}></Route>*/}
        {/* <Route exact path="/login"
        element={<Login />}></Route> */}
      </Route>
      </Routes>
    </Router>
  );
}

export default App;