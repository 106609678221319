import React, { useState } from 'react';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai'; 
import { FaTable } from "react-icons/fa";
import { MdTableView } from "react-icons/md";
import { AiOutlineFunction } from "react-icons/ai";
import { BsDatabaseFillLock } from "react-icons/bs";
import '../../style/EditorLayout.css';

const DatabaseContent = ({ dbMetadata }) => {
  const [selectedSchema, setSelectedSchema] = useState('All Schemas');
  const [expandedSections, setExpandedSections] = useState({});
  const [expandedTables, setExpandedTables] = useState({}); 

  const schemaOptions = [`All Schemas (${Object.keys(dbMetadata).length})`, 
    ...Object.keys(dbMetadata)];

  const displayedData =
    selectedSchema === `All Schemas (${Object.keys(dbMetadata).length})`
      ? dbMetadata
      : { [selectedSchema]: dbMetadata[selectedSchema] };

  const toggleSection = (schema, section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [schema]: {
        ...prev[schema],
        [section]: !prev[schema]?.[section],
      },
    }));
  };

  const toggleTable = (schema, tableName) => {
    setExpandedTables((prev) => ({
      ...prev,
      [schema]: {
        ...prev[schema],
        [tableName]: !prev[schema]?.[tableName],
      },
    }));
  };

  const sectionIcons = {
    tables: <FaTable />,
    views: <MdTableView />,
    functions: <AiOutlineFunction />,
    procedures: <BsDatabaseFillLock />,
  };

  return (
    <div className='metadata-section'>
      <div className='schema-select'>
        <label htmlFor='schema-dropdown' className='schema-dropdown-label'>
          Schema:
        </label>
        <select
          id='schema-dropdown'
          value={selectedSchema}
          onChange={(e) => setSelectedSchema(e.target.value)}
        >
          {schemaOptions.map((schema) => (
            <option key={schema} value={schema}>
              {schema}
            </option>
          ))}
        </select>
      </div>

      <div className='filtered-schema-details'>
        {displayedData && Object.entries(displayedData).map(([schema, details]) => (
          <div key={schema} className='schema-section'>
            <div className='schema-content'>
              {['tables', 'views', 'functions', 'procedures'].map((section) => (
                <div key={section} className='schema-item'>
                  <div
                    className='section-header'
                    onClick={() => toggleSection(schema, section)}
                  >
                    {expandedSections[schema]?.[section] ? <AiOutlineMinus /> : <AiOutlinePlus />}
                    <div>{section.charAt(0).toUpperCase() + section.slice(1)}</div>
                  </div>
                  
                  {expandedSections[schema]?.[section] && (
                    <div className='section-content'>
                      {details[section].length > 0 ? (
                        <div className='items-container'>
                          {details[section].map((item, index) => (
                            <div key={index} className='database-item'>
                                {section !== 'tables' && 
                                    <div className='icon-container'>
                                            {sectionIcons[section]}
                                    </div> 
                                }   

                              {section === 'tables' ? (
                                <div className='table-details'>
                                  <div
                                    className='table-labels'
                                    onClick={() => toggleTable(schema, item.table_name)}
                                  >
                                    <div className='icon-container'>
                                        {sectionIcons[section]}
                                    </div>
                                    <span>{item.table_name}</span>
                                    <span className='row-label'>{item.row_count} rows</span>
                                  </div>

                                  {expandedTables[schema]?.[item.table_name] && (
                                    <div className='columns-container-editor'>
                                      {item.columns.map((col, colIndex) => (
                                        <div key={colIndex} className='column-item'>
                                          <span className='column-label-editor'>{col.name}</span>
                                          <span className='row-label'>({col.type})</span>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <span>{item}</span>
                              )}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p>No {section} found.</p>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DatabaseContent;
