import { Outlet } from 'react-router-dom';
import '../style/LayoutStyles.css'
import NavBar from './NavBar';
import SideBar from './SideBar';
const Layout = ({ darkMode, purpleMode, redMode, blueMode, yellowMode }) => {
    return (    
        <div className={`layout-container ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>
            <div className='display-container'>
                <div className='side-bar'>
                    <SideBar darkMode={darkMode} purpleMode={purpleMode} redMode={redMode} blueMode={blueMode} yellowMode={yellowMode} />
                </div>
                <div className={`App ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>
                    <div className={`logo-container ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''}`}>
                        <img src={require('../img/thumbnail_4Vue-V5-transformed.png')} alt="4SEER Logo"  className="logo-styles"/>
                    </div>
                    <div className={`page ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>          
    )
}

export default Layout;