import '../../style/ScrollableBox.css'
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useState, useEffect } from 'react';

const SelectTargetInput = ({showTargetDialog, setDisableSaveAndRun, newProjectData, handleInputChange, selectedFile, setButtonsDisabled, disableTargetOnValidate, targetValidationResult, selectedTarget, setValidateButton, setTargetValidate}) => {
    console.log('showTargetDialog', showTargetDialog, selectedTarget);
    const [showPassword, setShowPassword] = useState(false);
      return (
        <div>
          {setButtonsDisabled(targetValidationResult === 'Invalid configuration')}
          {setDisableSaveAndRun(false)}
            {showTargetDialog && selectedTarget === 'MS SQL Server' && (
                  <div className="target-config">
                    <div className='header-title-addProjects'>MS SQL Server</div>
                    <div className="scrollable-box-AddProjectsDialog">
                    <div className="label-input-container">
                      <label className="progress-labels">Server</label>
                      <div className="input-container">
                      <input
                        type="text"
                        placeholder="Server"
                        value={newProjectData.servernamet}
                        onChange={(e) => handleInputChange(e, 'servernamet')}
                        disabled={disableTargetOnValidate}
                      />
                    </div>
                    </div>
                    <div className="label-input-container">
                      <label className="progress-labels">Portname</label>
                      <div className="input-container">
                        <input
                          type="text"
                          placeholder="Type The ports"
                          value={newProjectData.portt}
                          onChange={(e) => handleInputChange(e, 'portt')}
                          disabled={disableTargetOnValidate}
                        />
                      </div>
                      </div>
                    <div className="label-input-container">
                      <label className="progress-labels">Database</label>
                      <div className="input-container">
                      <input
                        type="text"
                        placeholder="Database"
                        value={newProjectData.databasenamet}
                        onChange={(e) => handleInputChange(e, 'databasenamet')}
                        disabled={disableTargetOnValidate}
                      />
                    </div>
                    </div>
            
                    <div className="label-input-container">
                      <label className="progress-labels">Username</label>
                      <div className="input-container">
                      <input
                        type="text"
                        placeholder="Username"
                        value={newProjectData.usernamet}
                        onChange={(e) => handleInputChange(e, 'usernamet')}
                        disabled={disableTargetOnValidate}
                      />
                    </div>
                    </div>
                    <div className="label-input-container">
                      <label className="progress-labels">Password</label>
                      <div className="input-container-source-target">
                        <div className="input-wrapper">
                          <input
                              type={showPassword ? 'text' : 'password'}
                              placeholder="Type The Password"
                              value={newProjectData.passwordt}
                              onChange={(e) => handleInputChange(e, 'passwordt')}
                              disabled={disableTargetOnValidate}
                          />
                          <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                              {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </span>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
            )}

            {showTargetDialog && selectedTarget === 'Azure SQL Server' && (
            <div className="target-config">
              <div className='header-title-addProjects'>Azure SQL Server</div>
              <div className="scrollable-box-AddProjectsDialog">
            <div className="label-input-container">
              <label className="progress-labels">Server</label>
              <div className="input-container">
              <input
                type="text"
                placeholder="Server"
                value={newProjectData.servernamet}
                onChange={(e) => handleInputChange(e, 'servernamet')}
                disabled={disableTargetOnValidate}
              />
            </div>
            </div>
            <div className="label-input-container">
              <label className="progress-labels">Portname</label>
              <div className="input-container">
                <input
                  type="text"
                  placeholder="Type The ports"
                  value={newProjectData.portt}
                  onChange={(e) => handleInputChange(e, 'portt')}
                  disabled={disableTargetOnValidate}
                />
              </div>
              </div>
            <div className="label-input-container">
              <label className="progress-labels">Database</label>
              <div className="input-container">
              <input
                type="text"
                placeholder="Database"
                value={newProjectData.databasenamet}
                onChange={(e) => handleInputChange(e, 'databasenamet')}
                disabled={disableTargetOnValidate}
              />
            </div>
            </div>

              <div className="label-input-container">
              <label className="progress-labels">Username</label>
              <div className="input-container">
              <input
                type="text"
                placeholder="Username"
                value={newProjectData.usernamet}
                onChange={(e) => handleInputChange(e, 'usernamet')}
                disabled={disableTargetOnValidate}
              />
            </div>
            </div>
            <div className="label-input-container">
              <label className="progress-labels">Password</label>
              <div className="input-container-source-target">
                        <div className="input-wrapper">
                          <input
                              type={showPassword ? 'text' : 'password'}
                              placeholder="Type The Password"
                              value={newProjectData.passwordt}
                              onChange={(e) => handleInputChange(e, 'passwordt')}
                              disabled={disableTargetOnValidate}
                          />
                          <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                              {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </span>
                        </div>
                </div>
            </div>
            </div>
            </div>

            )}

            {showTargetDialog && selectedTarget === 'Azure Data Lake Table Storage' && (
              <div className="target-config">
                <div className='header-title-addProjects'>Azure Data Lake Table Storage</div>
                <div className="scrollable-box-AddProjectsDialog">
                <div className="label-input-container">
                <label className="progress-labels">AccountName</label>
                <div className="input-container">
                        <input
                          type="text"
                          placeholder="Type the AccountName"
                          value={newProjectData.accountnames}
                          onChange={(e) => handleInputChange(e, 'accountnames')}
                          disabled={disableTargetOnValidate}
                        />
                    </div>
                    </div>
                    <div className="sourcesystem-config">
                <div className="label-input-container">
                <label className="progress-labels">AccountKey</label>
                <div className="input-container">
                        <input
                          type="text"
                          placeholder="Type the AccountKey"
                          value={newProjectData.accountkeys}
                          onChange={(e) => handleInputChange(e, 'accountkeys')}
                          disabled={disableTargetOnValidate}
                        />
                    </div>
                    </div>
                    <div className="label-input-container">
                      <label className="progress-labels">ContainerName</label>
                      <div className="input-container">
                        <input
                          type="text"
                          placeholder="Type The containername"
                          value={newProjectData.containernames}
                          onChange={(e) => handleInputChange(e, 'containernames')}
                          disabled={disableTargetOnValidate}
                        />
                      </div>
                    </div>
                    </div>
                  </div>
                  </div>
            )}

            {showTargetDialog && selectedTarget === 'Azure CosmosDB' && (
            <div className="target-config">
              <div className='header-title-addProjects'>Azure CosmoDB</div>
              <div className="scrollable-box-AddProjectsDialog">
                              <div className="label-input-container">
                      <label className="progress-labels">Portname</label>
                      <div className="input-container">
                        <input
                          type="text"
                          placeholder="Type The ports"
                          value={newProjectData.portt}
                          onChange={(e) => handleInputChange(e, 'portt')}
                          disabled={disableTargetOnValidate}
                        />
                      </div>
                      </div>
                      <div className="label-input-container">
                      <label className="progress-labels">Primary</label>
                      <div className="input-container">
                        <input
                          type="text"
                          placeholder="Type The primary_key"
                          value={newProjectData.primary_keyt}
                          onChange={(e) => handleInputChange(e, 'primary_keyt')}
                          disabled={disableTargetOnValidate}
                        />
                      </div>
                  </div>
                      <div className="label-input-container">
                      <label className="progress-labels">Hostname</label>
                      <div className="input-container">
                        <input
                          type="text"
                          placeholder="Type The hostname"
                          value={newProjectData.hostnamet}
                          onChange={(e) => handleInputChange(e, 'hostnamet')}
                          disabled={disableTargetOnValidate}
                        />
                      </div>
                      </div>
                  
                      <div className="label-input-container">
                      <label className="progress-labels">Username</label>
                      <div className="input-container">
                        <input
                          type="text"
                          placeholder="Type The Username"
                          value={newProjectData.usernamet}
                          onChange={(e) => handleInputChange(e, 'usernamet')}
                          disabled={disableTargetOnValidate}
                        />
                      </div>
                  </div>
            
                      <div className="label-input-container">
                      <label className="progress-labels">Password</label>
                      <div className="input-container-source-target">
                        <div className="input-wrapper">
                          <input
                              type={showPassword ? 'text' : 'password'}
                              placeholder="Type The Password"
                              value={newProjectData.passwordt}
                              onChange={(e) => handleInputChange(e, 'passwordt')}
                              disabled={disableTargetOnValidate}
                          />
                          <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                              {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </span>
                        </div>
                      </div>
                  </div>
                  </div>
                  </div>
            )}

            {showTargetDialog && selectedTarget === 'MongoDB' && (
                  <div className="target-config">
                    <div className='header-title-addProjects'>MongoDB</div>     
                    <div className="scrollable-box-AddProjectsDialog">               
                    <div className="label-input-container">
                      <label className="progress-labels">Hostname</label>
                      <div className="input-container">
                      <input
                        type="text"
                        placeholder="Type Your Hostname"
                        value={newProjectData.hostnamet}
                        onChange={(e) => handleInputChange(e, 'hostnamet')}
                        disabled={disableTargetOnValidate}
                      />
                    </div>
                    </div>
                    <div className="label-input-container">
                      <label className="progress-labels">Portname</label>
                      <div className="input-container">
                      <input
                        type="text"
                        placeholder="Type Your Portname"
                        value={newProjectData.portt}
                        onChange={(e) => handleInputChange(e, 'portt')}
                        disabled={disableTargetOnValidate}
                      />
                    </div>
                    </div>
                    <div className="label-input-container">
                      <label className="progress-labels">AuthDatabase</label>
                      <div className="input-container">
                      <input
                        type="text"
                        placeholder="Type your auth_database"
                        value={newProjectData.auth_databaset}
                        onChange={(e) => handleInputChange(e, 'auth_databaset')}
                        disabled={disableTargetOnValidate}
                      />
                    </div>
                    </div>
                    <div className="label-input-container">
                      <label className="progress-labels">Username</label>
                      <div className="input-container">
                      <input
                        type="text"
                        placeholder="Type your Username"
                        value={newProjectData.usernamet}
                        onChange={(e) => handleInputChange(e, 'usernamet')}
                        disabled={disableTargetOnValidate}
                      />
                    </div>
                    </div>
                    <div className="label-input-container">
                    <label className="progress-labels">Password</label>
                    <div className="input-container-source-target">
                        <div className="input-wrapper">
                          <input
                              type={showPassword ? 'text' : 'password'}
                              placeholder="Type The Password"
                              value={newProjectData.passwordt}
                              onChange={(e) => handleInputChange(e, 'passwordt')}
                              disabled={disableTargetOnValidate}
                          />
                          <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                              {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
            )}

            {showTargetDialog && selectedTarget === 'Text/Csv' && (
              <div className="target-config">
                <div className='header-title-addProjects'>Text/CSV</div>
              <div className="label-input-container">
                <label className="progress-labels">Filepath</label>
                <div className="input-container">
                  <input
                    type="text"
                    placeholder={selectedFile ? selectedFile.name : 'Filepath'}
                    value={newProjectData.filepatht}
                    onChange={(e) => handleInputChange(e, 'filepatht')}
                    disabled={disableTargetOnValidate}
                  />
                </div>
              </div>
            
            </div>
            )}
                
            {showTargetDialog && selectedTarget === 'Azure Blob Storage' && (
                  <div className="target-config">
                    <div className='header-title-addProjects'>Azure Blob Storage</div>
                    <div className="scrollable-box-AddProjectsDialog">
                  <div className="label-input-container">
                  <label className="progress-labels">AccountName</label>
                  <div className="input-container">
                        <input
                          type="text"
                          placeholder="Type the AccountName"
                          value={newProjectData.accountnames}
                          onChange={(e) => handleInputChange(e, 'accountnames')}
                          disabled={disableTargetOnValidate}
                        />
                    </div>
                    </div>
                    <div className="sourcesystem-config">
                  <div className="label-input-container">
                  <label className="progress-labels">AccountKey</label>
                  <div className="input-container">
                        <input
                          type="text"
                          placeholder="Type the AccountKey"
                          value={newProjectData.accountkeys}
                          onChange={(e) => handleInputChange(e, 'accountkeys')}
                          disabled={disableTargetOnValidate}
                        />
                    </div>
                    </div>
                  </div>
                  <div className="label-input-container">
                      <label className="progress-labels">ContainerName</label>
                      <div className="input-container">
                        <input
                          type="text"
                          placeholder="Type The containername"
                          value={newProjectData.containernames}
                          onChange={(e) => handleInputChange(e, 'containernames')}
                          disabled={disableTargetOnValidate}
                        />
                      </div>
                    </div>
                    <div className="label-input-container">
                        <label className="progress-labels">Excel FileName</label>
                        <div className="input-container">
                          <input
                            type="text"
                            placeholder= "ex: Filename.xlsx"
                            value={newProjectData.excelfilenamet}
                            onChange={(e) => handleInputChange(e, 'excelfilenamet')}
                            disabled={disableTargetOnValidate}
                          />
                        </div>
                      </div>
                    </div>
                    </div>
                
            )}

            {showTargetDialog && selectedTarget === 'Azure Blob Storage Flatfiles' && (
              <div className="target-config">
                <div className='header-title-addProjects'>Azure Blob Storage Flatfiles</div>
                <div className="scrollable-box-AddProjectsDialog">
                  <div className="label-input-container">
                  <label className="progress-labels">AccountName</label>
                  <div className="input-container">
                        <input
                          type="text"
                          placeholder="Type the AccountName"
                          value={newProjectData.accountnames}
                          onChange={(e) => handleInputChange(e, 'accountnames')}
                          disabled={disableTargetOnValidate}
                        />
                    </div>
                    </div>
                    <div className="sourcesystem-config">
                  <div className="label-input-container">
                  <label className="progress-labels">AccountKey</label>
                  <div className="input-container">
                        <input
                          type="text"
                          placeholder="Type the AccountKey"
                          value={newProjectData.accountkeys}
                          onChange={(e) => handleInputChange(e, 'accountkeys')}
                          disabled={disableTargetOnValidate}
                        />
                    </div>
                    </div>
                    <div className="label-input-container">
                      <label className="progress-labels">Containername</label>
                      <div className="input-container">
                        <input
                          type="text"
                          placeholder="Type The containername"
                          value={newProjectData.containernames}
                          onChange={(e) => handleInputChange(e, 'containernames')}
                          disabled={disableTargetOnValidate}
                        />
                      </div>
                    </div>
                  </div>
                  </div>
                  </div>
            )}

            {showTargetDialog && selectedTarget === 'Azure Blob Storage Excel' && (
                  <div className="target-config">
                    <div className='header-title-addProjects'>Azure Blob Storage Excel</div>
                    <div className="scrollable-box-AddProjectsDialog">
            <div className="label-input-container">
                      <label className="progress-labels">AccountName</label>
                      <div className="input-container">
                      <input
                        type="text"
                        placeholder="Type your AccountName"
                        value={newProjectData.accountnamet}
                        onChange={(e) => handleInputChange(e, 'accountnamet')}
                        disabled={disableTargetOnValidate}
                      />
                    </div>
                    <div className="label-input-container">
                      <label className="progress-labels">AccountKey</label>
                      <div className="input-container">
                      <input
                        type="password"
                        placeholder="AccountKey"
                        value={newProjectData.accountkeyt}
                        onChange={(e) => handleInputChange(e, 'accountkeyt')}
                        disabled={disableTargetOnValidate}
                      />
                    </div>
                    </div>
                    <div className="label-input-container">
                      <label className="progress-labels">ContainerName</label>
                      <div className="input-container">
                      <input
                        type="password"
                        placeholder="Password"
                        value={newProjectData.containernamet}
                        onChange={(e) => handleInputChange(e, 'containernamet')}
                        disabled={disableTargetOnValidate}
                      />
                    </div>
                    </div>
                    </div>
                  </div>
                  </div>
            )}

            {showTargetDialog && selectedTarget === 'MySQL' && (
            <div className="target-config">
              <div className='header-title-addProjects'>MySQL</div>
              <div className="scrollable-box-AddProjectsDialog">
                <div className="label-input-container">
                <label className="progress-labels">Hostname</label>
                <div className="input-container">
                    <input
                        type="text"
                        placeholder="Type The HostName"
                        value={newProjectData.hostnamet}
                        onChange={(e) => handleInputChange(e, 'hostnamet')}
                        disabled={disableTargetOnValidate}
                        />
                    </div>
                </div>
                <div className="label-input-container">
                <label className="progress-labels">Portname</label>
                <div className="input-container">
                  <input
                    type="text"
                    placeholder="Type The Portname"
                    value={newProjectData.portt}
                    onChange={(e) => handleInputChange(e, 'portt')}
                    disabled={disableTargetOnValidate}
                            />
                      </div>
                </div>
                <div className="label-input-container">
                <label className="progress-labels">Database</label>
                <div className="input-container">
                    <input
                        type="text"
                        placeholder="Type The Database"
                        value={newProjectData.databasenamet}
                        onChange={(e) => handleInputChange(e, 'databasenamet')}
                        disabled={disableTargetOnValidate}
                                  />
                          </div>
                    </div>
            
                <div className="label-input-container">
                <label className="progress-labels">Username</label>
                <div className="input-container">
                      <input
                        type="text"
                        placeholder="Type The Username"
                        value={newProjectData.usernamet}
                        onChange={(e) => handleInputChange(e, 'usernamet')}
                        disabled={disableTargetOnValidate}
                                />
                          </div>
                    </div>
            
                <div className="label-input-container">
                <label className="progress-labels">Password</label>
                <div className="input-container-source-target">
                        <div className="input-wrapper">
                          <input
                              type={showPassword ? 'text' : 'password'}
                              placeholder="Type The Password"
                              value={newProjectData.passwordt}
                              onChange={(e) => handleInputChange(e, 'passwordt')}
                              disabled={disableTargetOnValidate}
                          />
                          <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                              {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </span>
                        </div>
                      </div>
                    </div>
                </div>
                </div>

            )}

            {showTargetDialog && selectedTarget === 'PostgreSQL' && (
            <div className="target-config">
              <div className='header-title-addProjects'>PostgreSQL</div>
              <div className="scrollable-box-AddProjectsDialog">
            <div className="label-input-container">
            <label className="progress-labels">Hostname</label>
            <div className="input-container">
                <input
                    type="text"
                    placeholder="Type The HostName"
                    value={newProjectData.hostnamet}
                    onChange={(e) => handleInputChange(e, 'hostnamet')}
                    disabled={disableTargetOnValidate}
                    />
                </div>
            </div>
            <div className="label-input-container">
            <label className="progress-labels">Portname</label>
            <div className="input-container">
                  <input
                    type="text"
                    placeholder="Type The Portname"
                    value={newProjectData.portt}
                    onChange={(e) => handleInputChange(e, 'portt')}
                    disabled={disableTargetOnValidate}
                            />
                      </div>
            </div>
            <div className="label-input-container">
            <label className="progress-labels">Database</label>
            <div className="input-container">
                <input
                    type="text"
                    placeholder="Type The Database"
                    value={newProjectData.databasenamet}
                    onChange={(e) => handleInputChange(e, 'databasenamet')}
                    disabled={disableTargetOnValidate}
                              />
                      </div>
                </div>

            <div className="label-input-container">
            <label className="progress-labels">Username</label>
            <div className="input-container">
                  <input
                    type="text"
                    placeholder="Type The Username"
                    value={newProjectData.usernamet}
                    onChange={(e) => handleInputChange(e, 'usernamet')}
                    disabled={disableTargetOnValidate}
                            />
                      </div>
                </div>

            <div className="label-input-container">
            <label className="progress-labels">Password</label>
            <div className="input-container-source-target">
                        <div className="input-wrapper">
                          <input
                              type={showPassword ? 'text' : 'password'}
                              placeholder="Type The Password"
                              value={newProjectData.passwordt}
                              onChange={(e) => handleInputChange(e, 'passwordt')}
                              disabled={disableTargetOnValidate}
                          />
                          <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                              {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </span>
                        </div>
                      </div>
                </div>
            </div>
            </div>

            )}

            {showTargetDialog && selectedTarget === 'Azure PostgreSQL' && (
            <div className="target-config">
              <div className='header-title-addProjects'>Azure PostgreSQL</div>
              <div className="scrollable-box-AddProjectsDialog">
                  <div className="label-input-container">
                  <label className="progress-labels">Hostname</label>
                  <div className="input-container">
                    <input
                        type="text"
                        placeholder="Type The HostName"
                        value={newProjectData.hostnamet}
                        onChange={(e) => handleInputChange(e, 'hostnamet')}
                        disabled={disableTargetOnValidate}
                        />
                    </div>
                  </div>
                  <div className="label-input-container">
                  <label className="progress-labels">Portname</label>
                  <div className="input-container">
                    <input
                    type="text"
                    placeholder="Type The Portname"
                    value={newProjectData.portt}
                    onChange={(e) => handleInputChange(e, 'portt')}
                    disabled={disableTargetOnValidate}
                            />
                      </div>
                  </div>
                  <div className="label-input-container">
                  <label className="progress-labels">Database</label>
                  <div className="input-container">
                      <input
                        type="text"
                        placeholder="Type The Database"
                        value={newProjectData.databasenamet}
                        onChange={(e) => handleInputChange(e, 'databasenamet')}
                        disabled={disableTargetOnValidate}
                                    />
                            </div>
                  </div>

                  <div className="label-input-container">
                  <label className="progress-labels">Portname</label>
                  <div className="input-container">
                  <input
                    type="text"
                    placeholder="Type The Portname"
                    value={newProjectData.portt}
                    onChange={(e) => handleInputChange(e, 'portt')}
                    disabled={disableTargetOnValidate}
                            />
                      </div>
                  </div>
              
                  <div className="label-input-container">
                  <label className="progress-labels">Username</label>
                  <div className="input-container">
                      <input
                          type="text"
                          placeholder="Type The Username"
                          value={newProjectData.usernamet}
                          onChange={(e) => handleInputChange(e, 'usernamet')}
                          disabled={disableTargetOnValidate}
                                  />
                          </div>
                    </div>
              
                  <div className="label-input-container">
                  <label className="progress-labels">Password</label>
                  <div className="input-container-source-target">
                        <div className="input-wrapper">
                          <input
                              type={showPassword ? 'text' : 'password'}
                              placeholder="Type The Password"
                              value={newProjectData.passwordt}
                              onChange={(e) => handleInputChange(e, 'passwordt')}
                              disabled={disableTargetOnValidate}
                          />
                          <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                              {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </span>
                        </div>
                      </div>
                      </div>
                  </div>
                  </div>
            )}

            {showTargetDialog && selectedTarget === 'Azure MySQL' && (
            <div className="target-config">
              <div className='header-title-addProjects'>Azure MySQL</div>
              <div className="scrollable-box-AddProjectsDialog">
                <div className="label-input-container">
                <label className="progress-labels">Hostname</label>
                <div className="input-container">
                    <input
                        type="text"
                        placeholder="Type The HostName"
                        value={newProjectData.hostnamet}
                        onChange={(e) => handleInputChange(e, 'hostnamet')}
                        disabled={disableTargetOnValidate}
                        />
                    </div>
                </div>
                
                <div className="label-input-container">
                <label className="progress-labels">Portname</label>
                <div className="input-container">
                      <input
                        type="text"
                        placeholder="Type The Portname"
                        value={newProjectData.portt}
                        onChange={(e) => handleInputChange(e, 'portt')}
                        disabled={disableTargetOnValidate}
                                />
                          </div>
                </div>

                <div className="label-input-container">
                <label className="progress-labels">Database</label>
                <div className="input-container">
                    <input
                        type="text"
                        placeholder="Type The Database"
                        value={newProjectData.databasenamet}
                        onChange={(e) => handleInputChange(e, 'databasenamet')}
                        disabled={disableTargetOnValidate}
                                  />
                          </div>
                    </div>
            
                <div className="label-input-container">
                <label className="progress-labels">Username</label>
                <div className="input-container">
                      <input
                        type="text"
                        placeholder="Type The Username"
                        value={newProjectData.usernamet}
                        onChange={(e) => handleInputChange(e, 'usernamet')}
                        disabled={disableTargetOnValidate}
                                />
                          </div>
                    </div>
            
                <div className="label-input-container">
                <label className="progress-labels">Password</label>
                <div className="input-container-source-target">
                        <div className="input-wrapper">
                          <input
                              type={showPassword ? 'text' : 'password'}
                              placeholder="Type The Password"
                              value={newProjectData.passwordt}
                              onChange={(e) => handleInputChange(e, 'passwordt')}
                              disabled={disableTargetOnValidate}
                          />
                          <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                              {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </span>
                        </div>
                      </div>
                    </div>
                </div>
                </div>

            )}

            {showTargetDialog && selectedTarget === 'EXCEL' && (
              <div className="target-config">
                <div className='header-title-addProjects'>Excel</div>
              <div className="label-input-container">
                <label className="progress-labels">Filepath</label>
                <div className="input-container">
                  <input
                    type="text"
                    placeholder={selectedFile ? selectedFile.name : 'Filepath'}
                    value={newProjectData.filepatht}
                    onChange={(e) => handleInputChange(e, 'filepatht')}
                    disabled={disableTargetOnValidate}
                  />
                </div>
              </div>
            </div>
            )}

            {selectedTarget && (
                    <div>
                    {setValidateButton(true)}
                    {setTargetValidate(true)}
                    {/* <button className="button1 button5 next-button2" onClick={handleValidate} disabled={!selectedTarget}>
                      Validate
                    </button> */}
                    {/* {targetValidationResult && (
                              <p className={targetValidationResult === 'Invalid configuration' ? 'error-text' : 'success-text'}>
                                {targetValidationResult}
                              </p>
                            )} */}
                  </div>
            )}
  </div>
    )
}

export default SelectTargetInput;