import Select from 'react-select';
import { useState } from "react";
import '../../style/ProjectNameEntry.css'


const ProjectNameEntry = ({newProjectData, handleInputChange, setDisableSaveAndRun, 
    selectedLoadTypeOption, setSelectedLoadTypeOption, LoadTypeOptions}) => {    
    
    const [incrementalDialogOpen, setIncrementalDialogOpen] = useState(false);

    const handleSelectChange = (selected) => {
        setSelectedLoadTypeOption(selected);
        if (selected.value === 'incremental') {
          setIncrementalDialogOpen(true);
        }
      };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#F7F8F9' : '#fff', 
            color: state.isSelected ? '#6A6A6A' : '#6A6A6A', 
            ':hover': {
                backgroundColor: '#3F787E', 
                color: '#fff',
            },
        }),
    };

    return (
            // <div className="center-content1">
                <div className="addnewname flex flex-col items-start w-full">
                    {setDisableSaveAndRun(false)}
                    <label className="progress-labels text-left">Project Name</label>
                    <div className="input-container w-full">
                        <input
                        type="text"
                        placeholder="Enter the Project Name"
                        value={newProjectData.projectname}
                        onChange={(e) => handleInputChange(e, 'projectname')}
                        />
                    </div>
                    <div className="addnewname flex flex-col items-start w-full">
                        <label className="progress-labels text-left">Load Type</label>
                        <Select
                        options={LoadTypeOptions}
                        value={selectedLoadTypeOption}
                        onChange={(selected) => handleSelectChange(selected)}
                        className="select-wrapper"
                        styles={customStyles}
                        />
                    </div>
                </div>
            // </div>  
      )
}
export default ProjectNameEntry;