import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../style/Calendar.css';

const CalendarComponent = ({handleInputChange, calendarType, disabled, darkMode, purpleMode, redMode, blueMode, yellowMode, editMode, formattedDate}) => {
  const [date, setDate] = useState(new Date());
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (formattedDate) {
      setInputValue(formattedDate);
    } else {
      const currentDate = new Date();
      const formattedCurrentDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
      setInputValue(formattedCurrentDate);
    }
  }, [formattedDate]);

  const toggleCalendar = () => {
    setCalendarOpen(!isCalendarOpen);
  };

  const handleDateChange = (newDate) => {
    setDate(newDate);
    const formattedDate = `${newDate.getFullYear()}-${(newDate.getMonth() + 1).toString().padStart(2, '0')}-${newDate.getDate().toString().padStart(2, '0')}`;
    setInputValue(formattedDate);
    handleInputChange(calendarType, formattedDate); // Add this line
  };

  const handleOkClick = () => {
    setCalendarOpen(false);
  };

  const handleCancelClick = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
    setInputValue(formattedDate);
    handleInputChange(calendarType, formattedDate);
    setCalendarOpen(false);
  };

  const tileClassName = ({ date, view }) => {
    if (date.getDate() === 10 && date.getMonth() === 0) {
      return 'highlighted-date';
    }
    return null;
  };

  const getCalendarClassName = () => {
    let className = 'react-calendar';

    if (darkMode) {
      className += ' dark-mode';
    } else if (purpleMode) {
      className += ' purple-mode';
    } else if (redMode) {
      className += ' red-mode';
    } else if (blueMode) {
      className += ' blue-mode';
    } else if (yellowMode) {
      className += ' yellow-mode';
    }

    return className;
  };

  return (
    <div className={`calendar-input-container ${isCalendarOpen ? 'calendar-open' : ''} ${disabled ? 'disabled-clock' : ''} ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>
      <span className='top-left-text'>Select date</span>
      <div className='center-area'>
        <span className='center-text'>Date</span>
        <div className='horizontal-line'></div>
        <div className='input-border'>
          <div className='calendar-input'>
            <input
              type='text'
              className={`calendar-text-input ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}
              value={inputValue}
              onClick={disabled ? null : toggleCalendar}
              readOnly
              placeholder='dd/mm/yyyy'
            />
            <label className= {`calendar-icon ${disabled ? 'disabled-clock' : ''}`} onClick={disabled ? null : toggleCalendar}>📅</label>
          </div>
        </div>
      </div>
      {isCalendarOpen && !disabled &&(
        <div className='calendar-container'>
          <div className='c'>
            <Calendar
              onChange={handleDateChange}
              value={date}
              tileClassName={tileClassName}
              minDate={new Date()} 
              className={getCalendarClassName()}
            />
          </div>
        </div>
      )}
      <div className={`bottom-right-corner ${isCalendarOpen ? 'calendar-open' : ''}`}>
        <button onClick={handleCancelClick}>Cancel</button>
        <button onClick={handleOkClick}>OK</button>
      </div>
    </div>
  );
}

export default CalendarComponent;
