import { useState, useRef, useEffect } from "react";
import Editor from "@monaco-editor/react";
import '../../style/SQLEditor.css';

function SQLEditor({ activeDatabase }) {

  const sampleQueryResults = [
    { id: 1, name: "Alice", age: 30, email: "alice@example.com", city: "New York" },
    { id: 2, name: "Bob", age: 25, email: "bob@example.com", city: "San Francisco" },
    { id: 3, name: "Charlie", age: 35, email: "charlie@example.com", city: "Los Angeles" },
    { id: 4, name: "Diana", age: 28, email: "diana@example.com", city: "Seattle" },
    { id: 5, name: "Eve", age: 22, email: "eve@example.com", city: "Chicago" },
    { id: 6, name: "Frank", age: 40, email: "frank@example.com", city: "Boston" },
    { id: 7, name: "Grace", age: 27, email: "grace@example.com", city: "Denver" },
    { id: 8, name: "Hank", age: 31, email: "hank@example.com", city: "Austin" },
    { id: 9, name: "Ivy", age: 24, email: "ivy@example.com", city: "Portland" },
    { id: 10, name: "Jack", age: 29, email: "jack@example.com", city: "Miami" },
  ];
  const editorRef = useRef(null);
  const [query, setQuery] = useState("");
  const [queryResult, setQueryResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [editorHeight, setEditorHeight] = useState("300px");

   // Store editor instance on mount
   const handleEditorDidMount = (editor) => {
    editorRef.current = editor;
  };

  // Ensure the editor resizes with the window
  useEffect(() => {
    const handleResize = () => {
      if (editorRef.current) {
        editorRef.current.layout(); 
      }
    };

    window.addEventListener("resize", handleResize); // Listen to window resize
    return () => window.removeEventListener("resize", handleResize); // Cleanup
  }, []);

  const onQuerySubmit = async (query) => {
    const connectionToken = localStorage.getItem("connectionToken");

    console.log('activeDatabase value in SQLEditor render: ', activeDatabase);

    if (!activeDatabase) {
      console.log("Please select an active database.");
      return;
    }

    if (!connectionToken) {
      alert("No connection token found. Please establish connection with a server.");
      return;
    }

    try {
      setError("");
      setIsLoading(true);
      setQueryResult(null);
      

      const url = `${process.env.REACT_APP_FASTAPI_URL}/v1/editor/execute-sql`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${connectionToken}`,
        },
        body: JSON.stringify({ query, database: activeDatabase }),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.detail || "Failed to execute query.");
      }

      setQueryResult(result.data); 
      console.log("Query Result:", result.data);

      if (isSelectQuery(query)) {
        setEditorHeight("100px"); 
      } else {
        setEditorHeight("300px"); 
      }
    } catch (error) {
      console.error("Error executing query:", error);
      setError(error.message || "An error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (value) => setQuery(value);

  const handleRun = () => onQuerySubmit(query);

  const isSelectQuery = (query) => query.trim().toLowerCase().startsWith("select");

  // Helper to check if query result is a CRUD operation
  const isCrudOperation = (result) => 
    typeof result === "string" && 
    (result.toLowerCase().includes("executed successfully") || 
     result.toLowerCase().includes("row affected"));
  

  return (
    <div className="sql-editor">
      <Editor
        height={editorHeight}
        defaultLanguage="sql"
        value={query}
        onChange={handleChange}
      />
      <button className="run-button" onClick={handleRun} disabled={isLoading}>
        {isLoading ? "Running..." : "Run"}
      </button>

      {error && <div className="error-message">Error: {error}</div>}

      {queryResult && (
        <div className="query-result">
          <p>Query Results</p>
          {isCrudOperation(queryResult) ? (
            <p>{queryResult}</p>
          ) : Array.isArray(queryResult) && queryResult.length > 0 ? (
            <div className="query-result-wrapper">
              <table>
                <thead>
                  <tr>
                    {Object.keys(queryResult[0]).map((key) => (
                      <th key={key}>{key}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {queryResult.map((row, index) => (
                    <tr key={index}>
                      {Object.values(row).map((value, idx) => (
                        <td key={idx}>
                          {value === null ? "NULL" : value.toString()}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No results found.</p>
          )}
        </div>
      )}
    </div>
  );
}

export default SQLEditor;
