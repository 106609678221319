import React, { useState, useEffect } from "react";
import { CiSearch } from "react-icons/ci";
import { FaChevronDown } from "react-icons/fa";
import BreadcrumbItem from "../Breadcrumbs";
import { useLocation  } from 'react-router-dom';
import '../../style/ProjectStatus.css';

const DMLayer3 = () => { 
    const [filter, setFilter] = useState("All"); 
    const [sortBy, setSortBy] = useState({ field: 'Creation Date', isAscending: true });
    const [data, setData] = useState([]);
    const loggedInUserEmail = JSON.parse(localStorage.getItem('user'))?.email;
    const location = useLocation();
    const { projectData } = location.state || {};

    const projectid = projectData?.projectid;
    const rundate = projectData?.rundate;

    console.log("projectdetails in dmlayer3: ", projectData, projectid, rundate);

    useEffect(() => {
        if (projectid && rundate) {
            const fetchProjects = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/v1/project/data-migration/entitylevel-status?email=${loggedInUserEmail}&project_id=${projectid}&run_date=${rundate}`);
                    const projects = await response.json();
                    setData(projects);
                    console.log('DM Projects Entity level data from endpoint: ', data);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
            fetchProjects();
        }
    }, [loggedInUserEmail, projectid, rundate]);

    const handleFilterChange = (e) => setFilter(e.target.value);

    const handleSortByChange = (e) => {
        const selectedField = e.target.value;
        setSortBy(prev => ({
            field: selectedField,
            isAscending: prev.field === selectedField ? !prev.isAscending : true,
        }));
    };

    return (
        <div className='flex-grow'>
            <div className='sqlprojects-layout'>
                <nav aria-label="breadcrumb-trails">
                    <ul className="breadcrumb-unit">
                    <BreadcrumbItem to="/" label="Home" isActive={false} />
                        <BreadcrumbItem to="/scheduler" label="Active Jobs" isActive={false} />
                        <BreadcrumbItem to="/project-status" label="Project Status" isActive={false} />
                        <BreadcrumbItem to="/dm-object-details" label="DM Entity Level Status" isActive={true} />
                    </ul>
                </nav>
                <div className='header-sql-exec-projects'>
                    <div>
                        <h4 className="page-heading">DM Entity Level Status</h4>
                        <span className="sub-title">Change subtitle</span> 
                    </div>
                    {/* <button className='add-sql-exec-projects' onClick={handleAddProject}>
                        + Add Project
                    </button> */}
                </div>
                <div className="project-details-layout">
                    <div className="searchbar-filter-sort-layout">
                        <div className="search-container-sql-exec-projects">
                            <CiSearch className="search-icon" />
                            <input
                                type="text"
                                className="search-bar-sql-exec-projects"
                                placeholder="Search projects by name"
                            />
                        </div>
                
                        <div className="filter-sort-selection">
                            Filter:
                            <div className="dropdown-container-sql-exec-projects">
                                <select value={filter} onChange={handleFilterChange} className="dropdown-sql-exec-projects">
                                    <option value="All">All</option>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                </select>
                                <FaChevronDown className="dropdown-icon-sql-exec-projects" />
                            </div>
                        </div>
                
                        <div className="filter-sort-selection">
                            Sort by:
                            <div className="dropdown-container-sql-exec-projects">
                                <select value={sortBy.field} onChange={handleSortByChange} className="dropdown-sql-exec-projects">
                                    <option value="Creation Date">Creation Date</option>
                                    <option value="Last Update">Last Update</option>
                                    <option value="Active Status">Status</option>
                                </select>
                                <FaChevronDown className="dropdown-icon-sql-exec-projects" />
                            </div>
                        </div>
                    </div>
                    <div className='projects-listing'>
                        <div className='project-table-header'>
                            <div className='project-column-header-project-status-tab'>Object</div>
                            <div className='project-column-header-project-status-tab'>Number of Records</div>
                            <div className='project-column-header-project-status-tab'>Status</div>
                            <div className='project-column-header-project-status-tab'>Message</div>
                        </div>
                        <div className="scrollable-box-sql-project-details">
                            {data.length > 0 ? (
                            data.map(project => (
                            <div key={project.projectid} className='project-status-tab-row'>
                                <div className='project-status-tab-column'>{project.selectedobject}</div>
                                <div className='project-status-tab-column'>{project.reccount}</div>
                                <div className='project-status-tab-column'>{project.status}</div>
                                <div className='project-status-tab-column'>{project.message}</div>
                            </div>
                            ))
                            ) : (
                            <div className='no-projects'>No projects available</div>
                            )}
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
            
    );
}

export default DMLayer3;