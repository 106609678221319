import React, { useState, useEffect } from "react";
import BreadcrumbItem from "../Breadcrumbs";
import '../../style/ProjectStatus.css';
import DMProjectsTab from "./DMProjectsTab";
import SEProjectsTab from "./SEProjectsTab";
import { useLocation  } from 'react-router-dom';


const Layer1 = () => {
    // const { scheduleId } = useParams();
    const [activeTab, setActiveTab] = useState('dmProjects');
    const location = useLocation();
    const { jobData } = location.state || {};

    const scheduleId = jobData?.SchedulerId;

    useEffect(() => {
        console.log("Schedule ID in ProjectStatus as received in params: ", scheduleId);
    }, [scheduleId]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className='flex-grow'>
            <div className="project-status-layout">
                <nav aria-label="breadcrumb-trails">
                    <ul className="breadcrumb-unit">
                        <BreadcrumbItem to="/" label="Home" isActive={false} />
                        <BreadcrumbItem to="/scheduler" label="Active Jobs" isActive={false} />
                        <BreadcrumbItem to={`/project-status`} label="Project Status" isActive={true} />

                    </ul>
                </nav>

                <div className="tabs-layout">
                    <div className="tabs-header">
                        <div
                            className={`tab-item ${activeTab === 'dmProjects' ? 'active' : ''}`}
                            onClick={() => handleTabClick('dmProjects')}
                        >
                            DM Projects Status
                        </div>
                        <div
                            className={`tab-item ${activeTab === 'seProjects' ? 'active' : ''}`}
                            onClick={() => handleTabClick('seProjects')}
                        >
                            SE Projects Status
                        </div>
                    </div>
                    <div>
                        {activeTab === 'dmProjects' && (
                            <DMProjectsTab scheduleId = {scheduleId} />                            
                        )}
                        {activeTab === 'seProjects' && (
                            <SEProjectsTab scheduleId = {scheduleId} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Layer1;
