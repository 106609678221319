import React from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineClose} from 'react-icons/ai'; 
import '../style/HelpPage.css' 


const HelpPage = ({ onClose, darkMode, purpleMode }) => {
  return (
    <div className={`help-bot-dialog ${darkMode ? 'dark-mode' : ''}`}>
      <Link to="/NewPage">
      <img src={require('../img/bot.gif')} alt="Animated Bot" style={{ backgroundColor: 'transparent'}}/>  
      </Link>
    </div>
  );
};

export default HelpPage;