import React, { useState, useEffect } from "react";
import { CiSearch } from "react-icons/ci";
import { FaChevronDown } from "react-icons/fa";
import { Link } from 'react-router-dom';
// import '../../style/ProjectStatus.css';

const RunHistorySEProjectLevel = () => { 
    const [filter, setFilter] = useState("All"); 
    const [sortBy, setSortBy] = useState({ field: 'Creation Date', isAscending: true });
    const [data, setData] = useState([]);
    const loggedInUserEmail = JSON.parse(localStorage.getItem('user'))?.email;

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/v1/projectsql-execution/manual-projectlevel-status?email=${loggedInUserEmail}`);
                const projects = await response.json();
                setData(projects);
                console.log('SE Run History Projects level data from endpoint: ', data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchProjects();
    }, [loggedInUserEmail] );

    const handleFilterChange = (e) => setFilter(e.target.value);

    const handleSortByChange = (e) => {
        const selectedField = e.target.value;
        setSortBy(prev => ({
            field: selectedField,
            isAscending: prev.field === selectedField ? !prev.isAscending : true,
        }));
    };
    
    return (
            <div className="project-status-details-layout">
                <div className="searchbar-filter-sort-layout">
                    <div className="search-container-sql-exec-projects">
                        <CiSearch className="search-icon" />
                        <input
                            type="text"
                            className="search-bar-sql-exec-projects"
                            placeholder="Search projects by name"
                        />
                    </div>
            
                    <div className="filter-sort-selection">
                        Filter:
                        <div className="dropdown-container-sql-exec-projects">
                            <select value={filter} onChange={handleFilterChange} className="dropdown-sql-exec-projects">
                                <option value="All">All</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                            </select>
                            <FaChevronDown className="dropdown-icon-sql-exec-projects" />
                        </div>
                    </div>
            
                    <div className="filter-sort-selection">
                        Sort by:
                        <div className="dropdown-container-sql-exec-projects">
                            <select value={sortBy.field} onChange={handleSortByChange} className="dropdown-sql-exec-projects">
                                <option value="Creation Date">Creation Date</option>
                                <option value="Last Update">Last Update</option>
                                <option value="Active Status">Status</option>
                            </select>
                            <FaChevronDown className="dropdown-icon-sql-exec-projects" />
                        </div>
                    </div>
                </div>
                <div className='projects-status-listing'>
                    <div className='project-table-header'>
                        <div className='project-column-header-project-status-tab'>Project Name</div>
                        <div className='project-column-header-project-status-tab'>Total Objects</div>
                        <div className='project-column-header-project-status-tab'>Run Date</div>
                        <div className='project-column-header-project-status-tab'>Status</div>
                    </div>
                    <div className="scrollable-box-sql-project-details">
                        {data.length > 0 ? (
                        data.map(project => (
                        <div key={project.projectid} className='project-status-tab-row'>
                            {/* <Link to={{pathname: `/se-object-details/${project.projectid}`,state: { projectData: project },}}className="link-styling" style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}>
                                {project.projectname}
                            </Link> */}
                            <Link 
                                to={`/run-history-se-entity-status`} 
                                state={{ projectData: project }} 
                                className="link-styling project-status-tab-column" 
                                style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}
                            >
                                {project.projectname}
                            </Link>  
                            {/* <div className='project-column'>{project.projectname}</div> */}
                            <div className='project-status-tab-column'>{project.noofobjects}</div>
                            <div className='project-status-tab-column'>{new Date(project.rundate).toLocaleString()}</div>
                            <div className='project-status-tab-column'>{project.status}</div>
                        </div>
                        ))
                        ) : (
                        <div className='no-projects'>No projects available</div>
                        )}
                    </div>                        
                </div>
            </div>
    );
}

export default RunHistorySEProjectLevel;