import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AiOutlineClose,AiOutlineCaretLeft,AiOutlineCaretRight,AiOutlineStepForward} from 'react-icons/ai'; 
import { ImPrevious2 } from "react-icons/im";

function EditProjectDialog({
  isOpen,
  onClose,
  project,
  editingProjectValues,
  handleInputChange,
  handleSaveEdit,
  handleCancelEdit,
}) {
  console.log('editingProjectValues:', editingProjectValues)
  const [selectedFile, setSelectedFile] = useState(null);
  const [step, setStep] = useState(1); 
  const [data, setData] = useState([]);
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [newProjectData, setNewProjectData] = useState({
    projectname: '',sourcesystem: '',servernames: '',databasenames: '',usernames: '',passwords: '',filepaths: '', hostnames: '', ports: '',protocols: '',dbqs: '',users: '',db_names: '',accountnames: '',accountkeys: '',containernames: '',auth_databases:'',excelfilenames:'',taregtsystem: '',servernamet: '', databasenamet: '',usernamet: '',passwordt: '',filepatht: '', hostnamet: '', portt: '',protocolt: '',dbqt: '',usert: '', db_namet: '',accountnamet: '',accountkeyt: '',containernamet: '',auth_databaset:'',excelfilenamet:'',
  });
  const isactive = 1;
  const { projectname } = useParams();
  const loggedInUserEmail = JSON.parse(localStorage.getItem('user'))?.email;
  const handleSaveAndClose = () => {
    handleSaveEdit();
    onClose(); 
  };


  const handleSelectAllDbObjects = () => {
    const allDbObjects = data.map(dbobject => dbobject.sourceobjects);
    setSelectedObjects(allDbObjects);
  };
  
  const handleCheckboxChange = (sourceObject) => {
    if (selectedObjects.includes(sourceObject)) {
      setSelectedObjects(selectedObjects.filter(obj => obj !== sourceObject));
    } else {
      setSelectedObjects([...selectedObjects, sourceObject]);
    }
  };

  const handleDeselectAll = () => {
    setSelectedObjects([]);
  };
  
const handleFileSelect = (event) => {
  const selectedFile = event.target.files[0];

  if (selectedFile) {
    const filePath = URL.createObjectURL(selectedFile);
    setSelectedFile(selectedFile); 
    setNewProjectData({
      ...newProjectData,
      filepaths: filePath,
    });
  }
};
  
  const handleNext = () => {
    if (step === 1) {
      if (editingProjectValues.projectname.trim() === '') {
        alert('Please enter the project name.');
        return;
      }
      setStep(2); 
    }else if (step === 2) {
      if (editingProjectValues.sourcesystem.trim() === '') {
        alert('Please select a source system.');
        return;
      }
      const sourceSystems = [
        'SQL SERVER',
        'Azure SQL Server',
        'Azure Blob Storage Flatfiles',
        'Azure Data Lake Table Storage',
        'Azure Blob Storage Excel',
        'Azure MySQL',
        'Azure PostgreSQL',
        'Azure CosmosDB',
        'EXCEL',
        'Text/Csv',
        'PostgreSQL',
        'MySQL',
        'MongoDB'
      ];
    
      if (sourceSystems.includes(project.sourcesystem.toUpperCase())) {
        setStep(3); 
      }
    } else if (step === 3) {
      if (project.sourcesystem.includes('SQL SERVER' || 'Azure SQL Server')) {
        if (
          project.servernames.trim() === '' ||
          project.databasenames.trim() === '' ||
          project.usernames.trim() === '' ||
          project.passwords.trim() === ''
        ) {
          alert('Please fill in all SQL source fields.');
          return;
        }
      }
    } else if (step === 6) {
    } else if (step === 7) {
    }
    if (step < 7) {
      setStep(step + 1);
    }
  };

  const fetchProjectConfig = () => {
    fetch(process.env.REACT_APP_API_URL+`projectconfig?email=${loggedInUserEmail}&isactive=${isactive}&projectid=${project.projectid}`)
      .then((response) => response.json())
      .then((projectconfig) => {
        console.log('Projects received from backend:', projectconfig); // Add this line to check the received data
        setData(projectconfig);
      })
      .catch((error) => console.error('Error fetching data:', error));
  };
  
  useEffect(() => {
    fetchProjectConfig();
  }, [loggedInUserEmail, isactive, projectname]);

  const handlePrevious = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleUncheckAndMoveToRight = () => {
    const updatedSelectedObjects = [...selectedObjects];
    for (let i = 0; i < updatedSelectedObjects.length; i++) {
      if (updatedSelectedObjects[i]) {
        updatedSelectedObjects.splice(i, 1);
        break; 
      }
    }
    setSelectedObjects(updatedSelectedObjects);
  };

  const handleMoveToRight = () => {
    const newData = data.filter(dbobject => !selectedObjects.includes(dbobject.sourceobjects));
    setData(newData);
    setSelectedObjects([]);
  };

  return (
    <div className={`dialog-container ${isOpen ? 'open' : ''}`}>
      <div className="dialog-content">
        <div className="inner-border">
          <h1>Edit Project</h1>
          <button className="close-button" onClick={onClose}>
            <AiOutlineClose />
          </button>
          <div className="button-container12">
            <div className="step">
              <button
                className={`button3 button7 ${
                  step === 1 ? 'button-active' : 'button-inactive'
                }`}
              >
                1
              </button>
              <p className="step-label">Edit Project Name</p>
            </div>
            <div className="step">
              <button
                className={`button3 button7 ${
                  step === 2 ? 'button-active' : 'button-inactive'
                }`}
              >
                2
              </button>
              <p className="step-label">Source System</p>
            </div>
            <div className="step">
              <button
                className={`button3 button7 ${
                  step === 3 ? 'button-active' : 'button-inactive'
                }`}
              >
                3
              </button>
              <p className="step-label">Select Source Inputs</p>
            </div>
            <div className="step">
              <button
                className={`button3 button7 ${
                  step === 4 ? 'button-active' : 'button-inactive'
                }`}
              >
                4
              </button>
              <p className="step-label">Target System</p>
            </div>
            <div className="step">
              <button
                className={`button3 button7 ${
                  step === 5 ? 'button-active' : 'button-inactive'
                }`}
              >
                5
              </button>
              <p className="step-label">Select Target Inputs</p>
            </div>
            <div className="step">
              <button
                className={`button3 button7 ${
                  step === 6 ? 'button-active' : 'button-inactive'
                }`}
              >
                6
              </button>
              <p className="step-label">Mapping</p>
            </div>
          </div>

          {step === 1 && (
            <div className="center-content1">
              <div className="addnewname">
                <label>Project Name</label>
                <div className="input-container">
                  <input
                    type="text"
                    name="projectname"
                    value={editingProjectValues.projectname || ''}
                    onChange={(e) => handleInputChange(e, 'projectname')}
                  />
                </div>
              </div>
              <button
                className="button1 button5 next-button"
                onClick={handleNext}
              >
                Save &#8594;
              </button>
             
            </div>
          )}

          {step === 2 && (
            <div className="container1234">
              <div className="label-input-container">
                <label className="label-container2">Select Source</label>
                <div className="input-container">
                  <div className="select-container">
                    <select
                      className="selectvalue"
                      name="sourcesystem"
                      value={editingProjectValues.sourcesystem || ''}
                      onChange={(e) => {
                        handleInputChange(e, 'sourcesystem');
                        if (e.target.value === 'MS SQL Server') {
                          setStep(3); // Automatically shift to the next step
                        }
                      }}
                    >
                      <option value="">Select an option</option>
                      <option value="MS SQL Server">SQL Server</option>
                      <option value="Azure SQL Server">Azure SQL Server</option>
                      <option value="Azure Blob Storage Flatfiles">
                        Azure Blob Storage Flatfiles
                      </option>
                      <option value="Azure Data Lake Table Storage">
                        Azure Data Lake Table Storage
                      </option>
                      <option value="Azure Blob Storage Excel">
                        Azure Blob Storage Excel
                      </option>
                      <option value="Azure MySQL">Azure MySQL</option>
                      <option value="Azure PostgreSQL">Azure PostgreSQL</option>
                      <option value="Azure CosmosDB">Azure CosmosDB</option>
                      <option value="EXCEL">EXCEL</option>
                      <option value="Text/Csv">Text/Csv</option>
                      <option value="PostgreSQL">PostgreSQL</option>
                      <option value="MySQL">MySQL</option>
                      <option value="MongoDB">MongoDB</option>
                    </select>
                  </div>
                </div>
              </div>
              <button
                className="button1 button5 next-button"
                onClick={handleNext}
              >
                Save &#8594;
              </button>
              <button
                className="button2 button6 next-button1"
                onClick={handlePrevious}
              >
               Previous
              </button>
            </div>
          )}

          {step === 3 && (
            <div>
              {editingProjectValues.sourcesystem === 'MS SQL Server' && (
                <div className="sql-server-fields">
                  <div className="label-input-container">
                    <label className="label-container4">Server</label>
                    <div className="input-container">
                      <input
                        type="text"
                        placeholder="Type The Server"
                        value={editingProjectValues.servers}
                        onChange={(e) =>
                          handleInputChange(e, 'servernames')
                        }
                      />
                    </div>
                  </div>
                  <div className="label-input-container">
                    <label className="label-container">Database</label>
                    <div className="input-container">
                      <input
                        type="text"
                        placeholder="Type The Database"
                        value={editingProjectValues.databases}
                        onChange={(e) =>
                          handleInputChange(e, 'databasenames')
                        }
                      />
                    </div>
                  </div>

                  <div className="label-input-container">
                    <label className="label-container">Username</label>
                    <div className="input-container">
                      <input
                        type="text"
                        placeholder="Type The Username"
                        value={editingProjectValues.uids}
                        onChange={(e) => handleInputChange(e, 'usernames')}
                      />
                    </div>
                  </div>

                  <div className="label-input-container">
                    <label className="label-container">Password</label>
                    <div className="input-container">
                      <input
                        type="password"
                        placeholder="Type The Password"
                        value={editingProjectValues.pwds}
                        onChange={(e) =>
                          handleInputChange(e, 'passwords')
                        }
                      />
                    </div>
                  </div>
                
                </div>
              )}

                {editingProjectValues.sourcesystem === 'Azure SQL Server' && (
                <div className="sql-server-fields">
                  <div className="label-input-container">
                    <label className="label-container4">Server</label>
                    <div className="input-container">
                      <input
                        type="text"
                        placeholder="Type The Server"
                        value={editingProjectValues.servernames}
                        onChange={(e) =>
                          handleInputChange(e, 'servernames')
                        }
                      />
                    </div>
                  </div>

                  <div className="label-input-container">
                    <label className="label-container">Database</label>
                    <div className="input-container">
                      <input
                        type="text"
                        placeholder="Type The Database"
                        value={editingProjectValues.databasenames}
                        onChange={(e) =>
                          handleInputChange(e, 'databasenames')
                        }
                      />
                    </div>
                  </div>

                  <div className="label-input-container">
                    <label className="label-container">Username</label>
                    <div className="input-container">
                      <input
                        type="text"
                        placeholder="Type The Username"
                        value={editingProjectValues.usernames}
                        onChange={(e) => handleInputChange(e, 'usernames')}
                      />
                    </div>
                  </div>

                  <div className="label-input-container">
                    <label className="label-container">Password</label>
                    <div className="input-container">
                      <input
                        type="password"
                        placeholder="Type The Password"
                        value={editingProjectValues.passwords}
                        onChange={(e) =>
                          handleInputChange(e, 'passwords')
                        }
                      />
                    </div>
                  </div>
                
                </div>
              )}
              
{editingProjectValues.sourcesystem === 'MongoDB' && (
      <div className="target-config">
                  <div className="label-input-container">*/
          <label className="label-container">Portname</label>
          <div className="input-container">
            <input
              type="text"
              placeholder="Type The ports"
              value={editingProjectValues.ports}
              onChange={(e) => handleInputChange(e, 'ports')}
            />
          </div>
          </div>
          <div className="label-input-container">
          <label className="label-container">Hostname</label>
          <div className="input-container">
            <input
              type="text"
              placeholder="Type The hostname"
              value={editingProjectValues.hostnames}
              onChange={(e) => handleInputChange(e, 'hostnames')}
            />
          </div>
          </div>
       
          <div className="label-input-container">
          <label className="label-container">Username</label>
          <div className="input-container">
            <input
              type="text"
              placeholder="Type The Username"
              value={editingProjectValues.usernames}
              onChange={(e) => handleInputChange(e, 'usernames')}
            />
          </div>
      </div>
      <div className="label-input-container">
          <label className="label-container">primary</label>
          <div className="input-container">
            <input
              type="text"
              placeholder="Type The primary_key"
              value={editingProjectValues.primary_key}
              onChange={(e) => handleInputChange(e, 'primary_key')}
            />
          </div>
      </div>
        <div className="label-input-container">
          <label className="label-container">Database</label>
          <div className="input-container">
          <input
            type="text"
            placeholder="Type your Database"
            value={editingProjectValues.databasenamet}
            onChange={(e) => handleInputChange(e, 'databasenamet')}
          />
        </div>
        </div>
       </div>
    )}

{editingProjectValues.sourcesystem === 'MySQL' && (
      <div className="target-config">
     <div className="label-input-container">
     <label className="label-container">Hostname</label>
     <div className="input-container">
        <input
            type="text"
            placeholder="Type The HostName"
            value={editingProjectValues.hostnames}
            onChange={(e) => handleInputChange(e, 'hostnames')}
            />
        </div>
     </div>
    <div className="label-input-container">
    <label className="label-container">Database</label>
    <div className="input-container">
         <input
            type="text"
            placeholder="Type The Database"
            value={editingProjectValues.databasenames}
            onChange={(e) => handleInputChange(e, 'databasenames')}
                       />
               </div>
        </div>
 
     <div className="label-input-container">
    <label className="label-container">Username</label>
     <div className="input-container">
          <input
             type="text"
             placeholder="Type The Username"
             value={editingProjectValues.usernames}
             onChange={(e) => handleInputChange(e, 'usernames')}
                     />
              </div>
        </div>
 
 <div className="label-input-container">
 <label className="label-container">Password</label>
 <div className="input-container">
            <input
               type="password"
               placeholder="Type The Password"
               value={editingProjectValues.passwords}
               onChange={(e) => handleInputChange(e, 'passwords')}
                     />
             </div>
         </div>
     </div>
    )}


{editingProjectValues.sourcesystem === 'Azure MySQL' && (
 <div className="target-config">
     <div className="label-input-container">
     <label className="label-container">Hostname</label>
     <div className="input-container">
        <input
            type="text"
            placeholder="Type The HostName"
            value={editingProjectValues.hostnames}
            onChange={(e) => handleInputChange(e, 'hostnames')}
            />
        </div>
     </div>
    <div className="label-input-container">
    <label className="label-container">Database</label>
    <div className="input-container">
         <input
            type="text"
            placeholder="Type The Database"
            value={editingProjectValues.databasenames}
            onChange={(e) => handleInputChange(e, 'databasenames')}
                       />
               </div>
        </div>
 
     <div className="label-input-container">
    <label className="label-container">Username</label>
     <div className="input-container">
          <input
             type="text"
             placeholder="Type The Username"
             value={editingProjectValues.usernames}
             onChange={(e) => handleInputChange(e, 'usernames')}
                     />
              </div>
        </div>
 
 <div className="label-input-container">
 <label className="label-container">Password</label>
 <div className="input-container">
            <input
               type="password"
               placeholder="Type The Password"
               value={editingProjectValues.passwords}
               onChange={(e) => handleInputChange(e, 'passwords')}
                     />
             </div>
             <div className="target-config">
                  <div className="label-input-container">
          <label className="label-container">Portname</label>
          <div className="input-container">
            <input
              type="text"
              placeholder="Type The ports"
              value={editingProjectValues.ports}
              onChange={(e) => handleInputChange(e, 'ports')}
            />
          </div>
          </div>
         </div>
         </div>
     </div>
     )}

{editingProjectValues.sourcesystem === 'PostgreSQL' && (
 <div className="target-config">
 <div className="label-input-container">
 <label className="label-container">Hostname</label>
 <div className="input-container">
    <input
        type="text"
        placeholder="Type The HostName"
        value={editingProjectValues.hostnames}
        onChange={(e) => handleInputChange(e, 'hostnames')}
        />
    </div>
 </div>
<div className="label-input-container">
<label className="label-container">Database</label>
<div className="input-container">
     <input
        type="text"
        placeholder="Type The Database"
        value={editingProjectValues.databasenames}
        onChange={(e) => handleInputChange(e, 'databasenames')}
                   />
           </div>
    </div>

 <div className="label-input-container">
<label className="label-container">Username</label>
 <div className="input-container">
      <input
         type="text"
         placeholder="Type The Username"
         value={editingProjectValues.usernames}
         onChange={(e) => handleInputChange(e, 'usernames')}
                 />
          </div>
    </div>

    <div className="label-input-container">
<label className="label-container">Portname</label>
 <div className="input-container">
      <input
         type="text"
         placeholder="Type The Portname"
         value={editingProjectValues.ports}
         onChange={(e) => handleInputChange(e, 'ports')}
                 />
          </div>
    </div>

<div className="label-input-container">
<label className="label-container">Password</label>
<div className="input-container">
        <input
           type="password"
           placeholder="Type The Password"
           value={editingProjectValues.passwords}
           onChange={(e) => handleInputChange(e, 'passwords')}
                 />
         </div>
     </div>
 </div>
)}

{editingProjectValues.sourcesystem === 'Azure PostgreSQL' && (
<div className="target-config">
      <div className="label-input-container">
      <label className="label-container">Hostname</label>
      <div className="input-container">
         <input
             type="text"
             placeholder="Type The HostName"
             value={editingProjectValues.hostnames}
             onChange={(e) => handleInputChange(e, 'hostnames')}
             />
         </div>
      </div>
     <div className="label-input-container">
     <label className="label-container">Database</label>
     <div className="input-container">
          <input
             type="text"
             placeholder="Type The Database"
             value={editingProjectValues.databasenames}
             onChange={(e) => handleInputChange(e, 'databasenames')}
                        />
                </div>
         </div>
  
      <div className="label-input-container">
     <label className="label-container">Username</label>
      <div className="input-container">
           <input
              type="text"
              placeholder="Type The Username"
              value={editingProjectValues.usernames}
              onChange={(e) => handleInputChange(e, 'usernames')}
                      />
               </div>
         </div>
         <div className="label-input-container">
<label className="label-container">Portname</label>
 <div className="input-container">
      <input
         type="text"
         placeholder="Type The Portname"
         value={editingProjectValues.ports}
         onChange={(e) => handleInputChange(e, 'ports')}
                 />
          </div>
    </div>
  
  <div className="label-input-container">
  <label className="label-container">Password</label>
  <div className="input-container">
             <input
                type="password"
                placeholder="Type The Password"
                value={editingProjectValues.passwords}
                onChange={(e) => handleInputChange(e, 'passwords')}
                      />
              </div>
          </div>
      </div>
  )}

{editingProjectValues.sourcesystem === 'Azure CosmosDB' && (
      <div className="target-config">
                  <div className="label-input-container">
          <label className="label-container">Portname</label>
          <div className="input-container">
            <input
              type="text"
              placeholder="Type The ports"
              value={editingProjectValues.ports}
              onChange={(e) => handleInputChange(e, 'ports')}
            />
          </div>
          </div>
          <div className="label-input-container">
          <label className="label-container">Hostname</label>
          <div className="input-container">
            <input
              type="text"
              placeholder="Type The hostname"
              value={editingProjectValues.hostnames}
              onChange={(e) => handleInputChange(e, 'hostnames')}
            />
          </div>
          </div>
          <div className="label-input-container">
          <label className="label-container">primary</label>
          <div className="input-container">
            <input
              type="text"
              placeholder="Type The primary_key"
              value={editingProjectValues.primary_key}
              onChange={(e) => handleInputChange(e, 'primary_key')}
            />
          </div>
      </div>
          <div className="label-input-container">
          <label className="label-container">Username</label>
          <div className="input-container">
            <input
              type="text"
              placeholder="Type The Username"
              value={editingProjectValues.usernames}
              onChange={(e) => handleInputChange(e, 'usernames')}
            />
          </div>
      </div>
 
          <div className="label-input-container">
          <label className="label-container">Password</label>
          <div className="input-container">
            <input
              type="password"
              placeholder="Type The Password"
              value={editingProjectValues.passwords}
              onChange={(e) => handleInputChange(e, 'passwords')}
            />
          </div>
       </div>
      </div>
    )}

{editingProjectValues.sourcesystem === 'EXCEL' && (
        <div className="target-config">
          <div className="label-input-container">
            <label className="label-container">Filepath</label>
            <div className="input-container">
              <input
                type="text"
                placeholder={selectedFile ? selectedFile.name : 'Filepath'}
                value={editingProjectValues.filepaths}
                onChange={(e) => handleInputChange(e, 'filepaths')}
              />
            </div>
          </div>
          <div className="file-upload">
            <label className="file-upload-button" htmlFor="file-input">
              Browse File
            </label>
            <input
              id="file-input"
              type="file"
              accept=".csv"
              className="file-input"
              onChange={handleFileSelect}
            />
          </div>
        </div>
    )}
    
{editingProjectValues.sourcesystem === 'Azure Blob Storage Flatfiles' && (
 <div className="target-config">
 <div className="label-input-container">
 <label className="label-container1">AccountName</label>
 <div className="input-container">
        <input
          type="text"
          placeholder="Type the AccountName"
          value={editingProjectValues.accountnames}
          onChange={(e) => handleInputChange(e, 'accountnames')}
        />
    </div>
    </div>
    <div className="sourcesystem-config">
 <div className="label-input-container">
 <label className="label-container1">AccountKey</label>
 <div className="input-container">
        <input
          type="text"
          placeholder="Type the AccountKey"
          value={editingProjectValues.accountkeys}
          onChange={(e) => handleInputChange(e, 'accountkeys')}
        />
    </div>
    </div>
    <div className="label-input-container">
          <label className="label-container1">containername</label>
          <div className="input-container">
            <input
              type="text"
              placeholder="Type The containername"
              value={editingProjectValues.containernames}
              onChange={(e) => handleInputChange(e, 'containernames')}
            />
          </div>
        </div>
    </div>
  </div>
)}

{editingProjectValues.sourcesystem === 'Azure Data Lake Table Storage' && (
     <div className="target-config">
     <div className="label-input-container">
     <label className="label-container1">AccountName</label>
     <div className="input-container">
            <input
              type="text"
              placeholder="Type the AccountName"
              value={editingProjectValues.accountnames}
              onChange={(e) => handleInputChange(e, 'accountnames')}
            />
        </div>
        </div>
        <div className="sourcesystem-config">
     <div className="label-input-container">
     <label className="label-container1">AccountKey</label>
     <div className="input-container">
            <input
              type="text"
              placeholder="Type the AccountKey"
              value={editingProjectValues.accountkeys}
              onChange={(e) => handleInputChange(e, 'accountkeys')}
            />
        </div>
        </div>
        <div className="label-input-container">
          <label className="label-container1">ContainerName</label>
          <div className="input-container">
            <input
              type="text"
              placeholder="Type The containername"
              value={editingProjectValues.containernames}
              onChange={(e) => handleInputChange(e, 'containernames')}
            />
          </div>
        </div>
        </div>
      </div>
    )}


{editingProjectValues.sourcesystem === 'AzureBlobStorage Flatfiles' && (
      <div className="target-config">
      <div className="label-input-container">
      <label className="label-container1">AccountName</label>
      <div className="input-container">
             <input
               type="text"
               placeholder="Type the AccountName"
               value={editingProjectValues.accountnames}
               onChange={(e) => handleInputChange(e, 'accountnames')}
             />
         </div>
         </div>
         <div className="sourcesystem-config">
      <div className="label-input-container">
      <label className="label-container1">AccountKey</label>
      <div className="input-container">
             <input
               type="text"
               placeholder="Type the AccountKey"
               value={editingProjectValues.accountkeys}
               onChange={(e) => handleInputChange(e, 'accountkeys')}
             />
         </div>
         </div>
         <div className="label-input-container">
          <label className="label-container1">containername</label>
          <div className="input-container">
            <input
              type="text"
              placeholder="Type The containername"
              value={editingProjectValues.containernames}
              onChange={(e) => handleInputChange(e, 'containernames')}
            />
          </div>
        </div>
         </div>
         </div>
    )}

{editingProjectValues.sourcesystem === 'Azure Blob Storage Excel' && (
      <div className="target-config">
      <div className="label-input-container">
      <label className="label-container1">AccountName</label>
      <div className="input-container">
             <input
               type="text"
               placeholder="Type The AccountName"
               value={editingProjectValues.accountnames}
               onChange={(e) => handleInputChange(e, 'accountnames')}
             />
         </div>
         </div>
         <div className="sourcesystem-config">
      <div className="label-input-container">
      <label className="label-container1">AccountKey</label>
      <div className="input-container">
             <input
               type="text"
               placeholder="Type The AccountKey"
               value={editingProjectValues.accountkeys}
               onChange={(e) => handleInputChange(e, 'accountkeys')}
             />
         </div>
         </div>
         <div className="label-input-container">
          <label className="label-container1">containername</label>
          <div className="input-container">
            <input
              type="text"
              placeholder="Type The containername"
              value={editingProjectValues.containernames}
              onChange={(e) => handleInputChange(e, 'containernames')}
            />
          </div>
        </div>
         </div>
       </div>
    )}


{editingProjectValues.sourcesystem === 'Text/Csv' && (
        <div className="target-config">
          <div className="label-input-container">
            <label className="label-container">Filepath</label>
            <div className="input-container">
              <input
                type="text"
                placeholder={selectedFile ? selectedFile.name : 'Filepath'}
                value={editingProjectValues.filepaths}
                onChange={(e) => handleInputChange(e, 'filepaths')}
              />
            </div>
          </div>
        </div>
      )}
      
                <button
                    className="button1 button5 next-button"
                    onClick={handleNext}
                  >
                    Save &#8594;
                  </button>
                  <button
                    className="button2 button6 next-button1"
                    onClick={handlePrevious}
                  >
                   Previous
                  </button>
            </div>
          )}
  
          {step === 4 && (
            <div className="container1234">
            <div className="label-input-container">
              <label className="label-container2">Select Target</label>
              <div className="input-container">
                <div className="select-container">
                  <select
                    className="selectvalue"
                    name="targetsystem"
                    value={editingProjectValues.targetsystem || ''}
                    onChange={(e) => {
                      handleInputChange(e, 'targetsystem');
                      if (e.target.value === 'MS SQL Server') {
                        setStep(3); // Automatically shift to the next step
                      }
                    }}
                  >
                    <option value="">Select an option</option>
                    <option value="MS SQL Server">SQL Server</option>
                    <option value="Azure SQL Server">Azure SQL Server</option>
                    <option value="Azure Blob Storage Flatfiles">
                      Azure Blob Storage Flatfiles
                    </option>
                    <option value="Azure Data Lake Table Storage">
                      Azure Data Lake Table Storage
                    </option>
                    <option value="Azure Blob Storage Excel">
                      Azure Blob Storage Excel
                    </option>
                    <option value="Azure MySQL">Azure MySQL</option>
                    <option value="Azure PostgreSQL">Azure PostgreSQL</option>
                    <option value="Azure CosmosDB">Azure CosmosDB</option>
                    <option value="EXCEL">EXCEL</option>
                    <option value="Text/Csv">Text/Csv</option>
                    <option value="PostgreSQL">PostgreSQL</option>
                    <option value="MySQL">MySQL</option>
                    <option value="MongoDB">MongoDB</option>
                  </select>
                </div>
              </div>
            </div>
            <button
              className="button1 button5 next-button"
              onClick={handleNext}
            >
              Save &#8594;
            </button>
            <button
              className="button2 button6 next-button1"
              onClick={handlePrevious}
            >
             Previous
            </button>
          </div>
          )}

    {step === 5 && (
                   <div>
                   {editingProjectValues.sourcesystem === 'MS SQL Server' && (
                     <div className="sql-server-fields">
                       <div className="label-input-container">
                         <label className="label-container4">Server</label>
                         <div className="input-container">
                           <input
                             type="text"
                             placeholder="Type The Server"
                             value={editingProjectValues.servert}
                             onChange={(e) =>
                               handleInputChange(e, 'servernames')
                             }
                           />
                         </div>
                       </div>
                       <div className="label-input-container">
                         <label className="label-container">Database</label>
                         <div className="input-container">
                           <input
                             type="text"
                             placeholder="Type The Database"
                             value={editingProjectValues.databaset}
                             onChange={(e) =>
                               handleInputChange(e, 'databasenames')
                             }
                           />
                         </div>
                       </div>
     
                       <div className="label-input-container">
                         <label className="label-container">Username</label>
                         <div className="input-container">
                           <input
                             type="text"
                             placeholder="Type The Username"
                             value={editingProjectValues.uidt}
                             onChange={(e) => handleInputChange(e, 'usernames')}
                           />
                         </div>
                       </div>
     
                       <div className="label-input-container">
                         <label className="label-container">Password</label>
                         <div className="input-container">
                           <input
                             type="password"
                             placeholder="Type The Password"
                             value={editingProjectValues.pwdt}
                             onChange={(e) =>
                               handleInputChange(e, 'passwords')
                             }
                           />
                         </div>
                       </div>
                     
                     </div>
                   )}
     
                     {editingProjectValues.sourcesystem === 'Azure SQL Server' && (
                     <div className="sql-server-fields">
                       <div className="label-input-container">
                         <label className="label-container4">Server</label>
                         <div className="input-container">
                           <input
                             type="text"
                             placeholder="Type The Server"
                             value={editingProjectValues.servernames}
                             onChange={(e) =>
                               handleInputChange(e, 'servernames')
                             }
                           />
                         </div>
                       </div>
     
                       <div className="label-input-container">
                         <label className="label-container">Database</label>
                         <div className="input-container">
                           <input
                             type="text"
                             placeholder="Type The Database"
                             value={editingProjectValues.databasenames}
                             onChange={(e) =>
                               handleInputChange(e, 'databasenames')
                             }
                           />
                         </div>
                       </div>
     
                       <div className="label-input-container">
                         <label className="label-container">Username</label>
                         <div className="input-container">
                           <input
                             type="text"
                             placeholder="Type The Username"
                             value={editingProjectValues.usernames}
                             onChange={(e) => handleInputChange(e, 'usernames')}
                           />
                         </div>
                       </div>
     
                       <div className="label-input-container">
                         <label className="label-container">Password</label>
                         <div className="input-container">
                           <input
                             type="password"
                             placeholder="Type The Password"
                             value={editingProjectValues.passwords}
                             onChange={(e) =>
                               handleInputChange(e, 'passwords')
                             }
                           />
                         </div>
                       </div>
                     
                     </div>
                   )}
                   
     {editingProjectValues.sourcesystem === 'MongoDB' && (
           <div className="target-config">
                       <div className="label-input-container">*/
               <label className="label-container">Portname</label>
               <div className="input-container">
                 <input
                   type="text"
                   placeholder="Type The ports"
                   value={editingProjectValues.ports}
                   onChange={(e) => handleInputChange(e, 'ports')}
                 />
               </div>
               </div>
               <div className="label-input-container">
               <label className="label-container">Hostname</label>
               <div className="input-container">
                 <input
                   type="text"
                   placeholder="Type The hostname"
                   value={editingProjectValues.hostnames}
                   onChange={(e) => handleInputChange(e, 'hostnames')}
                 />
               </div>
               </div>
            
               <div className="label-input-container">
               <label className="label-container">Username</label>
               <div className="input-container">
                 <input
                   type="text"
                   placeholder="Type The Username"
                   value={editingProjectValues.usernames}
                   onChange={(e) => handleInputChange(e, 'usernames')}
                 />
               </div>
           </div>
           <div className="label-input-container">
               <label className="label-container">primary</label>
               <div className="input-container">
                 <input
                   type="text"
                   placeholder="Type The primary_key"
                   value={editingProjectValues.primary_key}
                   onChange={(e) => handleInputChange(e, 'primary_key')}
                 />
               </div>
           </div>
             <div className="label-input-container">
               <label className="label-container">Database</label>
               <div className="input-container">
               <input
                 type="text"
                 placeholder="Type your Database"
                 value={editingProjectValues.databasenamet}
                 onChange={(e) => handleInputChange(e, 'databasenamet')}
               />
             </div>
             </div>
            </div>
         )}
     
     {editingProjectValues.sourcesystem === 'MySQL' && (
           <div className="target-config">
          <div className="label-input-container">
          <label className="label-container">Hostname</label>
          <div className="input-container">
             <input
                 type="text"
                 placeholder="Type The HostName"
                 value={editingProjectValues.hostnames}
                 onChange={(e) => handleInputChange(e, 'hostnames')}
                 />
             </div>
          </div>
         <div className="label-input-container">
         <label className="label-container">Database</label>
         <div className="input-container">
              <input
                 type="text"
                 placeholder="Type The Database"
                 value={editingProjectValues.databasenames}
                 onChange={(e) => handleInputChange(e, 'databasenames')}
                            />
                    </div>
             </div>
      
          <div className="label-input-container">
         <label className="label-container">Username</label>
          <div className="input-container">
               <input
                  type="text"
                  placeholder="Type The Username"
                  value={editingProjectValues.usernames}
                  onChange={(e) => handleInputChange(e, 'usernames')}
                          />
                   </div>
             </div>
      
      <div className="label-input-container">
      <label className="label-container">Password</label>
      <div className="input-container">
                 <input
                    type="password"
                    placeholder="Type The Password"
                    value={editingProjectValues.passwords}
                    onChange={(e) => handleInputChange(e, 'passwords')}
                          />
                  </div>
              </div>
          </div>
         )}
     
     
     {editingProjectValues.sourcesystem === 'Azure MySQL' && (
      <div className="target-config">
          <div className="label-input-container">
          <label className="label-container">Hostname</label>
          <div className="input-container">
             <input
                 type="text"
                 placeholder="Type The HostName"
                 value={editingProjectValues.hostnames}
                 onChange={(e) => handleInputChange(e, 'hostnames')}
                 />
             </div>
          </div>
         <div className="label-input-container">
         <label className="label-container">Database</label>
         <div className="input-container">
              <input
                 type="text"
                 placeholder="Type The Database"
                 value={editingProjectValues.databasenames}
                 onChange={(e) => handleInputChange(e, 'databasenames')}
                            />
                    </div>
             </div>
      
          <div className="label-input-container">
         <label className="label-container">Username</label>
          <div className="input-container">
               <input
                  type="text"
                  placeholder="Type The Username"
                  value={editingProjectValues.usernames}
                  onChange={(e) => handleInputChange(e, 'usernames')}
                          />
                   </div>
             </div>
      
      <div className="label-input-container">
      <label className="label-container">Password</label>
      <div className="input-container">
                 <input
                    type="password"
                    placeholder="Type The Password"
                    value={editingProjectValues.passwords}
                    onChange={(e) => handleInputChange(e, 'passwords')}
                          />
                  </div>
                  <div className="target-config">
                       <div className="label-input-container">
               <label className="label-container">Portname</label>
               <div className="input-container">
                 <input
                   type="text"
                   placeholder="Type The ports"
                   value={editingProjectValues.ports}
                   onChange={(e) => handleInputChange(e, 'ports')}
                 />
               </div>
               </div>
              </div>
              </div>
          </div>
          )}
     
     {editingProjectValues.sourcesystem === 'PostgreSQL' && (
      <div className="target-config">
      <div className="label-input-container">
      <label className="label-container">Hostname</label>
      <div className="input-container">
         <input
             type="text"
             placeholder="Type The HostName"
             value={editingProjectValues.hostnames}
             onChange={(e) => handleInputChange(e, 'hostnames')}
             />
         </div>
      </div>
     <div className="label-input-container">
     <label className="label-container">Database</label>
     <div className="input-container">
          <input
             type="text"
             placeholder="Type The Database"
             value={editingProjectValues.databasenames}
             onChange={(e) => handleInputChange(e, 'databasenames')}
                        />
                </div>
         </div>
     
      <div className="label-input-container">
     <label className="label-container">Username</label>
      <div className="input-container">
           <input
              type="text"
              placeholder="Type The Username"
              value={editingProjectValues.usernames}
              onChange={(e) => handleInputChange(e, 'usernames')}
                      />
               </div>
         </div>
     
         <div className="label-input-container">
     <label className="label-container">Portname</label>
      <div className="input-container">
           <input
              type="text"
              placeholder="Type The Portname"
              value={editingProjectValues.ports}
              onChange={(e) => handleInputChange(e, 'ports')}
                      />
               </div>
         </div>
     
     <div className="label-input-container">
     <label className="label-container">Password</label>
     <div className="input-container">
             <input
                type="password"
                placeholder="Type The Password"
                value={editingProjectValues.passwords}
                onChange={(e) => handleInputChange(e, 'passwords')}
                      />
              </div>
          </div>
      </div>
     )}
     
     {editingProjectValues.sourcesystem === 'Azure PostgreSQL' && (
     <div className="target-config">
           <div className="label-input-container">
           <label className="label-container">Hostname</label>
           <div className="input-container">
              <input
                  type="text"
                  placeholder="Type The HostName"
                  value={editingProjectValues.hostnames}
                  onChange={(e) => handleInputChange(e, 'hostnames')}
                  />
              </div>
           </div>
          <div className="label-input-container">
          <label className="label-container">Database</label>
          <div className="input-container">
               <input
                  type="text"
                  placeholder="Type The Database"
                  value={editingProjectValues.databasenames}
                  onChange={(e) => handleInputChange(e, 'databasenames')}
                             />
                     </div>
              </div>
       
           <div className="label-input-container">
          <label className="label-container">Username</label>
           <div className="input-container">
                <input
                   type="text"
                   placeholder="Type The Username"
                   value={editingProjectValues.usernames}
                   onChange={(e) => handleInputChange(e, 'usernames')}
                           />
                    </div>
              </div>
              <div className="label-input-container">
     <label className="label-container">Portname</label>
      <div className="input-container">
           <input
              type="text"
              placeholder="Type The Portname"
              value={editingProjectValues.ports}
              onChange={(e) => handleInputChange(e, 'ports')}
                      />
               </div>
         </div>
       
       <div className="label-input-container">
       <label className="label-container">Password</label>
       <div className="input-container">
                  <input
                     type="password"
                     placeholder="Type The Password"
                     value={editingProjectValues.passwords}
                     onChange={(e) => handleInputChange(e, 'passwords')}
                           />
                   </div>
               </div>
           </div>
       )}
     
     {editingProjectValues.sourcesystem === 'Azure CosmosDB' && (
           <div className="target-config">
                       <div className="label-input-container">
               <label className="label-container">Portname</label>
               <div className="input-container">
                 <input
                   type="text"
                   placeholder="Type The ports"
                   value={editingProjectValues.ports}
                   onChange={(e) => handleInputChange(e, 'ports')}
                 />
               </div>
               </div>
               <div className="label-input-container">
               <label className="label-container">Hostname</label>
               <div className="input-container">
                 <input
                   type="text"
                   placeholder="Type The hostname"
                   value={editingProjectValues.hostnames}
                   onChange={(e) => handleInputChange(e, 'hostnames')}
                 />
               </div>
               </div>
               <div className="label-input-container">
               <label className="label-container">primary</label>
               <div className="input-container">
                 <input
                   type="text"
                   placeholder="Type The primary_key"
                   value={editingProjectValues.primary_key}
                   onChange={(e) => handleInputChange(e, 'primary_key')}
                 />
               </div>
           </div>
               <div className="label-input-container">
               <label className="label-container">Username</label>
               <div className="input-container">
                 <input
                   type="text"
                   placeholder="Type The Username"
                   value={editingProjectValues.usernames}
                   onChange={(e) => handleInputChange(e, 'usernames')}
                 />
               </div>
           </div>
      
               <div className="label-input-container">
               <label className="label-container">Password</label>
               <div className="input-container">
                 <input
                   type="password"
                   placeholder="Type The Password"
                   value={editingProjectValues.passwords}
                   onChange={(e) => handleInputChange(e, 'passwords')}
                 />
               </div>
            </div>
           </div>
         )}
     
     {editingProjectValues.sourcesystem === 'EXCEL' && (
             <div className="target-config">
               <div className="label-input-container">
                 <label className="label-container">Filepath</label>
                 <div className="input-container">
                   <input
                     type="text"
                     placeholder={selectedFile ? selectedFile.name : 'Filepath'}
                     value={editingProjectValues.filepaths}
                     onChange={(e) => handleInputChange(e, 'filepaths')}
                   />
                 </div>
               </div>
               <div className="file-upload">
                 <label className="file-upload-button" htmlFor="file-input">
                   Browse File
                 </label>
                 <input
                   id="file-input"
                   type="file"
                   accept=".csv"
                   className="file-input"
                   onChange={handleFileSelect}
                 />
               </div>
             </div>
         )}
         
     {editingProjectValues.sourcesystem === 'Azure Blob Storage Flatfiles' && (
      <div className="target-config">
      <div className="label-input-container">
      <label className="label-container1">AccountName</label>
      <div className="input-container">
             <input
               type="text"
               placeholder="Type the AccountName"
               value={editingProjectValues.accountnames}
               onChange={(e) => handleInputChange(e, 'accountnames')}
             />
         </div>
         </div>
         <div className="sourcesystem-config">
      <div className="label-input-container">
      <label className="label-container1">AccountKey</label>
      <div className="input-container">
             <input
               type="text"
               placeholder="Type the AccountKey"
               value={editingProjectValues.accountkeys}
               onChange={(e) => handleInputChange(e, 'accountkeys')}
             />
         </div>
         </div>
         <div className="label-input-container">
               <label className="label-container1">containername</label>
               <div className="input-container">
                 <input
                   type="text"
                   placeholder="Type The containername"
                   value={editingProjectValues.containernames}
                   onChange={(e) => handleInputChange(e, 'containernames')}
                 />
               </div>
             </div>
         </div>
       </div>
     )}
     
     {editingProjectValues.sourcesystem === 'Azure Data Lake Table Storage' && (
          <div className="target-config">
          <div className="label-input-container">
          <label className="label-container1">AccountName</label>
          <div className="input-container">
                 <input
                   type="text"
                   placeholder="Type the AccountName"
                   value={editingProjectValues.accountnames}
                   onChange={(e) => handleInputChange(e, 'accountnames')}
                 />
             </div>
             </div>
             <div className="sourcesystem-config">
          <div className="label-input-container">
          <label className="label-container1">AccountKey</label>
          <div className="input-container">
                 <input
                   type="text"
                   placeholder="Type the AccountKey"
                   value={editingProjectValues.accountkeys}
                   onChange={(e) => handleInputChange(e, 'accountkeys')}
                 />
             </div>
             </div>
             <div className="label-input-container">
               <label className="label-container1">ContainerName</label>
               <div className="input-container">
                 <input
                   type="text"
                   placeholder="Type The containername"
                   value={editingProjectValues.containernames}
                   onChange={(e) => handleInputChange(e, 'containernames')}
                 />
               </div>
             </div>
             </div>
           </div>
         )}
     
     
     {editingProjectValues.sourcesystem === 'AzureBlobStorage Flatfiles' && (
           <div className="target-config">
           <div className="label-input-container">
           <label className="label-container1">AccountName</label>
           <div className="input-container">
                  <input
                    type="text"
                    placeholder="Type the AccountName"
                    value={editingProjectValues.accountnames}
                    onChange={(e) => handleInputChange(e, 'accountnames')}
                  />
              </div>
              </div>
              <div className="sourcesystem-config">
           <div className="label-input-container">
           <label className="label-container1">AccountKey</label>
           <div className="input-container">
                  <input
                    type="text"
                    placeholder="Type the AccountKey"
                    value={editingProjectValues.accountkeys}
                    onChange={(e) => handleInputChange(e, 'accountkeys')}
                  />
              </div>
              </div>
              <div className="label-input-container">
               <label className="label-container1">containername</label>
               <div className="input-container">
                 <input
                   type="text"
                   placeholder="Type The containername"
                   value={editingProjectValues.containernames}
                   onChange={(e) => handleInputChange(e, 'containernames')}
                 />
               </div>
             </div>
              </div>
              </div>
         )}
     
     {editingProjectValues.sourcesystem === 'Azure Blob Storage Excel' && (
           <div className="target-config">
           <div className="label-input-container">
           <label className="label-container1">AccountName</label>
           <div className="input-container">
                  <input
                    type="text"
                    placeholder="Type The AccountName"
                    value={editingProjectValues.accountnames}
                    onChange={(e) => handleInputChange(e, 'accountnames')}
                  />
              </div>
              </div>
              <div className="sourcesystem-config">
           <div className="label-input-container">
           <label className="label-container1">AccountKey</label>
           <div className="input-container">
                  <input
                    type="text"
                    placeholder="Type The AccountKey"
                    value={editingProjectValues.accountkeys}
                    onChange={(e) => handleInputChange(e, 'accountkeys')}
                  />
              </div>
              </div>
              <div className="label-input-container">
               <label className="label-container1">containername</label>
               <div className="input-container">
                 <input
                   type="text"
                   placeholder="Type The containername"
                   value={editingProjectValues.containernames}
                   onChange={(e) => handleInputChange(e, 'containernames')}
                 />
               </div>
             </div>
              </div>
            </div>
         )}
     
     
     {editingProjectValues.sourcesystem === 'Text/Csv' && (
             <div className="target-config">
               <div className="label-input-container">
                 <label className="label-container">Filepath</label>
                 <div className="input-container">
                   <input
                     type="text"
                     placeholder={selectedFile ? selectedFile.name : 'Filepath'}
                     value={editingProjectValues.filepaths}
                     onChange={(e) => handleInputChange(e, 'filepaths')}
                   />
                 </div>
               </div>
             </div>
           )}
           
                     <button
                         className="button1 button5 next-button"
                         onClick={handleNext}
                       >
                         Save &#8594;
                       </button>
                       <button
                         className="button2 button6 next-button1"
                         onClick={handlePrevious}
                       >
                        Previous
                       </button>
                 </div>
      )}

{step === 6 && (
   <div className='mapping'>
   <div className="table-box-container">
     <div className="table-container">
       <table className="dual-table">
         <thead>
           <tr>
             <th className="table-header">Source System</th>
           </tr>
         </thead>
         <tbody>
           {data && Array.isArray(data) && data.map((dbobjects, index) => (
             <tr key={index}>
               <td>
                 <div className="checkbox-label">
                   <input
                     type="checkbox"
                     value={dbobjects.sourceobjects}
                     checked={selectedObjects.includes(dbobjects.sourceobjects)}
                     onChange={() => handleCheckboxChange(dbobjects.sourceobjects)}
                   />
                   <span className="checkbox-label-text">{dbobjects.sourceobjects}</span>
                 </div>
               </td>
             </tr>
           ))}
         </tbody>
       </table>
     </div>

     {/* Button Container */}
     <div className="new-button-container">
       <button className="new-button-sel" onClick={handleUncheckAndMoveToRight}>
         <AiOutlineCaretLeft />
       </button>
       <button className="new-button-uns-forward" onClick={handleSelectAllDbObjects}>
         <AiOutlineStepForward />
       </button>
       <button className="new-button-uns" onClick={handleMoveToRight}>
         <AiOutlineCaretRight />
       </button>
       <button className="new-button-uns-forward" onClick={handleDeselectAll}>
         <ImPrevious2 />
       </button>
     </div>

     {/* Target System Table */}
     <div className="table-container">
       <table className="dual-table">
         {/* Table header */}
         <thead className="fixed-header">
           <tr>
             <th className="table-header">Target System</th>
           </tr>
         </thead>
         <tbody>
           {data && Array.isArray(data) && data.map((dbobjects, index) => (
             <tr key={index}>
               <td>
                 {selectedObjects.includes(dbobjects.sourceobjects) && (
                   <div className="checkbox-label">
                     <span className="checkbox-label-text">{dbobjects.sourceobjects}</span>
                   </div>
                 )}
               </td>
             </tr>
           ))}
         </tbody>
       </table>
     </div>
   </div>

   {/* Save and Run,Previous buttons */}
   <button className="button1 button5 next-button" onClick={handleNext}>Save &#8594;</button>
   <button className="button2 button6 next-button1" onClick={handlePrevious}>Previous</button>
 </div>
)}
        </div>
      </div>
    </div>
  );
}

export default EditProjectDialog;