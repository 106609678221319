import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeClock } from '@mui/x-date-pickers/TimeClock';

import '../style/OneTimeOccurenceClock.css'

const AnalogClock = ({ hours, setHours, minutes, setMinutes, period, onTimeChange, darkMode, purpleMode, redMode, blueMode, yellowMode }) => {

    const [value, setValue] = useState(dayjs().set('hour', hours).set('minute', minutes));

    useEffect(() => {
        setValue(dayjs().set('hour', hours).set('minute', minutes));
    }, [hours, minutes, period]);

    const handleTimeChange = (newValue) => {
        setValue(newValue);
        const newHours = newValue.format('hh');
        const newMinutes = newValue.format('mm');
        setHours(newHours);
        setMinutes(newMinutes);
        const formattedTime = newValue.format('hh:mm');
        console.log('value in AnalogClock: ', formattedTime);
        // Pass the formatted time value to the parent component
        onTimeChange(formattedTime);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className={`digital-analog-clock-container ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>
                <TimeClock 
                    value={value} 
                    onChange={handleTimeChange} 
                    ampm={true}
                />
            </div>

        </LocalizationProvider>
    );
}

export default AnalogClock;
