import React, { useState, useEffect, useCallback } from 'react';
import BreadcrumbItem from './Breadcrumbs';
import { CiSearch } from "react-icons/ci";
import { FaChevronDown } from "react-icons/fa";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import '../style/Project2.css';
import { Toaster } from 'react-hot-toast';
import SearchFilterSort from './SearchFilterSort';

const ProjectConfigDetails = () => {
    const [data, setData] = useState([]);
    const loggedInUserEmail = JSON.parse(localStorage.getItem('user'))?.email;
    const { projectname } = useParams();
    const location = useLocation();
    const [filteredProjects, setFilteredProjects] = useState([]);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/v1/project/project-config?email=${loggedInUserEmail}&project_id=${projectname}`);
                const projectconfig = await response.json();
                setData(projectconfig);
                console.log('Data from endpoint: ', data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchProjects();
    }, [location, loggedInUserEmail, projectname] );

    const handleFilter = useCallback((filteredData) => {
        setFilteredProjects(filteredData);
    }, []);

    return (
        <div className='flex-grow'>
            <div className='sqlprojects-layout'>
                <nav aria-label="breadcrumb-trails">
                    <ul className="breadcrumb-unit">
                        <BreadcrumbItem to="/" label="Home" isActive={false} />
                        <BreadcrumbItem to="/all-projects" label="All Projects" isActive={false} />
                        <BreadcrumbItem to={`/project-config-details/${projectname}`} label="Project Configuration" isActive={true} />
                    </ul>
                </nav>
                <div className='header-sql-exec-projects'>
                    <div>
                        <h4 className="page-heading">Project Configuration Details</h4>
                        <span className="sub-title">Change subtitile to something more suitable</span> 
                    </div>
                    {/* <button className='add-sql-exec-projects' onClick={handleAddProject}>
                        + Add Project
                    </button> */}
                </div>
                <div className="project-details-layout">
                    <SearchFilterSort
                        data={data}
                        onFilter={handleFilter}
                        filterOptions={{'All':'All', "Active":'Active', "Inactive": 'In-Active'}}
                    />
                    <div className='projects-listing'>
                        <div className='project-table-header'>
                            <div className='project-column-header'>Project Name</div>
                            <div className='project-column-header'>Source</div>
                            <div className='project-column-header'>Source Configuration</div>
                            <div className='project-column-header'>Target</div>
                            <div className='project-column-header'>Target Configuration</div>
                            <div className='project-column-header'>Last Update</div>
                        </div>
                        <div className="scrollable-box-sql-project-details">
                            {filteredProjects.length > 0 ? (
                                filteredProjects.map(project => (
                                    <div key={project.projectid} className='project-row'>  
                                        <div className='project-column'>{project.projectname}</div>
                                        <div className='project-column'>{project.sourcesystem}</div>
                                        <div className="project-column" style={{ overflowX: "auto" }}>{project.sourceconfiguration}</div>
                                        <div className='project-column'>{project.targetsystem}</div>
                                        <div className="project-column" style={{ overflowX: "auto" }}>{project.targetconfiguration}</div>
                                        <div className='project-column'>{new Date(project.lastupdatedate).toLocaleString()}</div>
                                    </div>
                                ))
                            ) : (
                                <div className='no-projects'>No projects available</div>
                            )}
                        </div>                        
                    </div>
                </div>
            </div>
            <Toaster />
        </div>
    );
}

export default ProjectConfigDetails;