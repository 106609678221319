import React, { useState, useEffect } from 'react';
import '../style/DigitalClock.css';
import dayjs from 'dayjs';

const DigitalClock = ({ hours, setHours, minutes, setMinutes, period, setPeriod, selectedTime, onTimeChange, darkMode, purpleMode, redMode, blueMode, yellowMode, disabled }) => {
    // const [hours, setHours] = useState(dayjs(selectedTime).format('hh'));
    // const [minutes, setMinutes] = useState(dayjs(selectedTime).format('mm'));
    // const [period, setPeriod] = useState(dayjs(selectedTime).format('AM'));

    useEffect(() => {
        setHours(dayjs(selectedTime).format('hh'));
        setMinutes(dayjs(selectedTime).format('mm'));
        setPeriod(dayjs(selectedTime).format('A'));
    }, [selectedTime]);

    const handleHoursChange = (event) => {
        const value = event.target.value;
        if (/^[0-9]*$/.test(value) && (value === '' || (value >= 1 && value <= 12))) {
            setHours(value);
            updateSelectedTime(value, minutes, period);
        }
    };
    
    const handleMinutesChange = (event) => {
        const value = event.target.value;
        if (/^[0-9]*$/.test(value) && (value === '' || (value >= 0 && value <= 59))) {
            setMinutes(value);
            updateSelectedTime(hours, value, period);
        }
    };

    const handlePeriodChange = (selectedPeriod) => {
        setPeriod(selectedPeriod);
        updateSelectedTime(hours, minutes, selectedPeriod);
    };

    const updateSelectedTime = (hours, minutes, period) => {
        // Convert 12-hour format to 24-hour format for proper time representation
        let hour = parseInt(hours, 10);
        if (period === 'PM' && hour !== 12) {
            hour += 12;
        } else if (period === 'AM' && hour === 12) {
            hour = 0;
        }

        const timeValue = `${hour}:${minutes}`;

        // Pass the time value to the callback function
        onTimeChange(timeValue);
    };

    return (
        <div className={`digital-clock-container ${disabled ? 'disabled-clock' : ''}`}>
            <div className='digital-clock-content'>
                <div className='HHMM-container'>
                    {/* <div className='HH-input'> */}
                        <input
                            type="number"
                            placeholder="HH"
                            value={hours}
                            onChange={handleHoursChange}
                            min="1"
                            max="12"
                            className="HH-input"
                        />
                    {/* </div> */}
                    {/* <div className='MM-input'> */}
                        <input
                            type="number"
                            placeholder="MM"
                            value={minutes}
                            onChange={handleMinutesChange}
                            min="0"
                            max="59"
                            className="MM-input"
                        />
                    {/* </div> */}
                </div>
                <div className={`AMPM-container ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>
                        <button
                            className={`AM-input ${period === 'AM' ? 'active-period' : ''} ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}
                            onClick={() => handlePeriodChange('AM')}
                        >
                            AM
                        </button>
                        <button
                            className={`PM-input ${period === 'PM' ? 'active-period' : ''} ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}
                            onClick={() => handlePeriodChange('PM')}
                        >
                            PM
                        </button>
                </div>
            </div>
        </div>
    );
}

export default DigitalClock;
