import "react-datepicker/dist/react-datepicker.css";
import '../style/date-picker.css';
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { Icons } from "./icons.jsx";
import { format } from "date-fns";

const DatePickerComponent = ({ details, setDetails, selectedDate, onChange, header, keyName, onyDate }) => {
  console.log('Picker cp', keyName, details)
  const [startDate, setStartDate] = useState(
    selectedDate instanceof Date && !isNaN(selectedDate)
      ? selectedDate
      : new Date()
  );
  const [date, setDate] = useState(false);
  const [originalDate, setOriginalDate] = useState(startDate);
  const loggedInUserEmail = JSON.parse(localStorage.getItem('user'))?.email;
  const origDetails = details;
  
  useEffect(() => {
    setOriginalDate(startDate);
  }, [selectedDate]);

  const handleDateChange = (details, key, date) => {
    console.log('dp line 24', date, details, key)
    setStartDate(date);
    setDetails({
      ...details,
      [key]: date,
    });
  };

  const handleCancel = () => {
    setDate(false);
    setStartDate(originalDate); 
  };

  const schedulersave = () => {
    console.log('scheduler save details:', details, loggedInUserEmail)
    const detailsToSend = {
      useremail:loggedInUserEmail,
      startdate: details.startdate,
      enddate: details.enddate,
    };

    console.log('Datepicker Details to send:', detailsToSend);
    fetch(process.env.REACT_APP_API_URL+'scheduler', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(detailsToSend),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((responseData) => {
        if (responseData.status === 'success') {
          console.log('Details saved successfully!');
        } else {
          console.error('Error saving details:', responseData.message);
        }
      })
      .catch((error) => {
        console.error('Fetch error:', error);
      });
  };

  const handleSave = () => {
    setDate(false);
    if (onChange) {
      onChange(startDate);
    }
  };

  return (
    <div className="date-picker">
      {onyDate && <div className="head" style={{ marginBottom: 10 }}>
        Select date
      </div>}
      {onyDate && <div
        className="head"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>{header}</div>
        <div>{Icons?.calendar}</div>
      </div>}
      {onyDate && <div style={{ borderBottom: "1px solid gray", margin: "10px 0px" }}></div>}
      <div
        style={{
          border: "1px solid #fff",
          color: "#fff",
          padding: 10,
          marginBottom: 10,
          position: "relative",
        }}
        onClick={() => setDate(true)}
      >
        {" "}
        {format(startDate, "dd-MM-yyyy")}
        <div className="dates">Date</div>
      </div>
      {date && <DatePicker
        showIcon
        selected={startDate}
        onChange={event => handleDateChange(details, keyName, event)}
        dateFormat="yyyy-MM-dd"
        showMonthDropdown
        showYearDropdown
        inline
      />}
      <div className="d-flex justify-content-end">
        <button className="head" onClick={handleCancel}>Clear</button>
        {/*<button className="head" onClick={schedulersave}>Save</button>*/}
      </div>
    </div>
  );
};

export default DatePickerComponent;