import React from 'react';
import { Link } from 'react-router-dom';
import '../style/Breadcrumbs.css'

const BreadcrumbItem = ({ to, label, isActive, darkMode }) => (
  <li className={`breadcrumb ${isActive ? 'active' : ''}`}>
    <Link
      to={to}
      className={`${isActive ? 'active-link' : 'inactive-link'} ${darkMode ? 'dark-mode' : ''}`}
      aria-current={isActive ? 'page' : undefined}
    >
      {label}
    </Link>
  </li>
);

export default BreadcrumbItem;
