import React, { useState, useEffect, useCallback } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import '../style/Settings.css';
import 'react-toastify/dist/ReactToastify.css';
import UserProfile from './UserProfile';

const Settings = ({ darkMode, setDarkMode, purpleMode, setPurpleMode, redMode, setRedMode, blueMode, setBlueMode, yellowMode, setYellowMode }) => {
    const [emailNotifications, setEmailNotifications] = useState(false);
    const [settingType, setSettingType] = useState('profile');

    const screenCaps = [
      { path: '/assets/images/ThemeSample1.png',
        name:'Default Mode', 
        mode: 'defaultMode'
      },       
      { path: '/assets/images/ThemeSample2Dark.png',
        name:'Dark Mode', 
        mode: 'darkMode'
      },
      { path: '/assets/images/ThemeSample3Purple.png',
        name:'Purple Mode',
        mode: 'purpleMode' 
      },
      { path: '/assets/images/ThemeSample4Red.png',
        name:'Red Mode',
        mode: 'redMode' 
      },       
      { path: '/assets/images/ThemeSample5Blue.png',
        name:'Blue Mode',
        mode: 'blueMode' 
      },
      { path: '/assets/images/ThemeSample6Yellow.png',
        name:'Yellow Mode', 
        mode: 'yellowMode'
      },
    ]

    const handleThemeClick = (mode) => {
      switch (mode) {
        case 'defaultMode':
            setDarkMode(false);
            setPurpleMode(false);
            setRedMode(false);
            setBlueMode(false);
            setYellowMode(false);
            console.log('Default mode true');
            break;
        case 'darkMode':
            setPurpleMode(false);
            setRedMode(false);
            setBlueMode(false);
            setYellowMode(false);
            setDarkMode(true);
            console.log('Dark mode true');
            break;
        case 'purpleMode':
            setDarkMode(false);
            setRedMode(false);
            setBlueMode(false);
            setYellowMode(false);
            setPurpleMode(true);
            console.log('Purple mode true', mode);
            break;
        case 'redMode':
            setDarkMode(false);
            setPurpleMode(false);
            setBlueMode(false);
            setYellowMode(false);
            setRedMode(true);
            console.log('Red mode true', mode);
            break;
        case 'blueMode':
            setDarkMode(false);
            setPurpleMode(false);
            setRedMode(false);
            setYellowMode(false);
            setBlueMode(true);
            console.log('Blue mode true', mode);
            break;
        case 'yellowMode':
            setDarkMode(false);
            setPurpleMode(false);
            setRedMode(false);
            setBlueMode(false);
            setYellowMode(true);
            console.log('Yellow mode true', mode);
            break;
        default:
      }
    };

    const toggleDarkMode = () => {
        setDarkMode((prevMode) => !prevMode);
      };
    
      useEffect(() => {
        const root = document.documentElement;
        root.classList.toggle('dark-mode', darkMode);
      }, [darkMode]);

    const settingComponents = {
      profile: <UserProfile />,
      themes: (
          <div className={`settings-area ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>
              {screenCaps.map((cap, index) => (
                  <div key={index} className='theme-image-container'>
                      <img className='theme-image' src={cap.path} alt={cap.name} onClick={() => handleThemeClick(cap.mode)} />
                  </div>
              ))}
          </div>
      ),
      // Add other setting types here
  };
 

    const handleEmailNotificationsChange = useCallback(() => {
        setEmailNotifications((prevStatus) => !prevStatus);
    }, []);

    // Simulate submitting the settings
    const handleSubmit = useCallback((e) => {
        e.preventDefault(); // Prevent actual form submission

        // Here you'd typically send this data to your server
        console.log({ darkMode, emailNotifications });

        // Notify the user of the update
        toast.success('Settings updated successfully!');
    }, [darkMode, emailNotifications]);

    return (
          <div>
            <div className={`settings-container ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>
              <div className={`settings-selection ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>
                <h1>Settings</h1>
                <h2>
                    Themes
                </h2>
                <div className="settings-options">
                  <button className="user-profile" onClick={() => setSettingType("profile")}>
                    <span>Profile</span>
                  </button>
                  <button className="theme-toggle" onClick={() => setSettingType("themes")}>
                    <span>Theme</span>
                  </button>
                  <button className="language-toggle">
                    <span>Language</span>
                  </button>
                  <button className="reset-history">
                    <span>Reset History</span>
                  </button>
                  <button className="recycle-bin">
                    <span>Recycle Bin</span>
                  </button>
                </div>
              <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
              </div>
              {settingComponents[settingType]}
            </div>
          </div>
    );
}

export default Settings;
