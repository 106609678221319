import '../style/hour.css';
import React, { useState } from 'react';

const HourCounter = ({ details, setDetails, keyName }) => {
  console.log('Hour cp', details)
  const [Minutes, setMinutes] = useState(0);

  const incrementHour = (details, keyName, event) => {
    if (Minutes < 59) {
      setMinutes(Minutes + 1);
      setDetails({
        ...details,
        [keyName+'_val']: Minutes + 1,
      });
    }
  };

  const decrementHour = (details, keyName, event) => {
    if (Minutes > 0) {
      setMinutes(Minutes - 1);
      setDetails({
        ...details,
        [keyName+'_val']: Minutes - 1,
      });
    }
  };

  const handleCancel = () => {
    setMinutes(0);
  };

 

  return (
    <div className='hour-incremental'>
      <button className='hour-increment-btn' onClick={event => incrementHour(details, keyName, event)}>+</button>
      <div className="hour-inc">{Minutes}</div>
      <button className='hour-increment-btn' onClick={event => decrementHour(details, keyName, event)}>-</button>
      
      <div className="d-flex justify-content-end" style={{ marginTop: 10 }}>
        <button className="head-btn" onClick={handleCancel}>Clear</button>
      </div>
    </div>
  );
}

export default HourCounter;