import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import DigitalClock from './DigitalClock';
import AnalogClock from './AnalogClock';
import '../style/OneTimeOccurenceClock.css'

const OneTimeOccurenceClock = ({ handleInputChange, disabled, darkMode, purpleMode, redMode, blueMode, yellowMode, clockType, jobDetails }) => {   

    const currentTimeFormatted = dayjs().format('hh:mm:A');

    const [selectedTime, setSelectedTime] = useState(currentTimeFormatted);
    const [hours, setHours] = useState(dayjs(selectedTime).format('hh'));
    const [minutes, setMinutes] = useState(dayjs(selectedTime).format('mm'));
    const [period, setPeriod] = useState(dayjs(selectedTime).format('AM'));

    useEffect(() => {
        console.log('Time passed on is ', jobDetails.time_OneTime);

        if (jobDetails && jobDetails.time_OneTime) {
            const parsedTime = dayjs(jobDetails.time_OneTime, 'HH:mm').format('hh:mm:A');
            const parsedHours = dayjs(jobDetails.time_OneTime, 'HH:mm').format('hh');
            const parsedMinutes = dayjs(jobDetails.time_OneTime, 'HH:mm').format('mm');
            const parsedPeriod = dayjs(jobDetails.time_OneTime, 'HH:mm').format('A');
            setSelectedTime(parsedTime);
            setHours(parsedHours);
            setMinutes(parsedMinutes);
            setPeriod(parsedPeriod);
        }
        console.log('selectedTime after if statement is: ', selectedTime);
    }, [jobDetails]);

    useEffect(() => {
        console.log('Hours is: ', hours );
    }, [hours]);
    
    return(
        <div className={`digital-analog-clock-container ${disabled ? 'disabled-clock' : ''} ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>
                <span className='top-left-text'>Select time</span>
                <div className='digital-1'>
                    <DigitalClock 
                        hours={hours} 
                        setHours={setHours} 
                        minutes={minutes} 
                        setMinutes={setMinutes} 
                        period={period} 
                        setPeriod={setPeriod} 
                        onTimeChange={(time) => handleInputChange('time_OneTime', time)} 
                        darkMode={darkMode}
                        purpleMode={purpleMode} 
                        redMode={redMode} 
                        blueMode={blueMode} 
                        yellowMode={yellowMode}
                    />
                </div>
                <div className="analog-1">
                    <AnalogClock 
                        hours={hours} 
                        setHours={setHours} 
                        minutes={minutes} 
                        setMinutes={setMinutes} 
                        period={period} 
                        onTimeChange={(time) => handleInputChange('time_OneTime', time)} 
                        darkMode={darkMode}
                        purpleMode={purpleMode} 
                        redMode={redMode} 
                        blueMode={blueMode} 
                        yellowMode={yellowMode}
                    />
                </div>
            </div>
    );
}

export default OneTimeOccurenceClock;