import { AiOutlineClose,AiOutlineCaretLeft,AiOutlineCaretRight,AiOutlineStepForward} from 'react-icons/ai'; 
const EditScheduleDialog = ({isOpen, onClose}) => {
    return(
<div className={`dialog-container ${isOpen ? 'open' : ''}`}>
    <div className="dialog-content flex flex-col">
      <div className="header-section flex justify-between items-center px-4 bg-gray-200">
          <div className="flex items-center">
            <h1 className="dialog-heading text-xl md:text-2xl lg:text-3xl xl:text-4xl font-bold mr-4">NEW PROJECT</h1>
            <button className="close-button bg-gray-500 text-white px-4 py-1 rounded hover:bg-gray-700" onClick={onClose}>
              <AiOutlineClose />
            </button>
          </div>
        </div>

        <div className="progress-section p-4 bg-gray-300 overflow-hidden">

        </div>
        <div>
            {/* <button className="buttonMain buttonColorSave" onClick={handleNext}>Save &#8594;</button>
            <button className="buttonMain buttonColorSaveAsDraft" onClick={handlePrevious}>Save as draft </button> */}
            <button className="buttonMain buttonColorSave">Save &#8594;</button>
            <button className="buttonMain buttonColorSaveAsDraft">Save as draft </button>
        </div>

    </div>
    </div>
        )
}

export default EditScheduleDialog;