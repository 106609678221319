import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/Home.css';

const NewPage = () => {
  return (
    <div className='flex'>
      <div className="flex-grow">
        <iframe
          title="Dash Dashboard"
          src={process.env.REACT_APP_CHATBOT_URL}
          className="dashboard-iframe"
        ></iframe>
      </div>
    </div>
  );
}

export default NewPage;
