import React, { useState, useEffect, useCallback } from "react";
import '../../style/SQLExecProjects.css';
import BreadcrumbItem from "../Breadcrumbs";
import { Link, useNavigate } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import SearchFilterSort from "../SearchFilterSort";

const JobDetails = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const loggedInUserEmail = JSON.parse(localStorage.getItem('user'))?.email;
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [jobNameValidationData, setJobNameValidationData] = useState([]);

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_FASTAPI_URL}/v1/scheduler/scheduler/email/${loggedInUserEmail}`
                );
                const jobs = await response.json();
                if (Array.isArray(jobs)) {
                    setData(jobs);
                    const jobNames = jobs.map((job) => job.JobName);
                    setJobNameValidationData(jobNames);
                } else {
                    setData([]);  
                }
                console.log('Data from endpoint: ', jobs);
            } catch (error) {
                console.error('Error fetching data:', error);
                setData([]);  
            }
        };
        fetchJobs();
    }, [loggedInUserEmail]);  

    const handleAddJob = () => {
        navigate('/Schedule', { state: { jobNameValidationData } });
    };

    const handleFilter = useCallback((filteredData) => {
        setFilteredJobs(filteredData);
    }, []); 

    return (
        <div className='flex-grow'>
            <div className='sqlprojects-layout'>
                <nav aria-label="breadcrumb-trails">
                    <ul className="breadcrumb-unit">
                        <BreadcrumbItem to="/" label="Home" isActive={false} />
                        <BreadcrumbItem to="/sqlexecprojects" label="Scheduled Jobs" isActive={true} />
                    </ul>
                </nav>
                <div className='header-sql-exec-projects'>
                    <div>
                        <h4 className="page-heading">Scheduled Jobs</h4>
                        <span className="sub-title">Schedule, Edit, and Manage your Jobs</span> 
                    </div>
                    <button className='add-sql-exec-projects' onClick={handleAddJob}>
                        + Schedule Job
                    </button>
                </div>
                <div className="project-details-layout">
                    <SearchFilterSort 
                        data={data}
                        onFilter={handleFilter}
                        filterOptions={{'All':'All', "Active":'Active', "Inactive": 'In-Active'}}
                    />
                    <div className='projects-listing'>
                        <div className='project-table-header'>
                            <div className='project-column-header'>Job Name</div>
                            <div className='project-column-header'>Schedule Type</div>
                            <div className='project-column-header'>Last Update</div>
                            <div className='project-column-header'>Frequency</div>
                            <div className='project-column-header'>Description</div>
                            <div className='project-column-header'>Actions</div>
                        </div>
                        <div className="scrollable-box-sql-project-details">
                            {filteredJobs.length > 0 ? (
                                filteredJobs?.map(job => (
                                    <div key={job.projectid} className='project-row'>
                                        <Link 
                                            to={`/project-status`} 
                                            state={{ jobData: job }} 
                                            className="link-styling" 
                                            style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}
                                        >
                                            {job.JobName}
                                        </Link>
                                        <div className='project-column'>{job.ScheduleType}</div>
                                        <div className='project-column'>{new Date(job.UpdatedAt).toLocaleString()}</div>
                                        <div className='project-column'>{job.Frequency}</div>
                                        <div className='project-column'>{job.Description}</div>
                                        <div> Edit </div>
                                    </div>
                                ))
                            ) : (
                                <div className='no-projects'>No jobs available</div>
                            )}
                        </div>                        
                    </div>
                </div>
            </div>
            <Toaster />
        </div>
    );
};

export default JobDetails;
