import React, { useState } from "react";
import BreadcrumbItem from "../Components/Breadcrumbs.js";
import RunHistoryDMProjectLevel from "./RunHistoryDetailsComponents/RunHistoryDMProjectLevel";
import RunHistorySEProjectLevel from "./RunHistoryDetailsComponents/RunHistorySEProjectLevel";


const RunHistoryLayout = () => {
    const [activeTab, setActiveTab] = useState('dmProjects');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className='flex-grow'>
            <div className="project-status-layout">
                <nav aria-label="breadcrumb-trails">
                    <ul className="breadcrumb-unit">
                        <BreadcrumbItem to="/" label="Home" isActive={false} />
                        <BreadcrumbItem to={`/run-history-project-status`} label="Run History Project Status" isActive={true} />

                    </ul>
                </nav>

                <div className="tabs-layout">
                    <div className="tabs-header">
                        <div
                            className={`tab-item ${activeTab === 'dmProjects' ? 'active' : ''}`}
                            onClick={() => handleTabClick('dmProjects')}
                        >
                            DM Run History Status
                        </div>
                        <div
                            className={`tab-item ${activeTab === 'seProjects' ? 'active' : ''}`}
                            onClick={() => handleTabClick('seProjects')}
                        >
                            SE Run History Status
                        </div>
                    </div>
                    <div>
                        {activeTab === 'dmProjects' && (
                            <RunHistoryDMProjectLevel />                       
                        )}
                        {activeTab === 'seProjects' && (
                            <RunHistorySEProjectLevel />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RunHistoryLayout;
