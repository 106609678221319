import Select from 'react-select';

const SelectSource = ({ connectors, setDisableSaveAndRun, handleSourceSelect, filteredConnectors,setButtonsDisabled, setSourceValidate, setTargetValidate }) => {
  console.log('Source Conn:', connectors, filteredConnectors)
    return (
        <div className="container1234">
          {setButtonsDisabled(false)}
          {setSourceValidate(false)}
          {setTargetValidate(false)}
          {setDisableSaveAndRun(false)}
        <div className="label-input-container">
          <label className="progress-labels">Select Source</label>
          <div className="input-container">
            <Select
            className='selectvalue'
            options={connectors.map((connector, index) => ({
              value: connector.value,
              label: connector.name,
            }))}
            onChange={(selectedOption) => handleSourceSelect(selectedOption.value)}
            />
          </div>
        </div>
      </div>
    )
}

export default SelectSource;