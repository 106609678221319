// import '../../style/ProgressButtons.css';

const ProgressButtons = ({step}) => {
    return(
      <div className="progress-buttons-container">
        <div className="button-container12 flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
        <div className="step flex items-center">
          <button className={`button3 button7 ${step === 1 ? 'button-active' : 'button-inactive'}`}>
            1
          </button>
          <p className="step-label sm:ml-2 my-1">Project Name</p>
        </div>
        <div className="step">
          <button className={`button3 button7 ${step === 2 ? 'button-active' : 'button-inactive'}`}>
            2
          </button>
          <p className="step-label sm:ml-2 my-1">Source System</p>
        </div>
        <div className="step">
          <button className={`button3 button7 ${step === 3 ? 'button-active' : 'button-inactive'}`}>
            3
          </button>
          <p className="step-label sm:ml-2 my-1">Select Source</p>
        </div>
        <div className="step">
          <button className={`button3 button7 ${step === 4 ? 'button-active' : 'button-inactive'}`}>
            4
          </button>
          <p className="step-label sm:ml-2 my-1">Target System</p>
        </div>
        <div className="step">
          <button className={`button3 button7 ${step === 5 ? 'button-active' : 'button-inactive'}`}>
            5
          </button>
          <p className="step-label sm:ml-2 my-1">Select Target</p>
        </div>
        <div className="step">
          <button className={`button3 button7 ${step === 6 ? 'button-active' : 'button-inactive'}`}>
          6
          </button>
          <p className="step-label sm:ml-2 my-1">Mapping</p>
        </div>
      </div>
    </div>
    )
}

export default ProgressButtons;