import { useState } from "react";
import '../../style/Mapping.css'
import Incremental from "./Incremental";

const Mapping = ({data, selectedObjects, handleCheckboxChange, 
                handleUncheckAndMoveToRight, AiOutlineCaretLeft, 
                handleSelectAllDbObjects, AiOutlineStepForward, 
                handleMoveToRight, AiOutlineCaretRight, handleDeselectAll,
                ImPrevious2, handleExecutionClick, mappingDialogOpen, 
                MappingDialog, setMappingDialogOpen, setSourceValidate, setTargetValidate,
                setDisableSaveAndRun, setIsAtLeastOneCheckboxSelected,selectedLoadTypeOption, setSelectedObjects}) => {


    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    return(
        <div className='mapping'>
          {setSourceValidate(false)}
          {setTargetValidate(false)}
          {setDisableSaveAndRun(true)}
          {console.log('LoadType: ' + selectedLoadTypeOption.value)}
          {console.log('data in mapping1: '+ data)}
          {selectedLoadTypeOption.value === 'direct'? 
          <div className="table-box-container">
            <div className="table-container">
              <table className="dual-table">
                <thead className="fixed-header">
                  <tr>
                    <th className="table-header">Source System</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.data.map((table, index) => (                    
                    <tr key={index}>
                      <td>
                        <div className="checkbox-label">
                          <input
                            type="checkbox"
                            value={Object.keys(table)[0]}
                            checked={Object.keys(table)[0] in selectedObjects}
                            onChange={() => {
                              const sourceObject=Object.keys(table)[0];
                              handleCheckboxChange(sourceObject);
                              const isChecked = Object.keys(table)[0] in selectedObjects;
                              setIsAtLeastOneCheckboxSelected(!isChecked || selectedCheckboxes.length > 1);
                            }}
                          />
                          {console.log('data in mapping2: '+ data)}
                          {/* {console.log('dbobjects in mapping: '+ dbobjects)} */}
                          {console.log('dbobjects.sourceobjects in mapping: '+ Object.keys(table)[0])}
                          <span className="checkbox-label-text">{Object.keys(table)[0]}</span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Button Container */}
            <div className="new-button-container">
              <button className="new-button-sel" onClick={handleUncheckAndMoveToRight}>
                <AiOutlineCaretLeft />
              </button>
              <button className="new-button-uns-forward" onClick={handleSelectAllDbObjects}>
                <AiOutlineStepForward />
              </button>
              <button className="new-button-uns" onClick={handleMoveToRight}>
                <AiOutlineCaretRight />
              </button>
              <button className="new-button-uns-forward" onClick={handleDeselectAll}>
                <ImPrevious2 />
              </button>
            </div>

            {/* Target System Table */}
            <div className="table-container">
              <table className="dual-table">
                {/* Table header */}
                <thead className="fixed-header">
                  <tr>
                    <th className="table-header">Target System</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.data.map((table, index) => (
                    <tr key={index}>
                      <td>
                      {Object.keys(selectedObjects).includes(Object.keys(table)[0]) && (
                          <div className="checkbox-label">
                            <span className="checkbox-label-text">{Object.keys(table)[0]}</span>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          :
          <div className="incremental-mapping"> 
          {setIsAtLeastOneCheckboxSelected(true)}
            <Incremental 
              data={data} 
              setSelectedObjects = {setSelectedObjects}
              selectedObjects={selectedObjects}
            /> 
          </div>
          }
      </div>
    )
}

export default Mapping;