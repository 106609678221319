import React, { useState } from 'react';
import '../../style/Signup.css';
import Login from './Login';
import Signup from './Signup';

const SignUpLoginParent = () => {
    const [isSignup, setIsSignup] = useState(false);
    return (
        <div className='signup-main-container'>
            <div className='signup-card'>
                <div className='signup-card-left'>
                    <div className='signup-image-overlay'>
                        <h2>Welcome to 4Vue!</h2>
                        <p>A 4Seer product.</p>
                    </div>
                </div>
                {!isSignup ? <Login setIsSignup={setIsSignup}/> : <Signup setIsSignup={setIsSignup}/> }
            </div>
        </div>
    );
}

export default SignUpLoginParent;