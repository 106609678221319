import React, { useReducer, useState, useEffect } from 'react';
import BreadcrumbItem from '../Breadcrumbs';
import { Toaster } from 'react-hot-toast';
import '../../style/EditorLayout.css';
import SQLEditor from './SQLEditor';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai'; 
import { IoMdRefresh } from "react-icons/io";
import { BsDatabaseFillAdd } from 'react-icons/bs';
import { DiDatabase } from "react-icons/di";
import { FiX } from 'react-icons/fi'; 
import ValidateCredentials from './ValidateCredentials';
import DatabaseContent from './DatabaseContent';
import ConnectionDetailsFooter from './ConnectionDetailsFooter';

// Reducer for managing tabs
const tabReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TAB':
      return [...state, action.payload];
    case 'REMOVE_TAB':
      return state.filter((tab) => tab.id !== action.payload);
    case 'UPDATE_TAB': 
      state = state.filter((tab) => tab.id !== action.payload.id);
      return [...state, action.payload];
    default:
      return state;
  }
};

const EditorLayout = () => {
  const [tabs, dispatch] = useReducer(tabReducer, []);
  const [activeTab, setActiveTab] = useState(null);
  const [connectionEstablished, setConnectionEstablished] = useState(false); 
  const [credentials, setCredentials] = useState(null); 
  const [databases, setDatabases] = useState([]);
  const [isServerExpanded, setIsServerExpanded] = useState(true); 
  const [error, setError] = useState("");
  const [dbMetadata, setDbMetadata] = useState(null);
  const [activeDatabase, setActiveDatabase] = useState('');
  const [connectionStartTime, setConnectionStartTime] = useState(null);

  const addNewTab = (label, content) => {
    const newTabId = tabs.length + 1;
    const newTab = { id: newTabId, label, content };
    dispatch({ type: 'ADD_TAB', payload: newTab });
    setActiveTab(newTabId);
  };

  const closeTab = (tabId) => {
    dispatch({ type: 'REMOVE_TAB', payload: tabId });
    if (activeTab === tabId) {
      setActiveTab(tabs.length > 1 ? tabs[0].id : null);
    }
  };

  const handleNewConnection = () => {
    addNewTab(
      'Create Connection',
      <ValidateCredentials onConnect={(creds) => handleConnect(creds, tabs.length + 1)} />
    );
  };

  const handleConnect = async (creds, tabId) => {
    const query = new URLSearchParams(creds).toString();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_FASTAPI_URL}/v1/editor/validate-db-connection?${query}`,
        { method: 'GET', headers: { 'Content-Type': 'application/json' } }
      );

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.detail || 'Failed to connect to the database.');
      }

      const result = await response.json();

      if (result.status === 'success') {
        setCredentials(creds);
        setDatabases(result.data); 
        setConnectionEstablished(true);
        setConnectionStartTime(new Date())
        setError("");

        localStorage.setItem('connectionToken', result.token);

        closeTab(tabId);
        addNewTab('New Query', <SQLEditor activeDatabase={activeDatabase} />);
      } else {
        throw new Error(result.detail || 'Unexpected response from the server.');
      }
    } catch (err) {
      setError(err.message || 'Connection error');
      console.error('Error during connection validation:', err);
    }
  };

  
  const handleDatabaseClick = (database) => {
    // setActiveDatabase(database); // Set state as usual
  
    // // Wait until the state updates, then fetch metadata
    // setActiveDatabase((prevDatabase) => {
    //   if (prevDatabase === database) {
    //     fetchMetadata(database); // Call metadata fetch only after state update
    //   }

    //   console.log('activeDatabase after click: ', activeDatabase);
    //   return database; // Return the new state
    // });

    if(database !== activeDatabase) {
      setActiveDatabase(database);
      // console.log('activeDB after handleDBClick: ', activeDatabase);
      fetchMetadata(database)
      }
  };
  
  // Fetch metadata function
  const fetchMetadata = async (database) => {
    const token = localStorage.getItem('connectionToken');
    
    try {
      const query = new URLSearchParams({ database }).toString();
      const response = await fetch(
        `${process.env.REACT_APP_FASTAPI_URL}/v1/editor/get-db-metadata?${query}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.detail || 'Failed to retrieve database metadata.');
      }
  
      const metadata = await response.json();
      setDbMetadata(metadata.data);
      setError(""); // Clears any previous errors
    } catch (err) {
      setError(err.message || 'Error fetching database metadata');
      console.error('Error during metadata fetch:', err);
    }
  };

  const handleRefresh = async () => {
    const token = localStorage.getItem('connectionToken'); 
  
    try {
      const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/v1/editor/refresh-editor`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, 
        },
      });
  
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.detail || 'Failed to refresh editor.');
      }
  
      const result = await response.json();
      setDatabases(result.data); 
      setActiveDatabase('');
      setError(''); 
  
      console.log('Databases refreshed successfully:', result.data);
    } catch (err) {
      setError(err.message || 'Error refreshing editor.');
      console.error('Error during refresh:', err);
    }
  };
  

  useEffect(()=>{
    if(activeDatabase){
      const newTab = { id: activeTab, label: 'New Query', content:<SQLEditor activeDatabase={activeDatabase} /> };
      dispatch({ type: 'UPDATE_TAB', payload: newTab });
    }
  }, [activeDatabase]);
  
  

  return (
    <div className='flex-grow'>
      <Toaster />
      <div className='sqlprojects-layout'>
        <nav aria-label='breadcrumb-trails'>
          <ul className='breadcrumb-unit'>
            <BreadcrumbItem to='/' label='Home' isActive={false} />
            <BreadcrumbItem to='/editor' label='Editor' isActive={true} />
          </ul>
        </nav>

        <div className='editor-layout'>
          <div className='editor-working-area'>
            <div className='editor-column-1'>
              <div className='connections-area'>
                <div className='connections-header'>
                  <div className='editor-layout-titles'>Connections</div>
                  <div className='editor-control-buttons'>
                    <button className='add-connection-button' onClick={handleRefresh}>
                      <IoMdRefresh />
                    </button>
                    <button className='add-connection-button' onClick={() => addNewTab('New Query', <SQLEditor activeDatabase={activeDatabase} />)}>
                      <AiOutlinePlus />
                    </button>
                  </div>

                </div>

                <div className='connections-body'>
                  {!connectionEstablished ? (
                    <div className='add-connection-area' onClick={handleNewConnection}>
                      <div className='add-connection-button-2'>
                        <BsDatabaseFillAdd />
                      </div>
                      Add new connection
                    </div>
                  ) : (
                    <div className='server-details'>
                      <div className='server-header' onClick={() => setIsServerExpanded((prev) => !prev)}>
                        {isServerExpanded ? <AiOutlineMinus /> : <AiOutlinePlus />}
                        <span>{credentials.host}</span>                        
                      </div>

                      {isServerExpanded && (
                        <div className='database-list'>
                          {databases.map((db, index) => (
                            <div key={index} className='database-item' onClick={() => handleDatabaseClick(db)}>
                                <DiDatabase />
                                {db}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className='database-content'>
                  <div className='connections-header'>
                    {!dbMetadata ? <div className='editor-layout-titles'>Database Content</div> : <div className='editor-layout-titles'>Tables, Views and Procedures</div>}
                  </div>
                    {dbMetadata ? (
                      <DatabaseContent dbMetadata={dbMetadata}/>
                    ) : (
                      <p className='empty-interface'>Select a database to view its content.</p>
                    )}
              </div>
            </div>

            <div className='editor-column-2'>
              {tabs.length > 0 ? (
                <div className='tabs-header'>
                  {tabs.map((tab) => (
                    <div key={tab.id} className={`tab-button ${activeTab === tab.id ? 'active' : ''}`}>
                      <span onClick={() => setActiveTab(tab.id)}>{tab.label}</span>
                      <FiX className='close-icon' onClick={() => closeTab(tab.id)} />
                    </div>
                  ))}
                </div>
              ) : (
                <div className='empty-interface'>Please add a connection to start.</div>
              )}

              <div className='editor-interface'>
                {tabs.find((tab) => tab.id === activeTab)?.content}
              </div>

              {/* {error && <div className='error-message'>{error}</div>} */}
            </div>
          </div>

          <div className='editor-footer-area'>
            {/* Connection details etc */}
            <ConnectionDetailsFooter 
              activeDatabase={activeDatabase}
              host={credentials?.host || 'N/A'}
              user={credentials?.user || 'N/A'}
              connectionStatus={connectionEstablished}
              connectionStartTime={connectionStartTime}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditorLayout;
