import Select from 'react-select';
const SelectTarget = ({connectors, setDisableSaveAndRun, handleTargetSelect, setSourceValidate, setButtonsDisabled, setTargetValidate}) => {
    return (
            <div className="label-input-container">
                {setButtonsDisabled(false)}
                {setSourceValidate(false)}
                {setTargetValidate(false)}
                {setDisableSaveAndRun(false)}
                <label className="progress-labels">Select Target</label>
                <div className="input-container">
                    <Select
                    className='selectvalue'
                    options={connectors.map((connector, index) => ({
                        value: connector.value,
                        label: connector.name,
                    }))}
                    onChange={(selectedOption) => handleTargetSelect(selectedOption.value)}
                    />
                </div>
        </div>

    )
}

export default SelectTarget;