import React, { useState, useEffect } from "react";
import BreadcrumbItem from "./Breadcrumbs";
import '../style/SQLExecAddProject.css';
import { useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';

const AddProject = () => {
    const [currentPage, setCurrentPage] = useState(1); 
    const [formData, setFormData] = useState({load_type: 'direct'});  
    const [data, setData] = useState({ source: [], target: [] });
    const location = useLocation();
    const projectNameValidationData = location.state?.projectNameValidationData;   

    const handleNext = () => {
        if (currentPage < 3) {
            
        }
        if (currentPage === 1) {
            const projectname = formData.projectname || '';
            if (projectname.trim() === '') {
              toast.error('Please enter the project name.');
              return;
            }
            const isProjectNameValid = () => {
              return projectNameValidationData.includes(formData.projectname);
            };
            if (isProjectNameValid()) {
              toast.error('Project name already exists. Please choose a different name.');
              return;
          }
          setCurrentPage(prev => prev + 1);
        } else if (currentPage === 2) {
            setCurrentPage(prev => prev + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(prev => prev - 1);
        }
    };

    const renderPage = () => {
        switch (currentPage) {
            case 1:
                return <ProjectDetailsEntry formData={formData} setFormData={setFormData} handleNext={handleNext} projectNameValidationData={projectNameValidationData}/>;
            case 2:
                return <ConfigureSourceSystem formData={formData} setFormData={setFormData} handlePrevious={handlePrevious} handleNext={handleNext} setData={setData} />;
            case 3:
                return <ConfigureTargetSystem formData={formData} setFormData={setFormData} handlePrevious={handlePrevious} />;
            case 4:
                return <ConfigureTargetSystem formData={formData} setFormData={setFormData} handlePrevious={handlePrevious} />;
            default:
                return null;
        }
    };

    // const renderActionButtons = () => {
    //     switch (currentPage) {
    //         case 1:
    //             return (<div><button onClick={handlePrevious}>Previous</button></div>);
    //         case 2:
    //             return <ConfigureSourceSystem formData={formData.page2} setFormData={(data) => setFormData({...formData, page2: data})} />;
    //         case 3:
    //             return <Page3 formData={formData.page3} setFormData={(data) => setFormData({...formData, page3: data})} />;
    //         default:
    //             return null;
    //     }
    // };

    const renderProgressIndicator = () => (
            <div className="progress-indicator">
                <div className={`step-sql-add ${currentPage === 1 ? 'active' : ''}`}>
                    <span className={`circle-icon ${currentPage === 1 ? 'active' : ''}`}>1</span>
                    <span className="step-text">Project Details</span>
                </div>
                <div className={`step-sql-add ${currentPage === 2 ? 'active' : ''}`}>
                    <span className={`circle-icon ${currentPage === 2 ? 'active' : ''}`}>2</span>
                    <span className="step-text">Configure Source System</span>
                </div>
                <div className={`step-sql-add ${currentPage === 3 ? 'active' : ''}`}>
                    <span className={`circle-icon ${currentPage === 3 ? 'active' : ''}`}>3</span>
                    <span className="step-text">Configure Target System</span>
                </div>
                <div className={`step-sql-add ${currentPage === 4 ? 'active' : ''}`}>
                    <span className={`circle-icon ${currentPage === 4 ? 'active' : ''}`}>4</span>
                    <span className="step-text">Mapping</span>
                </div>
            </div>
    );

    return (
        <div className='flex-grow'>
            <Toaster />
            <div className="sqlprojects-layout">
                <nav aria-label="breadcrumb-trails">
                    <ul className="breadcrumb-unit">
                        <BreadcrumbItem to="/" label="Home" isActive={false} />
                        <BreadcrumbItem to="/all-projects" label="Active Projects" isActive={false} />
                        <BreadcrumbItem to="/add-project" label="Add Projects" isActive={true} />
                    </ul>
                </nav>
                <div className="add-projects-sql-header-layout">
                    {renderProgressIndicator()}
                </div>
                <div className="add-projects-sql-card-layout">
                    {renderPage()}
                    {/* <div className="navigation-buttons">
                        {currentPage > 1 && <button onClick={handlePrevious}>Previous</button>}
                        {currentPage < 3 && <button onClick={handleNext}>Next</button>}
                        {currentPage === 3 && <button onClick={() => console.log('Submit form')}>Submit</button>}
                    </div> */}
                </div>
            </div>
        </div>
    );
}

const ProjectDetailsEntry = ({ formData, setFormData, handleNext }) => {
    const loggedInUserEmail = JSON.parse(localStorage.getItem('user'))?.email;  
    const LoadTypeOptions = [
        { value: 'direct', label: 'Direct' },
        { value: 'incremental', label: 'Incremental' },
      ];

    return (
    <div className="form-page-card-inner-layout">
        <div className="form-page-card">
            <label className='label-sql-add'>Project Name</label>
            <input
                type="text"
                value={formData.projectname || ''}
                className="input-sql-add"
                onChange={(e) => setFormData({ ...formData, useremail: loggedInUserEmail, projectname: e.target.value })}
                placeholder="Enter project name"
            />
            <label className="label-sql-add">Load Type</label>
            <select
                options={LoadTypeOptions}
                value={formData.load_type || ''}
                onChange={(e) => setFormData({ ...formData, load_type: e.target.value })}
                className="input-sql-add"
            >
                <option value="" disabled>Select Load Type</option>
                <option value="direct">Direct</option>
                <option value="incremental">Incremental</option>
            </select>
        </div>
        <div className="navigation-buttons-page-1">
            <button onClick={handleNext}>Next</button>
        </div>
    </div>
    )
};

const ConfigureSourceSystem = ({ formData, setFormData, handlePrevious, handleNext, setData }) => {
    const [selectedSource, setSelectedSource] = useState(''); 
    const [sourceConfigFormData, setSourceConfigFormData] = useState({});  
    const [validationStatus, setValidationStatus] = useState(false); 
    const [validationMessage, setValidationMessage] = useState(""); 

    const configRequirements = {
        "MS SQL Server": {
            "Server": "servernames",
            "Port": "ports",
            "Database": "databasenames",
            "Username": "usernames",
            "Password": "passwords"
        },
        "MongoDB": {
            "Hostname": "servernames",
            "Port": "ports",
            "AuthDatabase": "databasenames",
            "Username": "usernames",
            "Password": "passwords"
        },
        "MySQL": {
            "Hostname": "hostnames",
            "Port": "ports",
            "Database": "databasenames",
            "Username": "usernames",
            "Password": "passwords"
        },
        "Azure MySQL": {
            "Hostname": "hostnames",
            "Port": "ports",
            "Database": "databasenames",
            "Username": "usernames",
            "Password": "passwords"
        }
    };

    // Updates source system on change and resets form data except for the sourcesystem key
    const handleSourceChange = (e) => {
        const newSource = e.target.value;
        setSelectedSource(newSource);
        setSourceConfigFormData({ sourcesystem: newSource });
        setValidationStatus(false);  // Reset validation status
        setValidationMessage("");
    };

    // This ensures the 'sourcesystem' field stays updated whenever the source is selected or changed
    useEffect(() => {
        if (selectedSource) {
            setSourceConfigFormData((prev) => ({
                ...prev,
                sourcesystem: selectedSource
            }));
        }
    }, [selectedSource]);

    const handleValidation = async () => {
        setValidationMessage("");  
        fetch(`${process.env.REACT_APP_FASTAPI_URL}/v1/connectors/validate-connector`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(sourceConfigFormData),
            }).then(response => {
                console.log('Raw Response:', response);
                return response.json();
              }).then((responseData) => {
                if (responseData.status === 'success') {
                    console.log('Validated successfully!');
                    setValidationStatus(true);  
                    setValidationMessage(responseData.message); 
                    setSourceConfigFormData((prev) => ({
                        ...prev,
                        db_type: selectedSource
                    }));
                    fetch(process.env.REACT_APP_FASTAPI_URL+`/v1/dbobjects/read-dbobjects`, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify(sourceConfigFormData),
                        })
                          .then((response) => response.json())
                          .then((dbobjects) => {
                            setData(dbobjects);
                            console.log('dbobjects received from backend for MS SQL Server:', dbobjects);
                          })
                          .catch((error) => console.error('Error fetching data:', error));
                } else {
                    setValidationStatus(false);  
                    setValidationMessage(responseData.message); 
                }
              }
            ).catch((error) => {
                setValidationStatus(false);
                setValidationMessage("An error occurred during validation.");
            });
    };

    const renderFields = () => {
        if (!selectedSource) return null;

        return Object.entries(configRequirements[selectedSource]).map(([label, key]) => (
            <div key={key} className="form-field">
                <label className='label-sql-add'>{label}</label>
                <input
                    type={label.toLowerCase() === 'password' ? 'password' : 'text'}
                    value={sourceConfigFormData[key] || ''}
                    className="input-sql-add"
                    onChange={(e) =>
                        setSourceConfigFormData({ ...sourceConfigFormData, [key]: e.target.value })
                    }
                    placeholder={`Enter ${label.toLowerCase()}`}
                />
            </div>
        ));
    };

    return (
        <div className="form-page-card-inner-layout">
            <div className="form-page-card">
                <label className='label-sql-add'>Select Source System</label>
                <select 
                    value={selectedSource} 
                    onChange={handleSourceChange} 
                    className="input-sql-add"
                >
                    <option value="">Select a source system</option>
                    {Object.keys(configRequirements).map((source) => (
                        <option key={source} value={source}>
                            {source}
                        </option>
                    ))}
                </select>

                <div className="form-two-columns">
                    {renderFields()}
                </div>

                <div className="navigation-buttons">
                    <button onClick={handlePrevious}>Previous</button>
                    {validationStatus ? 
                        <button onClick={handleNext}>Next</button> :
                        <button onClick={handleValidation}>Validate</button>
                    }
                </div>
                {validationMessage && <p>{validationMessage}</p>}
            </div>
        </div>
    );
};

const ConfigureTargetSystem = ({ formData, setFormData, handlePrevious, handleNext }) => {
    const [selectedTarget, setSelectedTarget] = useState(''); 
    const [targetConfigFormData, setTargetConfigFormData] = useState({});  
    const [validationStatus, setValidationStatus] = useState(false); 
    const [validationMessage, setValidationMessage] = useState(""); 

    const configRequirements = {
        "MS SQL Server": {
            "Server": "servernames",
            "Port": "ports",
            "Database": "databasenames",
            "Username": "usernames",
            "Password": "passwords"
        },
        "MongoDB": {
            "Hostname": "servernames",
            "Port": "ports",
            "AuthDatabase": "databasenames",
            "Username": "usernames",
            "Password": "passwords"
        },
        "MySQL": {
            "Hostname": "hostnames",
            "Port": "ports",
            "Database": "databasenames",
            "Username": "usernames",
            "Password": "passwords"
        },
        "Azure MySQL": {
            "Hostname": "hostnames",
            "Port": "ports",
            "Database": "databasenames",
            "Username": "usernames",
            "Password": "passwords"
        }
    };

    // Updates target system on change and resets form data except for the sourcesystem key
    const handleSourceChange = (e) => {
        const newSource = e.target.value;
        setSelectedTarget(newSource);
        setTargetConfigFormData({ sourcesystem: newSource });
        setValidationStatus(false);  // Reset validation status
        setValidationMessage("");
    };

    // This ensures the 'targetsystem' field stays updated whenever the target is selected or changed
    useEffect(() => {
        if (selectedTarget) {
            setTargetConfigFormData((prev) => ({
                ...prev,
                sourcesystem: selectedTarget
            }));
        }
    }, [selectedTarget]);

    const handleValidation = async () => {
        setValidationMessage("");  

        fetch(`${process.env.REACT_APP_FASTAPI_URL}/v1/connectors/validate-connector`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(targetConfigFormData),
            }).then(response => {
                console.log('Raw Response:', response);
                return response.json();
              }).then((responseData) => {
                if (responseData.status === 'success') {
                    console.log('Validated successfully!');
                    setValidationStatus(true);  
                    setValidationMessage(responseData.message); 
                } else {
                    setValidationStatus(false);  
                    setValidationMessage(responseData.message); 
                }
              }
            ).catch((error) => {
                setValidationStatus(false);
                setValidationMessage("An error occurred during validation.");
            });
    };

    const renderFields = () => {
        if (!selectedTarget) return null;

        return Object.entries(configRequirements[selectedTarget]).map(([label, key]) => (
            <div key={key} className="form-field">
                <label className='label-sql-add'>{label}</label>
                <input
                    type={label.toLowerCase() === 'password' ? 'password' : 'text'}
                    value={targetConfigFormData[key] || ''}
                    className="input-sql-add"
                    onChange={(e) =>
                        setTargetConfigFormData({ ...targetConfigFormData, [key]: e.target.value })
                    }
                    placeholder={`Enter ${label.toLowerCase()}`}
                />
            </div>
        ));
    };

    return (
        <div className="form-page-card-inner-layout">
            <div className="form-page-card">
                <label className='label-sql-add'>Select Target System</label>
                <select 
                    value={selectedTarget} 
                    onChange={handleSourceChange} 
                    className="input-sql-add"
                >
                    <option value="">Select a target system</option>
                    {Object.keys(configRequirements).map((target) => (
                        <option key={target} value={target}>
                            {target}
                        </option>
                    ))}
                </select>

                <div className="form-two-columns">
                    {renderFields()}
                </div>

                <div className="navigation-buttons">
                    <button onClick={handlePrevious}>Previous</button>
                    {validationStatus ? 
                        <button onClick={handleNext}>Next</button> :
                        <button onClick={handleValidation}>Validate</button>
                    }
                </div>
                {validationMessage && <p>{validationMessage}</p>}
            </div>
        </div>
    );
};


export default AddProject;