import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../style/HamburgerMenu.css';
import {FiCalendar, FiLogOut, FiX } from 'react-icons/fi';
import {PiClockCounterClockwiseBold} from "react-icons/pi";
import AddProjectDialog from './AddProjectDialog';
import { FaRegCircleUser} from 'react-icons/fa6';
import { MdOutlineManageHistory } from "react-icons/md";
import { GoHome } from "react-icons/go";
import { IoSettingsOutline} from "react-icons/io5";
import { IoMdNotificationsOutline } from "react-icons/io";
import { RiFolder2Line } from "react-icons/ri";

function HamburgerMenu() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isLogin, setLogin] = useState(false);
  const [projectNameValidationData, setProjectNameValidationData] = useState([]);
  const [newProjectData, setNewProjectData] = useState({
    projectname: '',sourcesystem: '',servernames: '',databasenames: '',usernames: '',passwords: '',filepaths: '', hostnames: '', ports: '',protocols: '',dbqs: '',users: '',db_names: '',accountnames: '',accountkeys: '',containernames: '',auth_databases:'',excelfilenames:'',taregtsystem: '',servernamet: '', databasenamet: '',usernamet: '',passwordt: '',filepatht: '', hostnamet: '', portt: '',protocolt: '',dbqt: '',usert: '', db_namet: '',accountnamet: '',accountkeyt: '',containernamet: '',auth_databaset:'',excelfilenamet:'',
  });
  const [data, setData] = useState([]);
  const [showTargetDialog, setShowTargetDialog] = useState(false)
  const [userEmail, setUserEmail] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const loggedInUserEmail = JSON.parse(localStorage.getItem('user'))?.email;

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const logout = () => {
    localStorage.clear();
    setLogin(false);
    setUserEmail('');
    navigate('/login');
  };
  
  const saveDataToBackend = (newProjectData) => {
    fetch(process.env.REACT_APP_API_URL+'save_newproject', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({useremail:newProjectData.useremail, projectname:newProjectData.projectname,
        sourcesystem:newProjectData.sourcesystem, servernames:newProjectData.servernames, databasenames:newProjectData.databasenames,usernames:newProjectData.usernames,passwords:newProjectData.passwords,filepaths:newProjectData.filepaths, hostnames:newProjectData.hostnames, ports:newProjectData.ports, protocols:newProjectData.protocols, dbqs:newProjectData.dbqs, users:newProjectData.users, db_names:newProjectData.db_names
        ,taregtsystem:newProjectData.taregtsystem, servernamet:newProjectData.servernamet, databasenamet:newProjectData.databasenamet,usernamet:newProjectData.usernamet,passwordt:newProjectData.passwordt,filepatht:newProjectData.filepatht, hostnamet:newProjectData.hostnamet, portt:newProjectData.portt, protocolt:newProjectData.protocolt, dbqt:newProjectData.dbqt, usert:newProjectData.usert, db_namet:newProjectData.db_namet }),
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      console.log(data);
      if (data.status === 'success') {
        console.log('Data saved successfully to the backend!');
      } else {
        console.error('Error saving data to the backend:', data.message);
      }
    })
      .catch((error) => {
        console.error('Error saving data to the backend:', error);
      });
  };

  const handleSaveNewProject = (newProjectData) => {
    if (!newProjectData.projectname.trim() || !newProjectData.sourcesystem.trim() || !newProjectData.taregtsystem.trim()) {
      return;
    }

    const currentDate = new Date();
    const createdDate = currentDate.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    });

    const newProject = {
      projectname: newProjectData.projectname,
      sourcesystem: newProjectData.sourcesystem,
      targetsystem: newProjectData.taregtsystem,
      createddate: createdDate,
      LastUpdateDate: createdDate,
    };

    const updatedData = [...data, newProject];
    setData(updatedData);
    setIsDialogOpen(false);
    saveDataToBackend(updatedData);
  };

  return (
    <div className={`hamburger-menu ${isOpen ? 'open' : ''}`}>
      <div className="hamburger-icon" onClick={toggleMenu}>
        {isOpen ? (
          <FiX className="sidebar-icon" /> 
        ) : (
          <>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </>
        )}
      </div>
      <ul className="menu-items">
      <button className="PROJECTadd1"  onClick={() => setIsDialogOpen(true)}>
            <span>+ ADD PROJECT</span> 
      </button>
      <div className="bottom-iconsbuttons">
      <Link className="logout-btn" to="/">
        <div><GoHome className="sidebar-icon" /> Home</div>
      </Link>
      <Link className="logout-btn" to="/Project">
        <div><RiFolder2Line className="sidebar-icon" /> Projects</div>
      </Link>
<Link className="logout-btn" to="/Project">
  <div><PiClockCounterClockwiseBold className="sidebar-icon" /> Connectors</div>
</Link>
<Link className="logout-btn" to="/Scheduler">
  <div><FiCalendar className="sidebar-icon" /> Schedule</div>
</Link>
<Link className="logout-btn" to="/RunHistoryMain">
  <div><MdOutlineManageHistory className="sidebar-icon" /> Run history</div>
</Link>
        <button className="logout-btn" onClick={logout}>
            <FiLogOut  className="login-icon" /> 𝗟𝗼𝗴𝗼𝘂𝘁
        </button>
      </div>

        <div className="dropdown">
        <div className="bottom-icons">
    <button className="logout-btn">
      <IoMdNotificationsOutline className="login-icon" /> Notifications
    </button>
    <button className="logout-btn">
      <IoSettingsOutline className="login-icon" /> Setting's
    </button>
    </div>
    <div className="bottom-icons-line"></div>
    <div className="profile-section">
  </div>
    <div className="bottom-icons">
            <button className="logout-btn1" onClick={toggleDropdown}>
      <FaRegCircleUser className="login-icon" />
    </button>
    </div>
    </div>

            <div className="dropdown">
            <div className="bottom-icons">
        <button className="logout-btn1">
          <IoMdNotificationsOutline className="login-icon" /> Notifications
        </button>
        <button className="logout-btn1">
          <IoSettingsOutline className="login-icon" /> Setting's
        </button>
        </div>
        <div className="bottom-icons-line"></div>
        <div className="profile-section">
      </div>
        <div className="bottom-icons1">
                <button className="logout-btn1" onClick={toggleDropdown}>
          <FaRegCircleUser className="login-icon" />
        </button>
        </div>
  </div>
  
      </ul>
      {isDialogOpen && (
          <AddProjectDialog
            isOpen={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
            onSave={handleSaveNewProject}
            showTargetDialog={showTargetDialog}
            setShowTargetDialog={setShowTargetDialog}
            projectNameValidationData = {projectNameValidationData}
          />
        )}
    </div>

  );
}

export default HamburgerMenu;