import React, { useState, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../style/Execution.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import NavBar from './NavBar';
import ProgressBar from './progressBar';
import { useNavigate } from 'react-router-dom';

export default function Index() {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState('Processing');
  const [searchLowerCase, setSearchLowerCase] = useState('');
  const [showSuccessIcon, setShowSuccessIcon] = useState(false);
  const [showDialog, setShowDialog] = useState(true);
  const projectname = 'ProjectA';
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const navigate = useNavigate(); // Get the navigate function
  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleStop = () => {
    setStatus('Stopped');
  };

  const handleClose = () => {
    console.log('Close button clicked');
    setShowDialog(false);
    navigate('/project'); // Navigate to the 'project' route
  };

  // const handleRefreshProjects = () => {
  //   fetchAuditHistoryExecution();
  // };

  // const fetchAuditHistoryExecution = () => {
  //   const loggedInUserEmail = JSON.parse(localStorage.getItem('user'))?.email;
  //   fetch(process.env.REACT_APP_API_URL+`audit_execution?email=${loggedInUserEmail}&projectname=${projectname}`)
  //     .then((response) => response.json())
  //     .then((audithistory) => {
  //       console.log('Projects received from backend:', audithistory);
  //       setData(audithistory);
  //     })
  //     .catch((error) => console.error('Error fetching data:', error));
  // };

  // useEffect(() => {
  //   fetchAuditHistoryExecution();
  // }, [projectname]);

  useEffect(() => {
    if (status === 'Processing') {
      // Use a setTimeout to change the status after 10 seconds
      const timeoutId = setTimeout(() => {
        setStatus('Completed');
        setShowSuccessIcon(true);
      }, 10000); // 10 seconds in milliseconds

      // Clear the timeout if the component unmounts or if the status changes before 10 seconds
      return () => clearTimeout(timeoutId);
    }
  }, [status]);

  const filteredData = data.filter((item) => {
    const { objectcount, statusmessage, duration } = item;
    const objectCountIncludes =
      objectcount && typeof objectcount === 'string' && objectcount.includes(searchLowerCase);
    const statusMessageIncludes =
      statusmessage && typeof statusmessage === 'string' && statusmessage.includes(searchLowerCase);
    const durationIncludes =
      duration && typeof duration === 'string' && duration.includes(searchLowerCase);
    return objectCountIncludes || statusMessageIncludes || durationIncludes;
  });

  useEffect(() => {
    filteredData.forEach((item, index) => {
      if (item.objectcount) {
        setShowSuccessMessage(true);
      }
    });
  }, [filteredData]);

  const renderStatusImage = () => {
    if (status === 'Processing') {
      return (
        <img
          src="https://media2.giphy.com/media/NgEAblIYpqSlwpj15O/giphy.gif?cid=6c09b952cg3fben5t59orwj5woowuht2q3njo2i07x9awz2f&ep=v1_stickers_related&rid=giphy.gif&ct=s"
          alt="Processing GIF"
          style={{ width: '35px', height: '35px', marginRight: '10px' }}
        />
      );
    } else if (status === 'Stopped') {
      return (
        <img
          src="https://img.freepik.com/free-vector/red-prohibited-sign-no-icon-warning-stop-symbol-safety-danger-isolated-vector-illustration_56104-912.jpg?w=360"
          alt="Stop Icon"
          style={{ width: '38px', height: '35px', marginRight: '10px' }}
        />
      );
    } else if (status === 'Completed' && showSuccessIcon) {
      return (
        <img
          src="https://static-00.iconduck.com/assets.00/process-completed-symbolic-icon-2048x2048-baquwdk1.png"
          alt="Success Icon"
          style={{ width: '38px', height: '35px', marginRight: '10px' }}
        />
      );
    }
  };

  if (!showDialog) {
    return null;
  }

  return (
    <div>
      <div className="performance-project-container">
        <div className="p-4">
          <div className="mt-2 d-flex justify-content-between mb-4">
            <button className="close-button" onClick={handleClose}>
              <AiOutlineClose />
            </button>
            <div>
              <h4>Performance Run</h4>
              <span>Click the "stop" button to interrupt the operation</span>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              margin: '10px 0px 20px 0px',
              justifyContent: 'space-between',
              width: '100%',
              borderBottom: '1px solid lightgray',
            }}
          >
            <div style={{ display: 'flex', marginBottom: 15 }}>
              {renderStatusImage()}
              <h4 style={{ color: status === 'Processing' ? 'black' : 'green' }}>
                {status}
              </h4>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleStop}
                disabled={status === 'Stopped'}
              >
                STOP
              </button>
            </div>
          </div>

          <div className="mt-4">
            {showSuccessMessage && (
              <div className="success-message">
                Data successfully loaded! Closing in 2 seconds...
              </div>
            )}
            <div className="table-outer-container">
              <table className="table table-borderless">
                <tbody>
                  {filteredData.map((item, index) => (
                    <tr
                      key={index}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <td>
                        <div>
                          <strong>TOTAL OBJECTS:</strong> {item.objectcount}
                        </div>
                      </td>
                      <td>
                        <div>
                          <strong>STATUS:</strong> {item.statusmessage}
                        </div>
                      </td>
                      <td>
                        <div>
                          <strong>TIME DURATION (minutes):</strong> {item.duration}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div>
            <button
              type="button"
              className="btn btn-dangerclose"
              onClick={handleClose}
            >
              CLOSE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
