import React, { useState } from "react";

function ValidateCredentials({ onConnect, tabId }) {
  const [credentials, setCredentials] = useState({
    host: "",
    port: 5432,
    user: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({ ...prev, [name]: value }));
  };

  const handleConnect = () => {
    onConnect(credentials, tabId);  // Pass both credentials and tabId
  };

  return (
    <div className="credentials-form">
      <input name="host" placeholder="Host" onChange={handleChange} />
      <input name="port" placeholder="Port" type="number" onChange={handleChange} />
      <input name="user" placeholder="Username" onChange={handleChange} />
      <input name="password" placeholder="Password" type="password" onChange={handleChange} />
      <button onClick={handleConnect}>Connect</button>
    </div>
  );
}

export default ValidateCredentials;
