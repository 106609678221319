import '../../style/ScrollableBox.css'
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useState, useEffect } from 'react';

const SelectSourceSystem = ({showSourceDialog, setDisableSaveAndRun, newProjectData, handleInputChange,disableSourceOnValidate, selectedSource, selectedFile, setButtonsDisabled, handleValidate, sourceValidationResult, setValidateButton, setSourceValidate}) => {
    console.log('selectedSource:'+selectedSource, showSourceDialog)
    const [showPassword, setShowPassword] = useState(false);
    return (
        <div>
          {setButtonsDisabled(sourceValidationResult === 'Invalid configuration')}
          {setDisableSaveAndRun(false)}     
          {showSourceDialog && selectedSource === 'MS SQL Server' && (
            <div className="target-config">
              <div className='header-title-addProjects'>MS SQL Server</div>
              <div className="scrollable-box-AddProjectsDialog">
              <div className="label-input-container">
                <label className="progress-labels">Server</label>
                <div className="input-container">
                  <input
                    type="text"
                    placeholder="Type The Server"
                    value={newProjectData.servernames}
                    onChange={(e) => handleInputChange(e, 'servernames')}
                    disabled={disableSourceOnValidate}
                  />
                </div>
                </div>
                <div className="label-input-container">
                <label className="progress-labels">Portname</label>
                <div className="input-container">
                  <input
                    type="text"
                    placeholder="Type The ports"
                    value={newProjectData.ports}
                    onChange={(e) => handleInputChange(e, 'ports')}
                    disabled={disableSourceOnValidate}
                  />
                </div>
                </div>
                <div className="label-input-container">
                <label className="progress-labels">Database</label>
                <div className="input-container">
                  <input
                    type="text"
                    placeholder="Type The Database"
                    value={newProjectData.databasenames}
                    onChange={(e) => handleInputChange(e, 'databasenames')}
                    disabled={disableSourceOnValidate}
                  />
                </div>
                </div>
            
                <div className="label-input-container">
                <label className="progress-labels">Username</label>
                <div className="input-container">
                  <input
                    type="text"
                    placeholder="Type The Username"
                    value={newProjectData.usernames}
                    onChange={(e) => handleInputChange(e, 'usernames')}
                    disabled={disableSourceOnValidate}
                  />
                </div>
            </div>
            <div className="label-input-container">
              <label className="progress-labels">Password</label>
              <div className="input-container-source-target">
                <div className="input-wrapper">
                  <input
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Type The Password"
                      value={newProjectData.passwords}
                      onChange={(e) => handleInputChange(e, 'passwords')}
                      disabled={disableSourceOnValidate}
                  />
                  <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
              </div>
            </div>
            </div>
          </div>
          )}
          {/* The following is to prevent unneccessary re-renders while password is being entered */}
          {/* {useEffect(() => {
                const timerId = setTimeout(() => {
                  console.log('Sending request to server');
                }, 500); 
                return () => clearTimeout(timerId);
              }, [newProjectData.passwords])}; */}

          {showSourceDialog && selectedSource === 'MongoDB' && (
                <div className="target-config">
                  <div className='header-title-addProjects'>MongoDB</div>
                  <div className="scrollable-box-AddProjectsDialog">                    
                    <div className="label-input-container">
                    <label className="progress-labels">Hostname</label>
                    <div className="input-container">

                      <input
                        type="text"
                        placeholder="Type The hostname"
                        value={newProjectData.hostnames}
                        onChange={(e) => handleInputChange(e, 'hostnames')}
                        disabled={disableSourceOnValidate}
                      />
                    </div>
                    </div>
                    <div className="label-input-container">
                    <label className="progress-labels">Portname</label>
                    <div className="input-container">
                      <input
                        type="text"
                        placeholder="Type The ports"
                        value={newProjectData.ports}
                        onChange={(e) => handleInputChange(e, 'ports')}
                        disabled={disableSourceOnValidate}
                      />
                    </div>
                    </div>
                    <div className="label-input-container">
                    <label className="progress-labels">Username</label>
                    <div className="input-container">
                      <input
                        type="text"
                        placeholder="Type The Username"
                        value={newProjectData.usernames}
                        onChange={(e) => handleInputChange(e, 'usernames')}
                        disabled={disableSourceOnValidate}
                      />
                    </div>
                </div>
                <div className="label-input-container">
                <label className="progress-labels">Password</label>
                <div className="input-container-source-target">
                  <div className="input-wrapper">
                    <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Type The Password"
                        value={newProjectData.passwords}
                        onChange={(e) => handleInputChange(e, 'passwords')}
                        disabled={disableSourceOnValidate}
                    />
                    <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                </div>
                </div>
                <div className="label-input-container">
                    <label className="progress-labels">AuthDatabase</label>
                    <div className="input-container">
                    <input
                      type="text"
                      placeholder="Type your auth_database"
                      value={newProjectData.auth_databases}
                      onChange={(e) => handleInputChange(e, 'auth_databases')}
                      disabled={disableSourceOnValidate}
                    />
                  </div>
                  </div>
                  </div>
                </div>
          )}

          {showSourceDialog && selectedSource === 'MySQL' && (      
          <div className="target-config">
            <div className='header-title-addProjects'>MySQL</div>
            <div className="scrollable-box-AddProjectsDialog"> 
            <div className="label-input-container">
              <label className="progress-labels">Hostname</label>
              <div className="input-container">
                <input
                    type="text"
                    placeholder="Type The HostName"
                    value={newProjectData.hostnames}
                    onChange={(e) => handleInputChange(e, 'hostnames')}
                    disabled={disableSourceOnValidate}
                    />
              </div>
            </div>
            <div className="label-input-container">
            <label className="progress-labels">Portname</label>
            <div className="input-container">
              <input
                type="text"
                placeholder="Type The Portname"
                value={newProjectData.ports}
                onChange={(e) => handleInputChange(e, 'ports')}
                disabled={disableSourceOnValidate}
                        />
              </div>
            </div>
            <div className="label-input-container">
            <label className="progress-labels">DataBase</label>
            <div className="input-container">
                <input
                    type="text"
                    placeholder="Type The Database"
                    value={newProjectData.databasenames}
                    onChange={(e) => handleInputChange(e, 'databasenames')}
                    disabled={disableSourceOnValidate}
                              />
                      </div>
                </div>
            <div className="label-input-container">
            <label className="progress-labels">Username</label>
            <div className="input-container">
                  <input
                    type="text"
                    placeholder="Type The Username"
                    value={newProjectData.usernames}
                    onChange={(e) => handleInputChange(e, 'usernames')}
                    disabled={disableSourceOnValidate}
                            />
                  </div>
                </div>
        
            <div className="label-input-container">
            <label className="progress-labels">Password</label>
              <div className="input-container-source-target">
                  <div className="input-wrapper">
                    <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Type The Password"
                        value={newProjectData.passwords}
                        onChange={(e) => handleInputChange(e, 'passwords')}
                        disabled={disableSourceOnValidate}
                    />
                    <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                </div>
                </div>
                </div>
          </div>
          )}

          {showSourceDialog && selectedSource === 'Azure MySQL' && (
            <div className="target-config">
              <div className='header-title-addProjects'>Azure MySQL</div>
              <div className="scrollable-box-AddProjectsDialog"> 
              <div className="label-input-container">
                <label className="progress-labels">Hostname</label>
                <div className="input-container">
                    <input
                        type="text"
                        placeholder="Type The HostName"
                        value={newProjectData.hostnames}
                        onChange={(e) => handleInputChange(e, 'hostnames')}
                        disabled={disableSourceOnValidate}
                        />
                </div>
              </div>
              <div className="label-input-container">
              <label className="progress-labels">Portname</label>
              <div className="input-container">
                <input
                  type="text"
                  placeholder="Type The Portname"
                  value={newProjectData.ports}
                  onChange={(e) => handleInputChange(e, 'ports')}
                  disabled={disableSourceOnValidate}
                          />
                </div>
              </div>
              <div className="label-input-container">
                <label className="progress-labels">Database</label>
                <div className="input-container">
                  <input
                      type="text"
                      placeholder="Type The Database"
                      value={newProjectData.databasenames}
                      onChange={(e) => handleInputChange(e, 'databasenames')}
                      disabled={disableSourceOnValidate}
                                />
                </div>
              </div>
          
              <div className="label-input-container">
                <label className="progress-labels">Username</label>
                <div className="input-container">
                    <input
                      type="text"
                      placeholder="Type The Username"
                      value={newProjectData.usernames}
                      onChange={(e) => handleInputChange(e, 'usernames')}
                      disabled={disableSourceOnValidate}
                              />
                </div>
              </div>
          
              <div className="label-input-container">
                <label className="progress-labels">Password</label>
                <div className="input-container-source-target">
                <div className="input-wrapper">
                  <input
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Type The Password"
                      value={newProjectData.passwords}
                      onChange={(e) => handleInputChange(e, 'passwords')}
                      disabled={disableSourceOnValidate}
                  />
                  <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
              </div>
              </div>
              </div>
            </div>
          )}

          {showSourceDialog && selectedSource === 'PostgreSQL' && (
          <div className="target-config">
            <div className='header-title-addProjects'>PostgreSQL</div>
            <div className="scrollable-box-AddProjectsDialog"> 
          <div className="label-input-container">
          <label className="progress-labels">Hostname</label>
          <div className="input-container">
              <input
                  type="text"
                  placeholder="Type The HostName"
                  value={newProjectData.servernames}
                  onChange={(e) => handleInputChange(e, 'servernames')}
                  disabled={disableSourceOnValidate}
                  />
              </div>
          </div>
          <div className="label-input-container">
          <label className="progress-labels">Portname</label>
          <div className="input-container">
                <input
                  type="text"
                  placeholder="Type The Portname"
                  value={newProjectData.ports}
                  onChange={(e) => handleInputChange(e, 'ports')}
                  disabled={disableSourceOnValidate}
                          />
                    </div>
              </div>
          <div className="label-input-container">
          <label className="progress-labels">Database</label>
          <div className="input-container">
              <input
                  type="text"
                  placeholder="Type The Database"
                  value={newProjectData.databasenames}
                  onChange={(e) => handleInputChange(e, 'databasenames')}
                  disabled={disableSourceOnValidate}
                            />
                    </div>
              </div>

          <div className="label-input-container">
          <label className="progress-labels">Username</label>
          <div className="input-container">
                <input
                  type="text"
                  placeholder="Type The Username"
                  value={newProjectData.usernames}
                  onChange={(e) => handleInputChange(e, 'usernames')}
                  disabled={disableSourceOnValidate}
                          />
                    </div>
              </div>

          <div className="label-input-container">
          <label className="progress-labels">Password</label>
          <div className="input-container-source-target">
                <div className="input-wrapper">
                  <input
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Type The Password"
                      value={newProjectData.passwords}
                      onChange={(e) => handleInputChange(e, 'passwords')}
                      disabled={disableSourceOnValidate}
                  />
                  <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
              </div>
              </div>
            </div>
          </div>
          )}

          {showSourceDialog && selectedSource === 'Azure PostgreSQL' && (
              <div className="target-config">
                <div className='header-title-addProjects'>Azure PostgreSQL</div>
                <div className="scrollable-box-AddProjectsDialog"> 
                <div className="label-input-container">
                <label className="progress-labels">Hostname</label>
                <div className="input-container">
                  <input
                      type="text"
                      placeholder="Type The HostName"
                      value={newProjectData.hostnames}
                      onChange={(e) => handleInputChange(e, 'hostnames')}
                      disabled={disableSourceOnValidate}
                      />
                  </div>
              </div>

              <div className="label-input-container">
              <label className="progress-labels">Portname</label>
              <div className="input-container">
                <input
                  type="text"
                  placeholder="Type The Portname"
                  value={newProjectData.ports}
                  onChange={(e) => handleInputChange(e, 'ports')}
                  disabled={disableSourceOnValidate}
                          />
                    </div>
              </div>

              <div className="label-input-container">
              <label className="progress-labels">Database</label>
              <div className="input-container">
                    <input
                      type="text"
                      placeholder="Type The Database"
                      value={newProjectData.databasenames}
                      onChange={(e) => handleInputChange(e, 'databasenames')}
                      disabled={disableSourceOnValidate}
                                  />
                          </div>
                  </div>
            
                <div className="label-input-container">
              <label className="progress-labels">Username</label>
                <div className="input-container">
                    <input
                        type="text"
                        placeholder="Type The Username"
                        value={newProjectData.usernames}
                        onChange={(e) => handleInputChange(e, 'usernames')}
                        disabled={disableSourceOnValidate}
                                />
                        </div>
                  </div>
            
            <div className="label-input-container">
            <label className="progress-labels">Password</label>
            <div className="input-container-source-target">
                <div className="input-wrapper">
                  <input
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Type The Password"
                      value={newProjectData.passwords}
                      onChange={(e) => handleInputChange(e, 'passwords')}
                      disabled={disableSourceOnValidate}
                  />
                  <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
              </div>
                    </div>
                </div>
                </div>
          )}

          {showSourceDialog && selectedSource === 'Azure SQL Server' && (
          <div className="target-config">
            <div className='header-title-addProjects'>Azure SQL Server</div>
            <div className="scrollable-box-AddProjectsDialog"> 
          <div className="label-input-container">
            <label className="progress-labels">Server</label>
            <div className="input-container">
            <input
              type="text"
              placeholder="Server"
              value={newProjectData.servernames}
              onChange={(e) => handleInputChange(e, 'servernames')}
              disabled={disableSourceOnValidate}
            />
          </div>
          </div>
          <div className="label-input-container">
          <label className="progress-labels">Portname</label>
          <div className="input-container">
                <input
                  type="text"
                  placeholder="Type The Portname"
                  value={newProjectData.ports}
                  onChange={(e) => handleInputChange(e, 'ports')}
                  disabled={disableSourceOnValidate}
                          />
                    </div>
          </div>
          <div className="label-input-container">
            <label className="progress-labels">Database</label>
            <div className="input-container">
            <input
              type="text"
              placeholder="Database"
              value={newProjectData.databasenames}
              onChange={(e) => handleInputChange(e, 'databasenames')}
              disabled={disableSourceOnValidate}
            />
          </div>
          </div>

            <div className="label-input-container">
            <label className="progress-labels">Username</label>
            <div className="input-container">
            <input
              type="text"
              placeholder="Username"
              value={newProjectData.usernames}
              onChange={(e) => handleInputChange(e, 'usernames')}
              disabled={disableSourceOnValidate}
            />
          </div>
          </div>
          <div className="label-input-container">
            <label className="progress-labels">Password</label>
            <div className="input-container-source-target">
                <div className="input-wrapper">
                  <input
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Type The Password"
                      value={newProjectData.passwords}
                      onChange={(e) => handleInputChange(e, 'passwords')}
                      disabled={disableSourceOnValidate}
                  />
                  <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
              </div>
          </div>
          </div>
          </div>
          )}

          {showSourceDialog && selectedSource === 'Azure CosmosDB' && (
                <div className="target-config">
                  <div className='header-title-addProjects'>Azure CosmoDB</div>
                  <div className="scrollable-box-AddProjectsDialog"> 
                    
                    <div className="label-input-container">
                    <label className="progress-labels">Hostname</label>
                    <div className="input-container">
                      <input
                        type="text"
                        placeholder="Type The hostname"
                        value={newProjectData.hostnames}
                        onChange={(e) => handleInputChange(e, 'hostnames')}
                        disabled={disableSourceOnValidate}
                      />
                    </div>
                    </div>
                    <div className="label-input-container">
                    <label className="progress-labels">Portname</label>
                    <div className="input-container">
                      <input
                        type="text"
                        placeholder="Type The ports"
                        value={newProjectData.ports}
                        onChange={(e) => handleInputChange(e, 'ports')}
                        disabled={disableSourceOnValidate}
                      />
                    </div>
                    </div>
                    <div className="label-input-container">
                    <label className="progress-labels">Primary</label>
                    <div className="input-container">
                      <input
                        type="text"
                        placeholder="Type The primary_key"
                        value={newProjectData.primary_key}
                        onChange={(e) => handleInputChange(e, 'primary_key')}
                        disabled={disableSourceOnValidate}
                      />
                    </div>
                </div>
                    <div className="label-input-container">
                    <label className="progress-labels">Username</label>
                    <div className="input-container">
                      <input
                        type="text"
                        placeholder="Type The Username"
                        value={newProjectData.usernames}
                        onChange={(e) => handleInputChange(e, 'usernames')}
                        disabled={disableSourceOnValidate}
                      />
                    </div>
                </div>
          
                    <div className="label-input-container">
                    <label className="progress-labels">Password</label>
                    <div className="input-container-source-target">
                      <div className="input-wrapper">
                        <input
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Type The Password"
                            value={newProjectData.passwords}
                            onChange={(e) => handleInputChange(e, 'passwords')}
                            disabled={disableSourceOnValidate}
                        />
                        <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </span>
                      </div>
                    </div>
                </div>
                </div>
                </div>
          )}

          {showSourceDialog && selectedSource === 'EXCEL' && (
                  <div className="target-config">
                    <div className='header-title-addProjects'>Excel</div>
                    {/* <div className="scrollable-box-AddProjectsDialog">  */}
                  <div className="label-input-container">
                    <label className="progress-labels">Filepath</label>
                    <div className="input-container">
                      <input
                        type="text"
                        placeholder={selectedFile ? selectedFile.name : 'filepaths'}
                        value={newProjectData.filepaths}
                        onChange={(e) => handleInputChange(e, 'filepaths')}
                        disabled={disableSourceOnValidate}
                      />
                    </div>
                  </div>
                  {/* </div> */}
                </div>
          )}
              
          {showSourceDialog && selectedSource === 'Azure Blob Storage Flatfiles' && (
          <div className="target-config">
            <div className='header-title-addProjects'>Azure Blob Storage Flatfiles</div>
            <div className="scrollable-box-AddProjectsDialog"> 
          <div className="label-input-container">
          <label className="progress-labels">AccountName</label>
          <div className="input-container">
                  <input
                    type="text"
                    placeholder="Type the AccountName"
                    value={newProjectData.accountnames}
                    onChange={(e) => handleInputChange(e, 'accountnames')}
                    disabled={disableSourceOnValidate}
                  />
              </div>
              </div>
              <div className="sourcesystem-config">
          <div className="label-input-container">
          <label className="progress-labels">AccountKey</label>
          <div className="input-container">
                  <input
                    type="text"
                    placeholder="Type the AccountKey"
                    value={newProjectData.accountkeys}
                    onChange={(e) => handleInputChange(e, 'accountkeys')}
                    disabled={disableSourceOnValidate}
                  />
              </div>
              </div>
              <div className="label-input-container">
                    <label className="progress-labels">ContainerName</label>
                    <div className="input-container">
                      <input
                        type="text"
                        placeholder="Type The containername"
                        value={newProjectData.containernames}
                        onChange={(e) => handleInputChange(e, 'containernames')}
                        disabled={disableSourceOnValidate}
                      />
                    </div>
                  </div>
              </div>
            </div>
            </div>
          )}

          {showSourceDialog && selectedSource === 'Azure Data Lake Table Storage' && (
            <div className="target-config">
              <div className='header-title-addProjects'>Azure Data Lake Table Storage</div>
              <div className="scrollable-box-AddProjectsDialog"> 
            <div className="label-input-container">
            <label className="progress-labels">AccountName</label>
            <div className="input-container">
                    <input
                      type="text"
                      placeholder="Type the AccountName"
                      value={newProjectData.accountnames}
                      onChange={(e) => handleInputChange(e, 'accountnames')}
                      disabled={disableSourceOnValidate}
                    />
                </div>
                </div>
                <div className="sourcesystem-config">
            <div className="label-input-container">
            <label className="progress-labels">AccountKey</label>
            <div className="input-container">
                    <input
                      type="text"
                      placeholder="Type the AccountKey"
                      value={newProjectData.accountkeys}
                      onChange={(e) => handleInputChange(e, 'accountkeys')}
                      disabled={disableSourceOnValidate}
                    />
                </div>
                </div>
                <div className="label-input-container">
                  <label className="progress-labels">ContainerName</label>
                  <div className="input-container">
                    <input
                      type="text"
                      placeholder="Type The containername"
                      value={newProjectData.containernames}
                      onChange={(e) => handleInputChange(e, 'containernames')}
                      disabled={disableSourceOnValidate}
                    />
                  </div>
                </div>
                </div>
              </div>
              </div>
          )}

          {showSourceDialog && selectedSource === 'Azure Blob Storage Excel' && (
                <div className="target-config">
                  <div className='header-title-addProjects'>Azure Blob Storage Excel</div>
                  <div className="scrollable-box-AddProjectsDialog"> 
                <div className="label-input-container">
                <label className="progress-labels">AccountName</label>
                <div className="input-container">
                      <input
                        type="text"
                        placeholder="Type the AccountName"
                        value={newProjectData.accountnames}
                        onChange={(e) => handleInputChange(e, 'accountnames')}
                        disabled={disableSourceOnValidate}
                      />
                  </div>
                  </div>
                  <div className="sourcesystem-config">
                <div className="label-input-container">
                <label className="progress-labels">AccountKey</label>
                <div className="input-container">
                      <input
                        type="text"
                        placeholder="Type the AccountKey"
                        value={newProjectData.accountkeys}
                        onChange={(e) => handleInputChange(e, 'accountkeys')}
                        disabled={disableSourceOnValidate}
                      />
                  </div>
                  </div>
                  <div className="label-input-container">
                    <label className="progress-labels">ContainerName</label>
                    <div className="input-container">
                      <input
                        type="text"
                        placeholder="Type The containername"
                        value={newProjectData.containernames}
                        onChange={(e) => handleInputChange(e, 'containernames')}
                        disabled={disableSourceOnValidate}
                      />
                    </div>
                  </div>
                  <div className="label-input-container">
                    <label className="progress-labels">Excel_File Name</label>
                    <div className="input-container">
                      <input
                        type="text"
                        placeholder= "ex: Filename.xlsx"
                        value={newProjectData.excelfilenames}
                        onChange={(e) => handleInputChange(e, 'excelfilenames')}
                        disabled={disableSourceOnValidate}
                      />
                    </div>
                  </div>
                  </div>
                  </div>
                  </div>
          )}

          {showSourceDialog && selectedSource === 'Azure Blob Storage' && (
              <div className="target-config">
                <div className='header-title-addProjects'>Azure Blob Storage</div>
                <div className="scrollable-box-AddProjectsDialog"> 
              <div className="label-input-container">
              <label className="progress-labels">AccountName</label>
              <div className="input-container">
                    <input
                      type="text"
                      placeholder="Type The AccountName"
                      value={newProjectData.accountnames}
                      onChange={(e) => handleInputChange(e, 'accountnames')}
                      disabled={disableSourceOnValidate}
                    />
                </div>
                </div>
                <div className="sourcesystem-config">
              <div className="label-input-container">
              <label className="progress-labels">AccountKey</label>
              <div className="input-container">
                    <input
                      type="text"
                      placeholder="Type The AccountKey"
                      value={newProjectData.accountkeys}
                      onChange={(e) => handleInputChange(e, 'accountkeys')}
                      disabled={disableSourceOnValidate}
                    />
                </div>
                </div>
                <div className="label-input-container">
                  <label className="progress-labels">ContainerName</label>
                  <div className="input-container">
                    <input
                      type="text"
                      placeholder="Type The containername"
                      value={newProjectData.containernames}
                      onChange={(e) => handleInputChange(e, 'containernames')}
                      disabled={disableSourceOnValidate}
                    />
                  </div>
                </div>
                <div className="label-input-container">
                    <label className="progress-labels">Excel FileName</label>
                    <div className="input-container">
                      <input
                        type="text"
                        placeholder= "ex: Filename.xlsx"
                        value={newProjectData.excelfilenames}
                        onChange={(e) => handleInputChange(e, 'excelfilenames')}
                        disabled={disableSourceOnValidate}
                      />
                    </div>
                  </div>
                </div>
              </div>
              </div>
          )}

          {showSourceDialog && selectedSource === 'Text/Csv' && (
                  <div className="target-config">
                    <div className='header-title-addProjects'>Text/CSV</div>
                    <div className="scrollable-box-AddProjectsDialog"> 
                    <div className="label-input-container">
                      <label className="progress-labels">Filepath</label>
                      <div className="input-container">
                        <input
                          type="text"
                          placeholder={selectedFile ? selectedFile.name : 'Filepath'}
                          value={newProjectData.filepaths}
                          onChange={(e) => handleInputChange(e, 'filepaths')}
                          disabled={disableSourceOnValidate}
                        />
                      </div>
                    </div>
                  </div>
                  </div>
          )}
      

          {newProjectData.sourcesystem && (
                    <div>
                      {setValidateButton(true)}
                      {setSourceValidate(true)}
                      {/* <button className="button1 button5 next-button2" onClick={(e)=>handleValidate(e, 'source')} disabled={!selectedSource}>
                      Validate
                      </button> */}
                      {/* {sourceValidationResult && (
                        <p className={sourceValidationResult === 'Invalid configuration' ? 'error-text' : 'success-text'}>
                          {sourceValidationResult}
                        </p>
                      )} */}
                    </div>
          )}
      </div>)
}

export default SelectSourceSystem;