import React, { useState, useEffect, useMemo } from 'react';
import { CiSearch } from "react-icons/ci";
import { FaChevronDown } from "react-icons/fa";
import '../style/Project2.css';

const SearchFilterSort = ({ 
  data = [], 
  onFilter, 
  filterOptions = {'All':'All', "Active":'Active', "Inactive": 'In-Active'}, 
  defaultFilter = 'All', 
  sortOptions = ['Creation Date', 'Last Update', 'Active Status'] 
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFilter, setSelectedFilter] = useState(defaultFilter);
  const [sortBy, setSortBy] = useState({ field: 'Creation Date', isAscending: true });

  // Memoize filtered and sorted data to avoid unnecessary renders
  const filteredAndSortedData = useMemo(() => {
    const filtered = data?.filter(project => {
      const searchLowerCase = searchQuery.toLowerCase();
      return (
        (!selectedFilter || selectedFilter === 'All' || project.status === selectedFilter) &&
        (project.projectname?.toLowerCase().includes(searchLowerCase) ||
         project.sourcesystem?.toLowerCase().includes(searchLowerCase) ||
         project.targetsystem?.toLowerCase().includes(searchLowerCase))
      );
    });

    const sorted = filtered.sort((a, b) => {
      const field = sortBy.field === 'Creation Date' ? 'createddate' :
                    sortBy.field === 'Last Update' ? 'lastupdatedate' : 'status';
      const compare = a[field]?.localeCompare(b[field]);
      return sortBy.isAscending ? compare : -compare;
    });

    return sorted;
  }, [data, searchQuery, selectedFilter, sortBy]);

  useEffect(() => {
    onFilter(filteredAndSortedData);  // Pass the filtered data to the parent only when needed
  }, [filteredAndSortedData, onFilter]);

  return (
    <div className="searchbar-filter-sort-layout">
      <div className="search-container-sql-exec-projects">
        <CiSearch className="search-icon" />
        <input
          type="text"
          className="search-bar-sql-exec-projects"
          value={searchQuery}
          placeholder="Search projects by name"
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <div className="filter-sort-selection">
        Filter:
          <select 
            value={selectedFilter} 
            onChange={(e) => setSelectedFilter(e.target.value)} 
            className="dropdown-sql-exec-projects"
          >
            {Object.entries(filterOptions).map(([key, value]) => (
              <option key={key} value={value}>
                {key}
              </option>
            ))}
        </select>
        <FaChevronDown className="dropdown-icon-sql-exec-projects" />
      </div>

      <div className="filter-sort-selection">
        Sort by:
        <select value={sortBy.field} onChange={(e) => setSortBy({ ...sortBy, field: e.target.value })} className="dropdown-sql-exec-projects">
          {sortOptions.map(option => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
        <FaChevronDown className="dropdown-icon-sql-exec-projects" />
      </div>
    </div>
  );
};

export default SearchFilterSort;
