import React, { useEffect, useState } from 'react';
import '../../style/ConnectionDetailsFooter.css';
import { DiDatabase } from "react-icons/di";
import { FaServer, FaUser, FaCheckCircle, FaTimesCircle } from "react-icons/fa"; 
import { GiBackwardTime } from "react-icons/gi"; // Changed from ImRadioUnchecked

const ConnectionDetailsFooter = ({ activeDatabase, host, user, connectionStatus, connectionStartTime }) => {
  const [elapsedTime, setElapsedTime] = useState('');

  useEffect(() => {
    const updateElapsedTime = () => {
      if (connectionStartTime) {
        const now = new Date();
        const diff = Math.floor((now - connectionStartTime) / 1000); // Seconds
        const hours = Math.floor(diff / 3600);
        const minutes = Math.floor((diff % 3600) / 60);
        const seconds = diff % 60;
        setElapsedTime(`${hours}h ${minutes}m ${seconds}s`);
      }
    };

    const interval = setInterval(updateElapsedTime, 1000);
    return () => clearInterval(interval);
  }, [connectionStartTime]);

  return (
    <footer className="footer">
      <div className="footer-content">
        <span><DiDatabase className='status-icon'/> {activeDatabase || 'None'}</span>
        <span><FaServer className='status-icon' /> {host || 'Not connected'}</span>
        <span><FaUser className='status-icon' /> {user || 'Not connected'}</span>
        <span>
          {connectionStatus ? (
            <FaCheckCircle className="status-icon connected" /> 
          ) : (
            <FaTimesCircle className="status-icon disconnected" />
          )}
          {connectionStatus ? ' Connected' : ' Disconnected'}
        </span>
        <span><GiBackwardTime className='status-icon' /> {elapsedTime || 'N/A'}</span>
      </div>
    </footer>
  );
};

export default ConnectionDetailsFooter;
