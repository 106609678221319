import React, { useState, useEffect, useCallback } from 'react';
import BreadcrumbItem from './Breadcrumbs';
import { Link, useNavigate } from "react-router-dom";
import '../style/Project2.css';
import { Toaster } from 'react-hot-toast';
import SearchFilterSort from './SearchFilterSort';
import {BsFillPencilFill } from 'react-icons/bs';

const Project2 = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [projectNameValidationData, setProjectNameValidationData] = useState([]);
    const loggedInUserEmail = JSON.parse(localStorage.getItem('user'))?.email;

    const fetchProjects = useCallback(async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_FASTAPI_URL}/v1/project/user-projects?email=${loggedInUserEmail}`
            ); 
            const projects = await response.json();
            setData(projects);
            const projectNames = projects?.map((project) => project.projectname);
            setProjectNameValidationData(projectNames);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [loggedInUserEmail]);

    useEffect(() => {
        fetchProjects();
    }, [fetchProjects]);

    const handleToggleStatus = async (project) => {
        const project_id = project.projectid;
        const newStatus = project.status === "In-Active" ? "reactivate" : "deactivate";

        try {
            const response = await fetch(
                `${process.env.REACT_APP_FASTAPI_URL}/v1/project/${project_id}/${newStatus}?email=${loggedInUserEmail}`,
                {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                }
            );
            const responseData = await response.json();

            if (responseData.status === 'success') {
                console.log(`Project ${newStatus}d successfully!`);
                setData((prevData) =>
                    prevData.map((item) =>
                        item.projectid === project_id
                            ? { ...item, status: newStatus === 'reactivate' ? 'Active' : 'In-Active' }
                            : item
                    )
                );
            } else {
                console.error(`Error updating project: ${responseData.message}`);
            }
        } catch (error) {
            console.error(`Error updating project:`, error);
        }
    };

    const openEditDialog = (project) => {
        // setEditingProject(project);
        // setEditingProjectValues(project);
        // setIsEditDialogOpen(true);
      };

    const handleAddProject = () => navigate('/add-project', { state: { projectNameValidationData } });

    const handleFilter = useCallback((filteredData) => {
        setFilteredProjects(filteredData);
    }, []);

    return (
        <div className='flex-grow'>
            <div className='sqlprojects-layout'>
                <nav aria-label="breadcrumb-trails">
                    <ul className="breadcrumb-unit">
                        <BreadcrumbItem to="/" label="Home" isActive={false} />
                        <BreadcrumbItem to="/all-projects" label="All Projects" isActive={true} />
                    </ul>
                </nav>

                <div className='header-sql-exec-projects'>
                    <div>
                        <h4 className="page-heading">All Projects</h4>
                        <span className="sub-title">Create, Edit and Manage your Projects</span>
                    </div>
                    <button className='add-sql-exec-projects' onClick={handleAddProject}>
                        + Add Project
                    </button>
                </div>

                <div className="project-details-layout">
                    <SearchFilterSort
                        data={data}
                        onFilter={handleFilter}
                        filterOptions={{ 'All': 'All', 'Active': 'Active', 'Inactive': 'In-Active' }}
                    />

                    <div className='projects-listing'>
                        <div className='project-table-header'>
                            <div className='project-column-header'>Project Name</div>
                            <div className='project-column-header'>Source</div>
                            <div className='project-column-header'>Target</div>
                            <div className='project-column-header'>Last Update</div>
                            <div className='project-column-header'>Actions</div>
                        </div>

                        <div className="scrollable-box-sql-project-details">
                            {filteredProjects.length > 0 ? (
                                filteredProjects.map((project) => (
                                    <div key={project.projectid} className='project-row'>
                                        <div className='project-column'>
                                            <span className="active">
                                                <button
                                                    className={`status-button ${project.status === "Active" ? "active-status" : "in-active"}`}
                                                >
                                                    {project.status === "Active" ? "🟢" : "🔴"}
                                                </button>
                                            </span>
                                            <Link
                                                to={`/project-config-details/${project.projectid}`}
                                                state={{ projectData: project }}
                                                className="link-styling project-status-tab-column"
                                            >
                                                {project.projectname}
                                            </Link>
                                        </div>
                                        <div className='project-column'>{project.sourcesystem}</div>
                                        <div className='project-column'>{project.targetsystem}</div>
                                        <div className='project-column'>{new Date(project.lastupdatedate).toLocaleString()}</div>
                                        <div className='project-column'>
                                            <label className="toggle-switch small-toggle" style={{ position: 'relative', display: 'inline-block' }}>
                                                <input
                                                    type="checkbox"
                                                    checked={project.status === "Active"}
                                                    onChange={() => handleToggleStatus(project)}
                                                />
                                                <span className="slider"></span>
                                            </label>
                                            <div className="custom-overlay">
                                                <span className="edit-link">
                                                    <span className={`edit-text`}>Edit</span>
                                                    <BsFillPencilFill
                                                    className={`pencil-icon`}
                                                    onClick={() => openEditDialog(project)}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className='no-projects'>No projects available</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Toaster />
        </div>
    );
};

export default Project2;
