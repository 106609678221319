import React, { useState, useEffect, useCallback } from "react";
import '../style/SQLExecProjects.css';
import BreadcrumbItem from "./Breadcrumbs";
import { useNavigate } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import SearchFilterSort from "./SearchFilterSort";
import {BsFillPencilFill } from 'react-icons/bs';

const SQLExecProjects = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const loggedInUserEmail = JSON.parse(localStorage.getItem('user'))?.email;

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_FASTAPI_URL}/v1/sql-projects/sqlprojects?email=${loggedInUserEmail}`);
                const projects = await response.json();
                setData(projects);
                console.log('Data from endpoint: ', data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchProjects();
    }, [loggedInUserEmail] );

    const handleAddProject = () => {
        navigate('/add-sql-project'); 
    };

    const handleFilter = useCallback((filteredData) => {
        setFilteredProjects(filteredData);
    }, []); 

    const openEditDialog = (project) => {
        // setEditingProject(project);
        // setEditingProjectValues(project);
        // setIsEditDialogOpen(true);
      };

    return (
        <div className='flex-grow'>
            <div className='sqlprojects-layout'>
                <nav aria-label="breadcrumb-trails">
                    <ul className="breadcrumb-unit">
                        <BreadcrumbItem to="/" label="Home" isActive={false} />
                        <BreadcrumbItem to="/sqlexecprojects" label="Active SQL Projects" isActive={true} />
                    </ul>
                </nav>
                <div className='header-sql-exec-projects'>
                    <div>
                        <h4 className="page-heading">SQL Execution Projects</h4>
                        <span className="sub-title">Create, Edit, and Manage your projects</span> 
                    </div>
                    <button className='add-sql-exec-projects' onClick={handleAddProject}>
                        + Add Project
                    </button>
                </div>
                <div className="project-details-layout">
                    <SearchFilterSort 
                        data={data}
                        onFilter={handleFilter}
                        filterOptions={{'All':'All', "Active":'Active', "Inactive": 'In-Active'}}
                    />
                    <div className='projects-listing'>
                        <div className='project-table-header'>
                            <div className='project-column-header'>Project Name</div>
                            <div className='project-column-header'>ODS</div>
                            <div className='project-column-header'>Last Updated Date</div>
                            <div className='project-column-header'>Actions</div>
                        </div>
                        <div className="scrollable-box-sql-project-details">
                            {filteredProjects.length > 0 ? (
                                filteredProjects.map(project => (
                                    <div key={project.projectid} className='project-row'>
                                        <div className='project-column'>{project.projectname}</div>
                                        <div className='project-column'>{project.ods}</div>
                                        <div className='project-column'>{new Date(project.lastupdatedate).toLocaleString()}</div>
                                        <div className='project-column'>
                                            <div className="custom-overlay">
                                                <span className="edit-link">
                                                    <span className={`edit-text`}>Edit</span>
                                                    <BsFillPencilFill
                                                    className={`pencil-icon`}
                                                    onClick={() => openEditDialog(project)}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className='no-projects'>No projects available</div>
                            )}
                        </div>                        
                    </div>
                </div>
            </div>
            <Toaster />
        </div>
    );
};

export default SQLExecProjects;
